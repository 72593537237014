
import React, { useState, useEffect  } from "react";
import { sendMedicalExtractionApi } from '../api';
import StatusDialog from "./StatusDialog";



const MedicalExtractionSendDialog = (props) => {
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");

    const showStatusDialog = (text) =>  {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () =>  {
        props.handleClose();
    };

    const handleMedicalExtractionSend = async (carrierCommonName, claimNumber)  => {

        setStatusCloseDisabled(true);
        setStatusDialogTitle("Send for Medical Extraction");
        showStatusDialog(carrierCommonName + " demand for Claim " + claimNumber + " is being sent for Medical Extraction");

        sendMedicalExtractionApi(props.documentId, props.user)
          .then((response) => {

            if (response.status == 200) {
                setStatusCloseDisabled(false);
                showStatusDialog("Success! Demand successfully submitted to Medical Extraction!");
                props.handler(true);
            }
            else {
                setStatusCloseDisabled(false);
                showStatusDialog("There was an error sending for medical extraction");
                props.handler(false);
            }

        });
    }

    useEffect(() => {
         setStatusDialogOpen(props.dialogOpen);
         if (props.dialogOpen) {
            handleMedicalExtractionSend(props.carrierCommonName, props.claimNumber);
         }
    }, [props.dialogOpen]);

    return (
        <>
            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    )

}

export default MedicalExtractionSendDialog;