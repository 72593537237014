import './App.css';
import "./fonts/Rubik-VariableFont_wght.ttf"
import awsExports from './aws-exports';
import { MedicalsViewer } from './MedicalsViewer'
import { MedicalsAdminViewer } from './MedicalsAdminViewer'
import { ShortCodeResolver } from "./ShortCodeResolver"
import { Error404Page } from "./Error404Page";
import HeaderAppBar from './HeaderAppBar'
import { Routes, Route } from 'react-router-dom';
import React, { } from "react";
import { Provider } from 'react-redux'
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from './Theme';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import store from './store'
import { InventoryList } from './InventoryList';
import { AdjusterList } from './AdjusterList';
import { AdjusterViewer } from './AdjusterViewer';
import { UsersView } from './UserManagement/UsersView';
import { DefaultRoute } from './DefaultRoute';
import { SubmitNewDemand } from './SubmitNewDemand';
import { ContactLogInventoryView } from './ContactLogInventoryView';
import { Login } from './Login';
import { RequireAuth } from './RequireAuth';
import { LicenseInfo } from '@mui/x-license-pro';
import { MedicalsLawFirmViewer } from './MedicalsLawFirmViewer';
import { LAW_FIRM_ROLES, PRECEDENT_ADMIN_ROLE, PRECEDENT_ROLES } from './common-roles';


const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY;
if (MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
}
else {
  console.error("MUI_LICENSE_KEY environment variable is not set. Please set it to the license key provided by MUI.")
}

// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  }
})


function App() {
  return (
    <Provider store={store}>
      <Authenticator.Provider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <>
              <HeaderAppBar />
              <Routes>
                <Route path="/" element={<RequireAuth><DefaultRoute /></RequireAuth>} />
                <Route path="/login" element={<Login />} />
                <Route path="/m/:documentId" element={<MedicalsViewer />} />
                <Route path="/a/:documentId" element={<RequireAuth roles={PRECEDENT_ROLES}><MedicalsAdminViewer /></RequireAuth>} />
                <Route path="/l/:documentId" element={<RequireAuth roles={LAW_FIRM_ROLES}><MedicalsLawFirmViewer /></RequireAuth>} />
                <Route path={"/404"} element={<Error404Page />} />
                <Route path="/:shortCode" element={<ShortCodeResolver />} />
                <Route path="/inventory" element={<RequireAuth roles={PRECEDENT_ROLES}><InventoryList viewType="precedent" /></RequireAuth>} />
                <Route path="/firm-inventory" element={<RequireAuth roles={LAW_FIRM_ROLES}><InventoryList viewType="lawfirm" /></RequireAuth>} />
                <Route path="/adjuster" element={<RequireAuth><AdjusterList /></RequireAuth>} />
                <Route path="/adjuster/d/:documentId" element={<RequireAuth><AdjusterViewer /></RequireAuth>} />
                <Route path="/submit-new-demand" element={<RequireAuth permissions={["DemandCreate"]}><SubmitNewDemand /></RequireAuth>} />
                <Route path="/contact-log-inventory" element={<RequireAuth roles={PRECEDENT_ROLES}><ContactLogInventoryView /></RequireAuth>} />
                <Route path="/user-management" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><UsersView /></RequireAuth>} />
                <Route path="/user-management/:customerId" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><UsersView /></RequireAuth>} />
              </Routes>
            </>
          </ThemeProvider>
        </LocalizationProvider>
      </Authenticator.Provider>
    </Provider>
  );

}

export default App;
