
import React, { } from "react";
import { formatCurrency, formatDate } from './common';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box, Stack, Typography } from '@mui/material';


const MedicalsOverview = (props) => {

    const documentData = props.documentData;

    // ui styles for grid
    const gridStyles = { px: 1, py: 1 };
    const labelStyles = { fontWeight: '500' };
    const stackSpacing = 1;
    const boxLabelStyle = { width: "40%" };
    const valueBoxStyle = { width: "60%" };


    return (
        <>
            <Grid container >
                <Grid xs={12} md={6} lg={3} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Claim number</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.claimInfo?.claimNumber || "Not on File"}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Claim coverage</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap>{documentData.claimInfo?.claimCoverage || "Not on File"}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Loss state</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap>{documentData.claimInfo?.lossState || "Not on File"}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12} md={6} lg={3} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Demand amount</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap className='adjuster-form-data'>{formatCurrency(documentData.demandDetails?.demandOfferToSettleAmount || 0)}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Policy limit</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap className='adjuster-form-data'>{documentData.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm && parseInt(documentData.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm) != 0 ? formatCurrency(documentData.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm) : 'Not on File'}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Policy limit demand</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap className='adjuster-form-data'>{documentData.demandDetails?.policyLimitDemandIndicator ?? false ? 'Yes' : 'No'}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12} md={6} lg={3} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Date of loss</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{formatDate(documentData.claimInfo?.dateOfLoss)}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Date of demand</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{formatDate(documentData.demandDetails?.demandWrittenDate)}</Typography>
                            </Box>
                        </Stack>

                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Response due date</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{formatDate(documentData.demandDetails?.demandResponseDueDate)}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12} md={6} lg={3} sx={gridStyles} overflow="hidden">
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Firm contact name</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap>{(documentData.sendingFirm?.primaryContact?.firstName || "Not on File") + " " + (documentData.sendingFirm?.primaryContact?.lastName ?? "")}</Typography>
                            </Box>
                        </Stack>

                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Firm contact phone</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap>{documentData.sendingFirm?.primaryContact?.contactInfo?.phoneNumber || "Not on File"}</Typography>
                            </Box>
                        </Stack>

                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Firm contact email</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.sendingFirm?.primaryContact?.contactInfo?.emailAddress || "Not on File"}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )

}

export default MedicalsOverview;