
import React, {  } from "react";
import Link from '@mui/material/Link';
import Title from './Title'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton } from '@mui/material';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const EnclosuresTable = (props) => {

    const findAttachment = (attachmentId) => {
        for (let i = 0; i < props.demandDoc.attachments.length; i++) {
            const attachment = props.demandDoc.attachments[i];
            if (attachment.attachmentId == attachmentId) {
                return attachment;
            }
            
        }

        return null;
    };

    return (
        <>
        { props.showTitle ? (
        <Title color="rgba(0, 0, 0, 0.56)">Enclosures</Title>
        ) : null}
                            
        <TableContainer>
        <Table className={"attachments-table"}>
            <TableBody> 
                {props.attachments.map((attachmentRef,index) => {
                    return (
                        <TableRow key={attachmentRef.referencedAttachmentId+index}>
                            <TableCell width={"3%"}><FilePresentOutlinedIcon color="secondary" /></TableCell>
                            <TableCell><Link style={{cursor: "pointer"}} onClick={()=>{props.viewAttachment(attachmentRef.referencedAttachmentId, props.viewTitle)}} color={"secondary"}>{findAttachment(attachmentRef.referencedAttachmentId || attachmentRef.attachmentId).attachmentName}</Link></TableCell>
                            <TableCell width={"5%"}><IconButton onClick={()=>{props.downloadAttachment(attachmentRef.referencedAttachmentId || attachmentRef.attachmentId)}}><FileDownloadIcon color="primaryActions"/></IconButton></TableCell>
                            <TableCell width={"5%"}><IconButton  onClick={()=>{props.viewAttachment(attachmentRef.referencedAttachmentId || attachmentRef.attachmentId,props.viewTitle)}}><RemoveRedEyeIcon color="primaryActions"/></IconButton></TableCell>
                        </TableRow>
                    )
                }
                )}
            </TableBody>
        </Table>
        </TableContainer>
        </>
    )

}

export default EnclosuresTable;