import { useEffect } from "react";

import { Authenticator, useAuthenticator, View, Heading, Image, useTheme, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

import awsExports from './aws-exports';
import { getUserApi } from './api';
import { userHasRole, userHasPermission } from './common';

export function Login(props) {
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  const { route, signOut, user } = useAuthenticator((context) => [
    context.route,
    context.signOut,
    context.user
  ]);

  const getUser = async (user) => {
    const response = await getUserApi(user);

    // Check roles
    let hasRole = false;
    if (location.state?.roles) {
      location.state?.roles.forEach((role) => {
        if (userHasRole(role, response)) {
          hasRole = true;
        }
      });
    }
    else {
      hasRole = true;
    }

    // Check permissions
    let hasPermission = false;
    if (location.state?.permissioms) {
      location.state?.permissions.forEach((permission) => {
        if (userHasPermission(permission, response)) {
          hasPermission = true;
        }
      });
    }
    else {
      hasPermission = true;
    }

    if (hasRole && hasPermission) {
      navigate(from, { replace: true });
    }
    else {
      navigate('/', { replace: true });
    }
  };

  const formFields = {
    setupTOTP: {
      QR: {
        totpIssuer: awsExports.MFA_ISSUER
      },
      confirmation_code: {
        label: 'Enter the code from your Authenticator application.',
        placeholder: 'Enter your Authenticator Code:',
        isRequired: true
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Enter the code from your Authenticator application.',
        placeholder: 'Enter your Authenticator code:',
        isRequired: true
      },
    },
  };


  const components = {
    Header() {
      return (
        <View>
        </View>
      );
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading level={5}>
            Scan the QR code or copy the code into your Authenticator application:
          </Heading>
        );
      }
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading level={3}>
            Authenticator information
          </Heading>
        );
      }
    },
  };

  const login_theme = {
    name: 'precedent-theme',
    tokens: {
      colors: {
        brand: {
          primary: {
            '90': '#313a5c',
            '100': '#232941'
          }
        }
      },
      components: {
        button: {
          // this will affect the font weight of all button variants
          fontWeight: { value: '{fontWeights.extrabold}' },

          // style the primary variation
          primary: {
            backgroundColor: { value: '{colors.brand.primary[100]}' },
            _hover: {
              backgroundColor: { value: '{colors.brand.primary[90]}' },
            },
            _focus: {
              backgroundColor: { value: '{colors.brand.primary[100]}' },
            },
            _active: {
              backgroundColor: { value: '{colors.brand.primary[100]}' },
            },
          },
        }
      },
    },
  };

  const services = {
  };



  useEffect(() => {
    if (route === 'authenticated') {
      getUser(user);
    }
  }, [route, navigate, from]);
  return (
    <View className="auth-wrapper">
      <ThemeProvider theme={login_theme}>
        <Authenticator hideSignUp={true} components={components} formFields={formFields} services={services}>
        </Authenticator>
      </ThemeProvider>
    </View>
  );
}