import React, { useState,createRef, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Stack,
  ThemeProvider} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {theme} from './Theme';
import awsExports from './aws-exports';
import {ConfirmationDialogRaw} from './ConfirmationDialogRaw'

const DemandGenerationView = ({ open, documentId, closeCallback , user}) => {
    
    const rootApiUrl = awsExports.ROOT_API_URL;
    const [attachmentS3Url, setAttachmentS3Url] = useState("");
    const frameRef = createRef();
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [infoDialogTitle, setInfoDialogTitle] = useState("");
    const [infoDialogText, setInfoDialogText] = useState("");

    const submitDemand = async () => {

        setShowUploadModal(true);

        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };
        
        const response = await fetch(
            rootApiUrl+"/document/"+documentId+"/submit", settings
        ).then((response) => {
            if (response.ok) {
               setSubmitDisabled(true);
               setShowUploadModal(false);
               showInfoDialog("Demand Submitted!", "Your demand has been successfully submitted.")
               return response.json();
            }
            else {
                throw new Error('Error submitting the demand. Got ' + response.status + ' status code. ');
            }
        }).catch((error) => {
            // Your error is here!
            console.log(error);
            setShowUploadModal(false);
            alert(error);
        });
        
        
    };

    const getDocument = async () => {

        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };
        
        const response = await fetch(
            rootApiUrl+"/document/"+documentId, settings
        ).then((response) => {
            if (response.ok) {
               return response.json();
            }
            else {
                throw new Error('Error getting document. ' + response.status + ' status code. ');
            }
        }).catch((error) => {
            // Your error is here!
            console.log(error);
            alert(error);
        });

        setSubmitDisabled(response.metadata.documentStatus != "INPROGRESS");
    };

    const getGeneratedDemand = async () => {

        setLoading(true);

        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };

        const response = await fetch(
            rootApiUrl+"/document/"+documentId+"/generatepdf", settings
        ).then((response) => {
            if (response.ok) {
               return response.json();
            }
            else {
                throw new Error('Error generating PDF. Got ' + response.status + ' status code. ');
            }
        }).catch((error) => {
            // Your error is here!
            console.log(error);
            setLoading(false);
            alert(error);
        });
        
        setLoading(false);
    
        if (response && response["s3Url"])
            setAttachmentS3Url(response["s3Url"]);
    };

    const resizeIframe = () => {
        if (frameRef.current != null) {
           frameRef.current.style.height = (window.innerHeight-200)+'px';
        }
    };

    useEffect(() => {
        resizeIframe();
    }, [frameRef]);
    
    const handleClose = (e) => {
        closeCallback();
    }

    const handleInfoDialogClose = () =>  {
        setInfoDialogOpen(false);
    };

    const showInfoDialog = (title,text) =>  {
        setInfoDialogText(text);
        setInfoDialogTitle(title);
        setInfoDialogOpen(true);
    };

    const handleSubmitDemandClick = () => {
        setConfirmationOpen(true);
    };

    const handleConfirmClose= (result) => {
        setConfirmationOpen(false);
        if (result) {
            submitDemand();
        }
    };

    useEffect(() => {
        getGeneratedDemand(documentId);
        getDocument();
    }, [open]);

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth={true} PaperProps={{ style: {
                    minHeight: '90%',
                    maxHeight: '90%',
                }}}>
                <DialogTitle>Demand Preview</DialogTitle>
                <DialogContent>
                    <Stack direction={"row"} sx={{width:"100%"}} justifyContent={"center"}>
                        {!loading ? (
                            <div ref={frameRef} style={{width:"100%"}}>
                                <iframe onLoad={resizeIframe()} id="documentFrame" src={attachmentS3Url} title="GeneratedDemand" width={"100%"} height={"100%"}>
                                </iframe>
                            </div>
                        ) : (

                            <Box sx={{ display: 'flex', marginTop:"100px" }} >
                                <Stack direction={"column"} alignItems={"center"} spacing={5}>
                                <CircularProgress />
                                <div>Generating PDF....</div>
                                </Stack>
                            </Box>

                        )}
                    </Stack>

                    {infoDialogOpen ? (
                    <Dialog open={infoDialogOpen} id="infomodal">
                        <DialogTitle>Demand Submitted!</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {infoDialogText}
                                </DialogContentText>
                            </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {handleInfoDialogClose()}}>Ok</Button>
                        </DialogActions>
                    </Dialog>) : null}
                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} sx={{width:"100%"}}>
                        <Stack sx={{width:"50%"}}>
                            <Button sx={{width:"250px"}} disabled={submitDisabled} onClick={() => {handleSubmitDemandClick()}} color='success' variant='contained'>                
                                Submit Demand
                            </Button>
                        </Stack>
                        <Stack sx={{width:"50%"}} alignItems={"end"}>
                            <Button sx={{width:"250px"}} onClick={handleClose} variant="contained" color="secondary">Close</Button>
                        </Stack>
                    </Stack>
                </DialogActions>
            </Dialog>

            <Dialog open={showUploadModal} id="submitmodel">
                <DialogTitle>Submitting Demand</DialogTitle>
                <Stack sx={{width: '400px'}}>
                    <div style={{padding:'20px',paddingBottom:"40px"}}>
                        Your demand is being submitted...
                    </div>
                </Stack>       
            </Dialog>

            <ConfirmationDialogRaw onClose={handleConfirmClose} open={confirmationOpen}  okText="Submit Demand" title="Submit Demand" text="Do you want to submit this demand? You will not be able to edit this demand once you submit." />
        </ThemeProvider>
    );
};
export default DemandGenerationView;