
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Card, Stack, TextField, ThemeProvider } from '@mui/material';
import awsExports from './aws-exports';
import LoadingButton from '@mui/lab/LoadingButton';
import { theme } from './Theme'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { buildProviderSummary } from './common-medicals';
import { InfoOutlined } from '@mui/icons-material';
import MedicalsOverview from './MedicalsOverview';
import MedicalsProviderSummary from './MedicalsProviderSummary';
import MedicalsTreatments from './MedicalsTreatments';
import { Transition } from './Transition'


export const MedicalsViewer = (props) => {

    const rootApiUrl = awsExports.ROOT_API_URL;
    const { documentId } = useParams();
    const [documentData, setDocumentData] = useState();
    const [verifyingPin, setVerifyingPin] = useState(false);
    const [pinError, setPinError] = useState(false);
    const [documentArchived, setDocumentArchived] = useState(false);
    const [shortCode, setShortCode] = useState("");
    const [pinErrorMessage, setPinErrorMessage] = useState("");
    const [pin, setPin] = useState("");
    const [pinDialogOpen, setPinDialogOpen] = useState(true);
    const [providerSummary, setProviderSummary] = useState([]);
    const [ipAddress, setIpAddress] = useState();

    const getDocument = async () => {
        if (pin == "" || pin == null)
            return;

        setVerifyingPin(true);

        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        };

        const response = await fetch(
            rootApiUrl + "/medicals/" + documentId + "?pin=" + pin + "&ua=" + btoa(navigator.userAgent) + "&ip=" + ipAddress, settings
        ).then((response) => {
            return response;
        });

        setVerifyingPin(false);

        // update the state and map form elements
        const responseJson = await response.json();
        if (responseJson.document) {
            setDocumentData(responseJson.document);
            setPinDialogOpen(false);
            setPinError(false);
            setPinErrorMessage("");
        }
        else {
            if (response.status == 405) {
                setShortCode(responseJson.shortCode);
                setDocumentArchived(true);
                setPinDialogOpen(false);
            }
            else {
                setPinErrorMessage("The PIN could not be verified");
                setPinError(true);
            }
        }
    };


    const handlePinDialogVerify = async () => {
        if (pin != "") {
            getDocument();
        }
    };


    useEffect(() => {

        // Generate the summary of provider
        if (documentData) {
            setProviderSummary(buildProviderSummary(documentData));
        }

    }, [documentData]);

    useEffect(() => {

        //
        // Get the IP Address of the client
        //
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIpAddress(data.ip));
    }, []);

    return (
        <Container maxWidth='100' sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
            padding: '10px'
        }}>
            <ThemeProvider theme={theme}>
                {documentArchived ? (
                    <div style={{ marginTop: '65px' }}>
                        Error attempting to view medicals.  Please contact customerservice@precedent.com and reference short code: {shortCode}
                    </div>
                )
                    : null}

                {documentData ? (
                    <>
                        <div style={{ position: 'fixed', zIndex: 10, top: 60, left: 15, right: 15, backgroundColor: "#FFFFFF" }}>
                            <div className='banner-container'>
                                <div className='banner'><InfoOutlined style={{ verticalAlign: 'middle' }} color={'secondary'} /> This information is related to a demand that was shared with you via email.</div>
                            </div>
                        </div>

                        <div style={{ marginTop: '125px' }}>
                            <Stack direction="column" spacing={2}>
                                <Card>
                                    <MedicalsOverview documentData={documentData} />
                                </Card>

                                <MedicalsProviderSummary providerSummary={providerSummary} />

                            </Stack>
                            <MedicalsTreatments viewType="carrier" documentData={documentData} adminView={false} />
                        </div>



                    </>
                ) : (
                    <p></p>
                )}

                <Dialog
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            handlePinDialogVerify();
                        }
                    }}
                    open={pinDialogOpen}
                    TransitionComponent={Transition}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>Verify Document PIN</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <Stack direction={"column"} spacing={2}>
                                <div>
                                    Enter PIN to view itemized medicals
                                </div>

                                <TextField autoFocus={true} onChange={(e) => { setPin(e.target.value) }} variant="outlined"
                                    error={pinError} label={pinErrorMessage}
                                    value={pin} placeholder='Enter PIN Here' />
                            </Stack>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton loading={verifyingPin} type="submit" fullWidth variant="contained" color="secondary" onClick={() => { handlePinDialogVerify() }}>Sign In</LoadingButton>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </Container>

    );
};
