
import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from '../Transition'
import { Stack, TextField, MenuItem, Button } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';



const ArchiveDialog = (props) => {

    const [archiveReasonError, setArchiveReasonError] = useState(false);
    const [archiveReason, setArchiveReason] = useState();

    const archive = () => {

        const reasonError = !archiveReason;
        setArchiveReasonError(reasonError);

        if (reasonError) {
            return;
        }
        else {
            props.handler(archiveReason);
        }
    };

    return (
        <Dialog
            open={props.dialogOpen}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
        >
            <DialogTitle>Archive demand</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Stack spacing={2}>
                        <div>You are about to archive matter {props.firmCaseNumber}.</div>
                        <TextField id="archive_reason" label="Archive reason" value={archiveReason}
                            fullWidth onChange={(e) => { setArchiveReason(e.target.value) }}></TextField>
                    </Stack>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.handleClose() }}>Cancel</Button><Button variant="contained" color="secondary" onClick={() => { archive() }}>Archive</Button>
            </DialogActions>
        </Dialog>
    )

}

export default ArchiveDialog;