import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LinearProgress, Stack, IconButton } from '@mui/material';
import Title from './Title';
import { convertUTCDateTimeToLocalTime, convertUTCDateTimeToLocalDate } from './common';
import { RefreshOutlined } from '@mui/icons-material';
import { getContactLogApi } from './api';
import StatusDialog from './dialogs/StatusDialog';


const ContactLogDetailPanelContent = (props) => {
    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    const rowProp = props.row;

    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [documentId, setDocumentId] = useState(rowProp.document.documentId);
    const [contactLog, setContactLog] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadContactLog = async () => {

        setLoading(true);

        const response = await getContactLogApi(documentId, user)
            .then((response) => {
                if (response.status != 200) {
                    showStatusDialog('Error', 'An error occurred loading the contact log.');
                }
                setLoading(false);
                return response.json();
            });

        setContactLog(response);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const getMaxWidthForPanel = () => {
        return window.innerWidth-150;
    };

    const mapQuickNote = (quickNoteId) => {

        switch (quickNoteId) {
            case "left_voicemail":
                return "Left voicemail with carrier";
            case "access_blocked":
                return "Carrier access blocked";
            case "new_adjuster":
                return "New adjuster information";
            case "new_claim":
                return "New claim information";
            case "new_status":
                return "New status update";
            default:
                return "Unknown: " + quickNoteId + "";
        }

    };

    useEffect(() => {
        loadContactLog();
    }, [documentId]);

    const columns = [
        {
            field: 'caller',
            headerName: 'Caller',
            width: 150,
            valueGetter: (params) => {
                return params.row.callerFirstName + ' ' + params.row.callerLastName;
            }
        },
        {
            field: 'adjuster',
            headerName: 'Point of contact',
            width: 150,
            valueGetter: (params) => {
                return params.row.adjuster;
            }
        },
        {
            field: 'quick_notes',
            headerName: 'Quick notes',
            flex: 300,
            valueGetter: (params) => {

                let notesStr = ""
                return params.row.quickNotesSelections.map(element => {
                    return mapQuickNote(element);
                }).join('; ');
            }
        },
        {
            field: 'comments',
            headerName: 'Additional comments',
            flex: 300,
            valueGetter: (params) => {
                return params.row.comments;
            }
        },
        {
            field: 'date_logged',
            headerName: 'Date logged',
            width: 175,
            valueGetter: (params) => {
                return params.row.contactDateTs;
            },
            renderCell: (params) => {
                return (<div className='MuiDataGrid-cellContent'>{convertUTCDateTimeToLocalDate(params.row.contactDateTs)}<br /><span className='pin-label'>{convertUTCDateTimeToLocalTime(new Date(params.row.contactDateTs))}</span></div>);
            }
        },
    ];

    return (
        <Stack
            sx={{ padding: '10px', paddingRight: '20px', height: '100%', boxSizing: 'border-box' }}
            direction="column"
        >
            {props.showHeader ? (
            <Stack direction={"row"} sx={{maxWidth: getMaxWidthForPanel()}}>
                <Stack direction={"row"} width={"35%"}>
                    <Title color="black">Contact log</Title>
                </Stack>
                <Stack direction={"row"} spacing={2} width={"65%"} justifyContent={"flex-end"}>
                    <IconButton title='Referesh contact log table' color='secondary' onClick={loadContactLog}><RefreshOutlined></RefreshOutlined></IconButton>
                </Stack>
            </Stack>) : null}

            <DataGridPro autoHeight getRowId={(row) => row.contactLogEntryId} columns={columns} rows={contactLog}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'date_logged', sort: 'desc' }],
                    }
                }}
                getRowHeight={() => 'auto'}
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            No attempts have been made to contact the carrier.
                        </Stack>
                    )
                }}
                disableRowSelectionOnClick={true}
                disableColumnSelector={true}
                loading={loading}
                pageSizeOptions={[25, 50, 100]}
                sx={{
                    backgroundColor: "#fff",
                    m: 1,
                    maxWidth: getMaxWidthForPanel(),
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontSize: '14px',
                        fontWeight: '500'
                    },
                    '& .MuiDataGrid-cellContent': {
                        fontSize: '14px'
                    }
                }}
            >
            </DataGridPro>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

        </Stack>
    );
};

export default ContactLogDetailPanelContent;