import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from "react";
import { DataGridPro, GridRowModes, GridPreProcessEditCellProps, GridRowEditStopReasons, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { LinearProgress, Paper, Stack, Snackbar, Alert, Button, TextField, MenuItem, Divider, Card, IconButton, Box } from '@mui/material';
import awsExports from '../aws-exports';
import Title from '../Title';
import { isValidEmail } from '../common';
import { PRECEDENT_ROLES, LAW_FIRM_ROLES } from '../common-roles';
import { DeleteRounded, EditRounded, SaveRounded, CloseRounded, PersonAddOutlined, RateReviewOutlined, ArrowRight, ChevronRight } from '@mui/icons-material';
import { getUserApi, getConfigValue } from '../api';
import StatusDialog from '../dialogs/StatusDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import styles from './UsersView.module.css'

export function UsersView(props) {

    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    const { customerId } = useParams();

    const rootApiUrl = awsExports.ROOT_API_URL;
    const [usersListData, setUsersListData] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [selectedCustomerId, setSelectedCustomerId] = useState(customerId);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState();
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastSeverity, setToastSeverity] = useState();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [rowModesModel, setRowModesModel] = useState({});
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState("");
    const [confirmationDialogTitle, setConfirmationDialogTitle] = useState("Delete user?");
    const [currentDeleteUser, setCurrentDeleteUser] = useState({});
    const [roleOptions, setRoleOptions] = useState([]);
    const [validDomains, setValidDomains] = useState([]);


    const apiRef = useRef({});
    const navigate = useNavigate();
    const location = useLocation();

    const getUsersListApi = async (customerId) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify({ customerId: customerId })
        };

        setLoading(true);

        const response = await fetch(
            rootApiUrl + "/users", settings
        ).then((response) => response.json())
            .finally(() => {
                setLoading(false);
            });

        setUsersListData(response);
    };

    const getCustomersApi = async () => {
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };

        setLoading(true);

        const response = await fetch(
            rootApiUrl + "/customers", settings
        ).then((response) => response.json())
            .finally(() => {
                setLoading(false);
            });

        // Sort the list. Precedent first and then all the others alphabetically 
        response.sort((a, b) => a.name.localeCompare(b.name));

        const customerList = []
        for (const customer of response) {

            // If we have a customer then select.
            if (selectedCustomerId && customer.customerId === selectedCustomerId) {
                setSelectedCustomer(customer);
            }

            if (customer["name"] === "Precedent") {
                customerList.unshift(customer);
            }
            else {
                customerList.push(customer);
            }
        }

        setCustomersData(customerList);
    };


    const updateUserApi = async (userRecord) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(userRecord)
        };

        setLoading(true);

        const response = await fetch(
            rootApiUrl + "/user", settings
        )
            .then((response) => {
                if (response.status === 200) {
                    showToast("User updated successfully.", "success");
                }
                else {
                    showToast("There was an error updating the user.", "error");
                }
                return response.json();
            }).finally(() => {
                setLoading(false);
            });

        return response["userId"];
    };

    const deleteUserApi = async (userRecord) => {

        const settings = {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(userRecord)
        };

        setLoading(true);

        const response = await fetch(
            rootApiUrl + "/user", settings
        )
            .then((response) => {
                if (response.status === 200) {
                    setConfirmationDialogOpen(false);
                    setUsersListData(usersListData.filter((row) => row.userId !== userRecord.userId));
                    showToast("User successfully deleted.", "success");
                }
                else {
                    setConfirmationDialogText("There was an error deleting the user.");
                }
                return response.json();
            }).finally(() => {
                setLoading(false);
                setCurrentDeleteUser(null);
            });

        return response;
    };

    const getValidDomains = async (customerId) => {
        const validDomains = await getConfigValue("validEmailDomains", customerId, user) || []
        setValidDomains(validDomains);
    };

    const getUser = async () => {
        setUserData(await getUserApi(user));
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = { ...newRow, isNew: false };

        if (!updatedRow["firstName"] || !updatedRow["lastName"] || !updatedRow["customerName"] || !updatedRow["emailAddress"] || !updatedRow["roleId"]) {
            console.log("New user not valid: " + JSON.stringify(updatedRow));
            showStatusDialog("Validation Error", "All fields are required");
            return false;
        }

        // Clean up the email address
        updatedRow["emailAddress"] = updatedRow["emailAddress"].trim();

        if (!isValidEmail(updatedRow["emailAddress"])) {
            showStatusDialog("Validation Error", "The email address is not valid");
            return false;
        }

        // Validate email is for an approved domain
        if (validDomains.length > 0) {
            const emailDomain = updatedRow["emailAddress"].split("@")[1];
            if (!validDomains.includes(emailDomain.toLowerCase())) {
                showStatusDialog("Invalid email domain", updatedRow["emailAddress"] + " is an invalid email address. Update the email address to save the user.");
                return false;
            }
        }

        // Set the role
        updatedRow["roles"] = [updatedRow["roleId"]];
        delete updatedRow["roleId"];

        // Handle new user. Set customer id and remove user id so we trigger a create.
        if (newRow.isNew) {
            updatedRow["customerId"] = selectedCustomer.customerId;
            updatedRow["isNewUser"] = true;
            delete updatedRow["userId"];
        }

        // Call the API
        updatedRow["userId"] = await updateUserApi(updatedRow);

        setUsersListData(usersListData.map((row) => (row.userId === newRow.userId ? updatedRow : row)));

        return updatedRow;
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleAddUserClick = (e) => {
        setUsersListData((oldRows) => [{ isNew: true, userId: 'NEW', firstName: '', lastName: '', customerName: selectedCustomer.name, customerId: selectedCustomer.customerId, emailAddress: '', roles: roleOptions }, ...oldRows]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            ["NEW"]: { mode: GridRowModes.Edit, fieldToFocus: 'firstName' },
        }));
    };

    const handleEditUserClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        handleRowEditStart({ id: id });
    };

    const handleDeleteUserClick = (id) => () => {
        const row = usersListData.find((row) => row.userId === id);
        setCurrentDeleteUser(row);
        setConfirmationDialogText("Are you sure you want to delete the user " + row.firstName + " " + row.lastName + "?");
        setConfirmationDialogOpen(true);
    };

    const handleDeleteUserConfirmOk = () => {
        deleteUserApi(currentDeleteUser);
    };

    const handleDeleteUserCancel = () => {
        setConfirmationDialogOpen(false);
    };

    const handleCancelUserClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
        const editedRow = usersListData.find((row) => row.userId === id);
        if (editedRow.isNew) {
            setUsersListData(usersListData.filter((row) => row.userId !== id));
        }
    };

    const handleSaveUserClick = (id) => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStart = (params, event) => {
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const loadCustomer = (customerId) => {
        setUsersListData([]);
        getValidDomains(customerId);
        getUsersListApi(customerId);
        setSelectedCustomerId(customerId);
    };

    const handleCustomerClick = (customer) => {
        setSelectedCustomerId(customer.customerId);
        setSelectedCustomer(customer);
        navigate('/user-management/' + customer.customerId);
    };

    const handleShowAllCustomersClick = (customer) => {
        setSelectedCustomer(null);
        setSelectedCustomerId(null);
        setUsersListData([]);
        navigate('/user-management');
    };

    const handleToastClose = () => {
        setToastMessage("");
        setToastOpen(false);
    };

    const showToast = (message, severity) => {
        setToastSeverity(severity);
        setToastMessage(message);
        setToastOpen(true);
    };

    // Grid Columns

    const first_name_col = {
        field: 'firstName',
        headerName: 'First name',
        width: 200,
        valueGetter: (params) => {
            return params.row.firstName;
        },
        editable: true
    };

    const last_name_col = {
        field: 'lastName',
        headerName: 'Last name',
        width: 200,
        valueGetter: (params) => {
            return params.row.lastName;
        },
        editable: true
    };

    const email_col = {
        field: 'emailAddress',
        headerName: 'Email address',
        width: 300,
        editable: true,
        valueGetter: (params) => {
            return params.row.emailAddress;
        }

    };

    const customer_col = {
        field: 'customerName',
        headerName: 'Customer ID',
        width: 300,
        editable: true,
        valueGetter: (params) => {
            return params.row.customerName;
        }
    };

    const role_col = {
        field: 'roleId',
        headerName: 'Role',
        width: 300,
        valueGetter: (params) => {
            return params.row.roles[0];
        },
        editable: true,
        type: 'singleSelect',
        valueOptions: roleOptions,
    };

    const actions_col = {
        field: 'actions',
        headerName: 'Actions',
        width: 110,
        type: 'actions',
        getActions: ({ id, row }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveRounded />}
                        label="Save"
                        color='secondary'
                        onClick={(e) => { handleSaveUserClick(id) }}
                    />,
                    <GridActionsCellItem
                        icon={<CloseRounded />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelUserClick(id)}
                        color="primaryActions"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditRounded />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditUserClick(id)}
                    color="primaryActions"
                />,
                <GridActionsCellItem
                    icon={<DeleteRounded />}
                    label="Delete"
                    onClick={handleDeleteUserClick(id)}
                    color="primaryActions"
                />,
            ];
        }
    }

    useEffect(() => {
        if (selectedCustomerId)
            loadCustomer(selectedCustomerId);
        else
            handleShowAllCustomersClick();
    }, [selectedCustomerId]);

    useEffect(() => {
        if (selectedCustomer) {
            if (selectedCustomer["customerType"] === "precedent") {
                setRoleOptions(PRECEDENT_ROLES);
            }
            else if (selectedCustomer["customerType"] === "lawfirm") {
                setRoleOptions(LAW_FIRM_ROLES);
            }
        }
    }, [selectedCustomer]);

    useEffect(() => {
        getCustomersApi();
    }, []);

    const columns = React.useMemo(() => [first_name_col, last_name_col, email_col, customer_col, role_col, actions_col]);

    return (
        <div className='header-offset'>
            {selectedCustomer ? (
                <div>
                    <div className='breadcrumbs-page-title-container' onClick={handleShowAllCustomersClick}><span className='breadcrumbs-page-link'>User management</span> <span className='breadcrumbs-page-active'>/ {selectedCustomer.name}</span></div>

                    <Card >
                        <Stack direction={"row"}>
                            <Stack direction={"row"} width={"35%"}>
                                <Title>{selectedCustomer.name} users</Title>
                            </Stack>
                            <Stack direction={"row"} width={"65%"} sx={{ pr: 1 }} justifyContent={"flex-end"}>
                                <Button variant="contained" color='secondary'
                                    disabled={usersListData.length > 0 && usersListData[0].userId === 'NEW'}
                                    onClick={handleAddUserClick} title='Add a new user to Exchange'
                                    startIcon={< PersonAddOutlined />}>Create user</Button>
                            </Stack>
                        </Stack>
                        <DataGridPro autoHeight={true} getRowId={(row) => row.userId} columns={columns} rows={usersListData}
                            apiRef={apiRef}
                            editMode="row"
                            density="compact"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            onRowEditStart={handleRowEditStart}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'lastName', sort: 'asc' }],
                                }
                            }}
                            isCellEditable={(params) => {
                                if (params.row.isNew)
                                    return params.field !== 'customerName';
                                else {
                                    if (params.field === 'customerName' || params.field === 'emailAddress')
                                        return false;
                                    else
                                        return true;
                                }

                            }}
                            processRowUpdate={processRowUpdate}
                            getRowHeight={() => 'auto'}
                            slots={{
                                loadingOverlay: LinearProgress,
                            }}
                            disableRowSelectionOnClick={true}
                            disableColumnSelector={true}
                            loading={loading}
                            pageSizeOptions={[25, 50, 100]}
                            sx={{
                                backgroundColor: "#fff",
                                mt: 1,
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontSize: '9pt',
                                    fontWeight: '500'
                                },
                                '& .MuiDataGrid-cellContent': {
                                    fontSize: '9pt',
                                    paddingTop: '5px',
                                    paddingBottom: '5px'
                                }
                            }}
                        >
                        </DataGridPro>

                        <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

                        <ConfirmationDialog buttonColor="error" handleOk={handleDeleteUserConfirmOk} handleCancel={handleDeleteUserCancel} okButtonText="Delete User" cancelButtonText="Cancel" dialogOpen={confirmationDialogOpen} dialogText={confirmationDialogText} dialogTitle={confirmationDialogTitle}></ConfirmationDialog>

                        <Snackbar
                            open={toastOpen}
                            autoHideDuration={3000}
                            onClose={handleToastClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>{toastMessage}</Alert>
                        </Snackbar>
                    </Card>

                </div>) : (<>

                    <Stack spacing={2} width={500}>
                        <Title color="black">Select customer</Title>
                        {customersData.map((customer) => (
                            <Card key={customer.customerId} onClick={(e) => { handleCustomerClick(customer) }} className={styles.userCard}>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                                    <Stack direction={"row"} width={"80%"}>
                                        <div className='secondary'>{customer.name}</div>
                                    </Stack>
                                    <Stack direction={"row"} width={"20%"} sx={{ pr: 1 }} justifyContent={"flex-end"}>
                                        <IconButton>
                                            <ChevronRight />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Card>))}
                    </Stack>

                </>)}

        </div>
    );

}