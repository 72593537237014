
import React, { } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Stack, Divider, Box, Typography } from '@mui/material';

const MedicalsOverviewPrecedent = (props) => {

    const documentData = props.documentData;

    // ui styles for grid
    const gridStyles = { px: 1, py: 1 }
    const labelStyles = { fontWeight: '500' }
    const stackSpacing = 1;
    const boxLabelStyle = { width: "40%" };
    const valueBoxStyle = { width: "60%" };

    return (
        <>
            <Grid container>
                <Grid xs={12} md={6} lg={3} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Loss county</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.claimInfo?.lossCounty || "Not on File"}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Client name</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.claimInfo?.claimant?.firstName || "Not on File"} {documentData.claimInfo?.claimant?.lastName ?? ""}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Insured name</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.claimInfo?.insuredFirstName || "Not on File"} {documentData.claimInfo?.insuredLastName ?? ""}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12} md={6} lg={3} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Carrier name</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.recipientCarrier.carrierCommonName}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Carrier phone</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.recipientCarrier.contactInfo?.phoneNumber || "Not on File"}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Adjuster name</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.recipientAdjuster?.firstName || "Not on File"} {documentData.recipientAdjuster?.lastName ?? ""}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Adjuster phone</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.recipientAdjuster?.contactInfo?.phoneNumber || "Not on File"}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12} md={6} lg={3} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Sending firm</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.sendingFirm.firmName}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Attorney name</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.sendingFirm.attorney?.firstName || "Not on File"} {documentData.sendingFirm.attorney?.lastName ?? ""}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"}>
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Attorney phone</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap>{documentData.sendingFirm.attorney?.contactInfo.phoneNumber || "Not on File"}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"} >
                            <Box sx={boxLabelStyle}>
                                <Typography variant="body2" sx={labelStyles}>Attorney email</Typography>
                            </Box>
                            <Box sx={valueBoxStyle}>
                                <Typography variant="body2" noWrap >{documentData.sendingFirm.attorney?.contactInfo?.emailAddress || "Not on File"}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>

            {props.showCarrierPanel ? (
                <>

                    <Divider sx={{ my: 1 }} />

                    <Grid container>
                        <Grid xs={12} md={6} lg={3} sx={gridStyles}>
                            <Stack direction={"column"} spacing={stackSpacing}>
                                <Stack direction={"row"}>
                                    <Box sx={boxLabelStyle}>
                                        <Typography variant="body2" sx={labelStyles}>Carrier email</Typography>
                                    </Box>
                                    <Box sx={valueBoxStyle}>
                                        <Typography variant="body2" >{documentData.recipientCarrier.contactInfo?.emailAddress || "Not on File"}</Typography>
                                    </Box>
                                </Stack>
                                <Stack direction={"row"}>
                                    <Box sx={boxLabelStyle}>
                                        <Typography variant="body2" sx={labelStyles}>Carrier fax</Typography>
                                    </Box>
                                    <Box sx={valueBoxStyle}>
                                        <Typography variant="body2" >{documentData.recipientCarrier.contactInfo?.faxNumber || "Not on File"}</Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            ) : null}
        </>
    )

}

export default MedicalsOverviewPrecedent;