import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Title from './Title'
import {formatAddress} from './common';

const AdjusterViewerParties = (props) => {

    const demandDoc = props.demandDoc;

    return (
        <Grid container xs={12} sx={{marginTop: '10px'}} spacing={3}>
            <Grid xs={6}>
                <Stack spacing={3}>
                    <Paper elevation={3}
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                    
                        <Title>Sending Firm</Title>
                        <Box>
                            <Grid container className="doc-content-section adjuster-form-data">
                                <Grid xs={3} className="adjuster-form-label">
                                    Firm
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.sendingFirm.firmName}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Address
                                </Grid>
                                <Grid xs={9}>
                                    {formatAddress(demandDoc.sendingFirm.attorney.contactInfo.address)}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Phone
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.sendingFirm.primaryContact.contactInfo.phoneNumber}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Fax
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.sendingFirm.primaryContact.contactInfo.faxNumber}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Email
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.sendingFirm.primaryContact.contactInfo.emailAddress}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Case No.
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.sendingFirm.firmCaseNumber}
                                </Grid>
        
                                <Grid xs={3} className="adjuster-form-label">
                                    Sending Party
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.sendingFirm.primaryContact.firstName}  {demandDoc.sendingFirm.primaryContact.lastName}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>

                    <Paper elevation={3}
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                    
                        <Title>Claimant</Title>
                        <Box>
                            <Grid xs={12} container className="doc-content-section adjuster-form-data">
                                <Grid xs={3} className="adjuster-form-label">
                                    Name
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.claimInfo.claimant.firstName}  {demandDoc.claimInfo.claimant.lastName}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    DOB
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.claimInfo.claimant.dateOfBirth}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Address
                                </Grid>
                                <Grid xs={9}>
                                    {formatAddress(demandDoc.claimInfo.claimant.contactInfo.address)}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Phone
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.claimInfo.claimant.contactInfo.phoneNumber}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Email
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.claimInfo.claimant.contactInfo.emailAddress}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Health Insurance
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.claimInfo.claimant.healthInsurance}
                                </Grid>
        
                                <Grid xs={3} className="adjuster-form-label">
                                    Marital Status
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.claimInfo.claimant.maritalStatus}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Profession
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.claimInfo.claimant.profession}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Stack>
            </Grid>
            <Grid xs={6}>
                <Stack spacing={3}>
                    <Paper elevation={3}
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                    
                        <Title>Recipient Carrier</Title>
                        <Box>
                            <Grid  container className="doc-content-section adjuster-form-data">
                                <Grid xs={3} item className="adjuster-form-label">
                                    Carrier
                                </Grid>
                                <Grid xs={9} item={true}>
                                    {demandDoc.recipientCarrier.carrierCommonName}
                                </Grid>

                                <Grid xs={3} item={true} className="adjuster-form-label">
                                    Addresss
                                </Grid>
                                <Grid xs={9} item={true}>
                                    {formatAddress(demandDoc.recipientCarrier.contactInfo.address)}
                                </Grid>
                                <Grid xs={3} item={true} className="adjuster-form-label">
                                    Phone
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.recipientCarrier.contactInfo.phoneNumber}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Fax
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.recipientCarrier.contactInfo.faxNumber}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Email
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.recipientCarrier.contactInfo.emailAddress}
                                </Grid>

                            </Grid>
                        </Box>
                    </Paper>

                    <Paper elevation={3}
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                    
                        <Title>Recipient Adjuster</Title>
                        <Box>
                            <Grid container className="doc-content-section adjuster-form-data">
                                <Grid xs={3} className="adjuster-form-label">
                                    Name
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.recipientAdjuster.firstName} {demandDoc.recipientAdjuster.lastName}
                                </Grid>

                                    <Grid xs={3} className="adjuster-form-label">
                                    Phone
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.recipientAdjuster.contactInfo.phoneNumber}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Email
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.recipientAdjuster.contactInfo.emailAddress}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>


                    <Paper elevation={3}
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                    
                        <Title>Insured</Title>
                        <Box>
                            <Grid container className="doc-content-section adjuster-form-data">
                                <Grid xs={3} className="adjuster-form-label">
                                    Name
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.claimInfo.insuredFirstName} {demandDoc.claimInfo.insuredLastName}
                                </Grid>

                                <Grid xs={3} className="adjuster-form-label">
                                    Policy No.
                                </Grid>
                                <Grid xs={9}>
                                    {demandDoc.claimInfo.insuredPolicyNumber}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Stack>
            </Grid>
        </Grid>
    )

}

export default AdjusterViewerParties;