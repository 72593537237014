
import React, { useState, useEffect  } from "react";
import { sendPreviewApi } from '../api';
import StatusDialog from "./StatusDialog";



const SendPreviewDialog = (props) => {
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");

    const showStatusDialog = (text) =>  {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () =>  {
        props.handleClose();
    };

    const handlePreviewEmail = async (userTriggered) => {

        if (userTriggered) {
            setStatusCloseDisabled(true);
            setStatusDialogTitle("Email preview to me");
            showStatusDialog("Sending email preview to me...");
        }

        sendPreviewApi(props.documentId, props.user)
          .then((response) => { 
            
            if (response.status == 200) {
                setStatusCloseDisabled(false);
                showStatusDialog("Success! You should receive an email with the demand preview in a few minutes.");
            }
            else if (response.status == 201) {
                setStatusDialogText("The demand is being regenerated. This should only take a few moments.");
                setTimeout(function(){handlePreviewEmail(false, null)},3000);
            }
            else {
                setStatusCloseDisabled(false);
                showStatusDialog("There was an error sending the email :-(");
            }
        
        });
    }

    useEffect(() => {
         setStatusDialogOpen(props.dialogOpen);
         if (props.dialogOpen) {
            handlePreviewEmail(true);
         }
    }, [props.dialogOpen]);

    return (
        <>
            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    )

}

export default SendPreviewDialog;