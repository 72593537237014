import awsExports from './aws-exports';
import React, { useEffect,useState, createRef } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Title from './Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatAddress, formatCurrency,formatDate} from './common';
import {getAttachmentS3Url, downloadAttachment} from './attachments_api';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import EnclosuresTable from './EnclosuresTable';
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from './slices/accordionSlice'

const AdjusterViewerFacts= (props) => {

    const [detailsPaneOpen, setDetailsPaneOpen] = useState(false);
    const [currentDocumentUrl, setCurrentDocumentUrl] = useState("");
    const [attachments, setAttachments] = useState([]);

    const frameRef = createRef();
    const demandDoc = props.demandDoc;

    const dispatch = useDispatch();
    const panels = useSelector(state => state.accordion.panels);

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        dispatch(toggle({id:panel,expanded:newExpanded}));
    };

    const isExpanded = (panelId) => {
        const matchingPanel = panels.find(panel => panel.id === panelId)
        if (matchingPanel) {
            return matchingPanel.expanded;
        }
        else {
            return panelId == 'facts-highlight' ? true : false;
        }
    } 

    const findAttachment = (attachmentId) => {
        for (let i = 0; i < demandDoc.attachments.length; i++) {
            const attachment = demandDoc.attachments[i];
            if (attachment.attachmentId == attachmentId)
                return attachment;
        }

        return null;
    };

    const resizeIframe = () => {
        if (frameRef.current != null) {
            let h = window.innerHeight-150;    
            h = 700;
            frameRef.current.style.height = h+'px';
        }
    };

    const hideDetailsPanel = () => {
        setDetailsPaneOpen(false);
    };

    const viewDetailsPanelCallback = async (attachmentId, title) => {
    
        // Load the documents
        const s3_url = await getAttachmentS3Url(attachmentId);
        setCurrentDocumentUrl(s3_url);

        // Open the panel
        setDetailsPaneOpen(true);
    };

    const downloadAttachmentCallback = async (attachmentId) => {
        downloadAttachment(findAttachment(attachmentId));
    };

    useEffect(() => {
        const tempAttachments = []
        for (let i = 0; i < props.demandDoc.attachments.length; i++) {
            const attachment = props.demandDoc.attachments[i];

            if (attachment.associatedDemandEntity.demandSection == 'FactsLiability') {
                tempAttachments.push(attachment);
            }
        }
        setAttachments([...tempAttachments]);
    }, []);
    
    useEffect(() => {
        resizeIframe();
    }, [frameRef]);

    return (
        <Grid container sx={{mt:1}}>
        <Grid xs={detailsPaneOpen ? 6 : 8} >
            <Accordion sx={{mb:1}}  expanded={isExpanded('facts-highlight')} onChange={handleAccordionChange('facts-highlight')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid container xs={12}>
                        <Title>Highlights</Title>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                   <Stack className='highlights-text-block'>
                        <div style={{marginBottom: "15px"}}>
                            {demandDoc.liability.lossDescriptionNarrative}
                        </div>

                        <div>
                            {demandDoc.liability.liabilityDescriptionNarrative}
                        </div>
                   </Stack>
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{mb:1}} expanded={isExpanded('facts-liab')} onChange={handleAccordionChange('facts-liab')}>
                <AccordionSummary
                    
                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Grid container xs={12}>
                        <Title>Facts & Liabilities</Title>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <Box>
                        <Grid container className="doc-content-section adjuster-form-data">
                            <Grid xs={3} className="adjuster-form-label">
                                Date of Loss
                            </Grid>
                            <Grid xs={9}>
                                {demandDoc.claimInfo.dateOfLoss}
                            </Grid>

                            <Grid xs={3} className="adjuster-form-label">
                                Loss State
                            </Grid>
                            <Grid xs={9}>
                                {demandDoc.claimInfo.lossState}
                            </Grid>

                            <Grid xs={3} className="adjuster-form-label">
                                Loss County
                            </Grid>
                            <Grid xs={9}>
                                {demandDoc.claimInfo.lossCounty}
                            </Grid>

                            <Grid xs={3} className="adjuster-form-label">
                                Location
                            </Grid>
                            <Grid xs={9}>
                                {demandDoc.claimInfo.lossLocation}
                            </Grid>


                            <Grid xs={3} className="adjuster-form-label">
                                Vehicle Damage
                            </Grid>
                            <Grid xs={9}>
                                {formatCurrency(demandDoc.vehicleEstimate.damageTotalAmount)}
                            </Grid>


                            <Grid xs={3} className="adjuster-form-label">
                                Liability
                            </Grid>
                            <Grid xs={9}>
                                {demandDoc.liability.percentNegligenceClaimed == '1.0' ? 'Cleared' : demandDoc.liability.percentNegligenceClaimed }
                            </Grid>
                        </Grid>
                    </Box>
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{mb:1}} expanded={isExpanded('facts-enclosures')} onChange={handleAccordionChange('facts-enclosures')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon color='secondary' />}
                aria-controls="panel2c-content"
                id="panel2c-header"
                >
                    <Grid container xs={12}>
                        <Title>Enclosures </Title>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>

                    <EnclosuresTable demandDoc={demandDoc} attachments={attachments} downloadAttachment={downloadAttachmentCallback} viewAttachment={viewDetailsPanelCallback} />
        
                </Typography>
                </AccordionDetails>
            </Accordion>

        </Grid>
        <Grid xs={detailsPaneOpen ? 6 : 4} >
            {detailsPaneOpen ? (
            <div style={{ height: "100%", marginLeft:"10px" }}>
                <Stack direction={"column"}>
                    <Stack direction={"row"}>
                        <Grid container xs={12}>
                            <Grid xs={6} >
                                <Title>Enclosures</Title>
                            </Grid>
                            <Grid xs={6} textAlign={"right"}>
                                <IconButton title='Close Details' style={{width:'25px'}} onClick={() => { hideDetailsPanel()}}>
                                <Close color='secondary'/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Stack>
                   
                    <div ref={frameRef}>
                        <iframe onLoad={resizeIframe()} id="documentFrame" src={currentDocumentUrl} title="Document" width={"100%"} height={"100%"}>
                        </iframe>
                    </div>
                </Stack>
            </div>
            ) : null }
        </Grid>
    </Grid>
    )

}

export default AdjusterViewerFacts;