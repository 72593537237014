import { Link, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

import React, { useEffect, useState, createRef, useRef } from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LinearProgress, Stack, Paper, Menu, MenuItem, Button, ListItemIcon, ListItemText, Divider, IconButton, ToggleButton, ToggleButtonGroup, Card } from '@mui/material';
import awsExports from './aws-exports';
import Title from './Title';
import { dateComparator } from './comparators';
import { convertUTCDateTimeToLocalDate, convertUTCDateTimeToLocalTime, mapStatusToChipClass, mapStatusToLabel, userHasPermission, mapDeliveryStatusToChipClass, mapDeliveryStatusToLabel, isRequestApprovalEnabled, isUploadMedicalsEnabled } from './common';
import { MoreHoriz, RemoveRedEyeOutlined, FaxOutlined, SendOutlined, ArchiveOutlined, CheckBoxRounded, ApprovalRounded, ErrorOutlineRounded, MedicalServices, MedicalInformation } from '@mui/icons-material';
import { getUserApi, getConfigValue, archiveApi, sendManuallyApi, rejectDemandApi, getUploadMedicalsUrl, pushMedicalsCsvToS3, uploadMedicalsApi } from './api';
import { isLawFirmUser, isPrecedentUser } from './common-roles';
import styled from '@emotion/styled';

import AttorneySendDialog from './dialogs/AttorneySendDialog'
import ArchiveDialog from './dialogs/ArchiveDialog'
import SendManuallyDialog from './dialogs/SendManuallyDialog'
import CarrierSendFaxDialog from './dialogs/CarrierSendFaxDialog'
import CarrierSendDialog from './dialogs/CarrierSendDialog'
import FirmRejectDialog from './dialogs/FirmRejectDialog';
import FirmApproveDialog from './dialogs/FirmApproveDialog';
import SendPreviewDialog from './dialogs/SendPreviewDialog'
import MedicalExtractionSendDialog from './dialogs/MedicalExtractionSendDialog'
import StatusDialog from './dialogs/StatusDialog';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export function InventoryList(props) {

    const viewType = props.viewType;

    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    const rootApiUrl = awsExports.ROOT_API_URL;
    const [demandData, setDemandData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEls, setAnchorEls] = useState([]);
    const [userData, setUserData] = useState();
    const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [carrierDialogOpen, setCarrierDialogOpen] = useState(false);
    const [carrierDemandId, setCarrierDemandId] = useState();
    const [deliveryEmail, setDeliveryEmail] = useState();
    const [carrierName, setCarrierName] = useState();
    const [carrierFaxDialogOpen, setCarrierFaxDialogOpen] = useState(false);
    const [manualDialogOpen, setManualDialogOpen] = useState(false);
    const [deliveryFax, setDeliveryFax] = useState();
    const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const [attorneyDialogOpen, setAttorneyDialogOpen] = useState(false);
    const [medicalExtractionDialogOpen, setMedicalExtractionDialogOpen] = useState(false);
    const [currentArchiveDemand, setCurrentArchiveDemand] = useState();
    const [currentApprovalDemand, setCurrentApprovalDemand] = useState();
    const [currentMedicalExtractionDemand, setCurrentMedicalExtractionDemand] = useState();
    const [currentApprovalEmail, setCurrentApprovalEmail] = useState();
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [firmApproveDialogOpen, setFirmApproveDialogOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [filterToggleValue, setFilterToggleValue] = useState("active");
    const [dateOfSendError, setDateOfSendError] = useState(false);
    const [sendReasonError, setSendReasonError] = useState(false);
    const [sendMethodError, setSendMethodError] = useState(false);
    const [demandCreateCTA, setDemandCreateCTA] = useState(false);
    const gridRef = createRef();

    const navigate = useNavigate();

    const getDemandListApi = async (active_filter) => {
        const requestBody = {
            "active_filter": active_filter
        };
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(requestBody)
        };

        setLoading(true);

        const response = await fetch(
            rootApiUrl + "/inventory", settings
        ).then((response) => response.json())
            .finally(() => {
                setLoading(false);
            });

        setDemandData(response);
    };

    const getUser = async () => {
        setUserData(await getUserApi(user));
        setDemandCreateCTA(await getConfigValue("createDemandEnabled", null, user) === 1);
    };

    const archiveDemand = (archiveReason) => {

        setArchiveDialogOpen(false);
        const archiveData = {
            "archiveReason": archiveReason
        };

        archiveApi(currentArchiveDemand.document.documentId, archiveData, user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("Success! The demand has been archived.");
                    updateDocumentStatus(currentArchiveDemand.document.documentId, 'DocumentArchived');
                    setCurrentArchiveDemand(null);
                }
                else {
                    showStatusDialog("There was an error archiving the demand :-(");
                }
            });
    };

    const sendManually = (sendDate, sendReason, sendMethod) => {

        setManualDialogOpen(false);

        const manualFormData = {
            "sendDate": sendDate.format("YYYY-MM-DDTHH:mm:ssZ"),
            "sendReason": sendReason,
            "sendMethod": sendMethod
        };

        sendManuallyApi(carrierDemandId, manualFormData, user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("Success! The demand has been updated.");
                    updateDocumentStatus(carrierDemandId, 'DocumentSubmitted');
                    updateDeliveryStatus(carrierDemandId, 'ManuallySent');
                }
                else {
                    showStatusDialog("There was an error updating the demand :-(");
                }
            });
    };

    const rejectDemand = (reason, comment) => {
        setRejectDialogOpen(false);

        setStatusDialogTitle("Sending Rejection");
        showStatusDialog("Sending rejection notification to Precedent...");


        rejectDemandApi(carrierDemandId, { "rejectReason": reason, "comment": comment }, user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("The rejection notification has been delivered.");
                    updateDocumentStatus(carrierDemandId, 'RejectedByLawFirm');
                }
                else {
                    showStatusDialog("There was an error delivering the rejection.");
                }
            });
    };

    /**
     * Called when the send to carrier has completed it's workflow.
     *  
     */
    const sendFirmApprovalRequestComplete = (success) => {
        if (success) {
            updateDocumentStatus(carrierDemandId, 'DocumentSubmitted');
        }
    };

    /**
     * Called when the send to carrier has completed it's workflow.
     *  
     */
    const sendEmailToCarrierComplete = (success) => {
        if (success) {
            updateDocumentStatus(carrierDemandId, 'DocumentApproved');
            updateDeliveryStatus(carrierDemandId, 'EmailSubmitted');
        }
    };

    /**
     * Called when the send to carrier has completed it's workflow.
     *  
     */
    const sendFaxToCarrierComplete = (success) => {
        if (success) {
            updateDocumentStatus(carrierDemandId, 'DocumentApproved');
            updateDeliveryStatus(carrierDemandId, 'EmailSubmitted');
        }
    };

    /**
     * Called when the send to carrier has completed it's workflow.
     *  
     */
    const sendForAttorneyApprovalComplete = (success) => {
        if (success) {
            updateDocumentStatus(currentApprovalDemand.document.documentId, 'WaitingFirmApproval');
        }
    };

    /**
    * Called when the preview email send.
    *  
    */
    const sendPreviewComplete = (success) => {
    };

    /**
     * Called when we finished sending document for medical extraction
     *
     */
    const sendForMedicalExtractionComplete = (success) => {
        if (success) {
            updateDocumentStatus(currentMedicalExtractionDemand.document.documentId, 'AwaitingMedicalExtraction');
        }
    };

    // UI Handlers

    const handleSubmitNewDemandClick = () => {
        navigate('/submit-new-demand');
    };

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const handleFirmApproveDialogClose = () => {
        setFirmApproveDialogOpen(false);
    };

    const handleFirmApproveDemand = (row, event) => {
        handleMenuClose(row.document.documentId, event);
        setCurrentApprovalDemand(row);
        setCarrierDemandId(row.document.documentId);
        setFirmApproveDialogOpen(true);
    };

    const handleRejectDemand = (row, event) => {
        handleMenuClose(row.document.documentId, event);
        setCarrierDemandId(row.document.documentId);
        setRejectDialogOpen(true);
    };

    const handleRejectDialogClose = () => {
        setRejectDialogOpen(false);
    };

    const handleSendManually = (id, event) => {
        setFormData({});
        setDateOfSendError(false);
        setSendMethodError(false);
        setSendReasonError(false);

        setCarrierDemandId(id);
        setManualDialogOpen(true);
        handleMenuClose(id, event);
    };

    const handleApproveDemandClick = (row, attorneyEmail, event) => {
        handleMenuClose(row.document.documentId, event);
        setCurrentApprovalDemand(row);
        setCurrentApprovalEmail(attorneyEmail)
        setAttorneyDialogOpen(true);

    };

    const handleAttorneyDialogClose = () => {
        setAttorneyDialogOpen(false);
    };

    const handleArchiveDemand = (row, event) => {
        setFormData({});
        setCurrentArchiveDemand(row);
        setArchiveDialogOpen(true);
        handleMenuClose(row.document.documentId, event);
    };

    const handleArchiveDialogClose = () => {
        setArchiveDialogOpen(false);
    };

    const handleFilterToggleChange = (event, newFilterToggleValue) => {
        // Null check enforces that one button is active
        if (newFilterToggleValue !== null) {
            setFilterToggleValue(newFilterToggleValue);
            if (newFilterToggleValue === "active") {
                getDemandListApi(true);
            } else if (newFilterToggleValue === "inactive") {
                getDemandListApi(false);
            }
        }
    };

    const handleSendManuallyDialogClose = () => {
        setManualDialogOpen(false);
    };

    const handleCarrierFaxDialogClose = () => {
        setCarrierFaxDialogOpen(false);
    };

    const handlePreviewDialogClose = () => {
        setPreviewDialogOpen(false);
    };

    const handleCarrierDialogClose = () => {
        setCarrierDialogOpen(false);
    };

    const showPreviewDialog = (text) => {
        setPreviewDialogOpen(true);
    };

    const handleMenuButtonClick = (id, event) => {
        let els = [...anchorEls]
        els[id] = event.currentTarget;
        setAnchorEls(els);
    };

    const handleMenuClose = (id, event) => {
        anchorEls[id] = null;
        setAnchorEls([...anchorEls]);
    };

    const handlePreviewEmail = (id, event) => {
        setCarrierDemandId(id);
        handleMenuClose(id, event);
        setPreviewDialogOpen(true);
    };

    const handleMedicalExtractionClick = (row, event) => {
        setCarrierDemandId(row.document.documentId);
        setCurrentMedicalExtractionDemand(row);
        handleMenuClose(row.document.documentId, event);
        setMedicalExtractionDialogOpen(true);
    };

    const handleMedicalExtractionDialogClose = () => {
        setMedicalExtractionDialogOpen(false);
    };

    const uploadMedicalsInputRef = useRef(null);
    var uploadMedicalsDocumentId = null;

    const handleUploadMedicalsClick = (documentId) => {
        uploadMedicalsDocumentId = documentId;
        uploadMedicalsInputRef.current.click();
    };

    const handleUploadMedicalsCsv = async (e) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        
        const uploadFields = await getUploadMedicalsUrl(uploadMedicalsDocumentId, user);

        // Upload the csv to s3
        const success = await pushMedicalsCsvToS3(uploadFields, file);

        if (success) {
            const uploadMedicalsResponse = await uploadMedicalsApi(uploadMedicalsDocumentId, user);
            const responseJson = await uploadMedicalsResponse.json();
            if (uploadMedicalsResponse.status == 200) {
                showStatusDialog("Success uploading medicals!");
                if (responseJson.metadata) {
                    updateDocumentStatus(uploadMedicalsDocumentId, responseJson.metadata.documentStatus);
                }
            } else if (uploadMedicalsResponse.status == 400) {
                showStatusDialog(responseJson["error"]);
            } else {
                showStatusDialog("Unexpected error uploading medicals, check logs");
            }
        }
        else {
            showStatusDialog("There was an error submitting the csv");
        }
        e.target.value = null;
    };
    

    const handleEmailCarrier = (documentId, deliveryEmail, carrierName, event) => {
        setCarrierDemandId(documentId);
        setDeliveryEmail(deliveryEmail);
        setCarrierName(carrierName);
        handleMenuClose(documentId, event);
        setCarrierDialogOpen(true);
    };

    const handleFaxCarrier = (documentId, fax, carrierName, event) => {
        setCarrierDemandId(documentId);
        setDeliveryFax(fax);
        setCarrierName(carrierName);
        handleMenuClose(documentId, event);
        setCarrierFaxDialogOpen(true);
    };

    const findStatusEventDate = (status, statusHistory) => {
        const matchedStatus = statusHistory.toReversed().find((element) => element.documentStatus == status);
        return matchedStatus;
    };

    const updateDocumentStatus = (documentId, newStatus) => {
        demandData.forEach((row) => {
            if (row.document.documentId === documentId) {
                row.documentStatus = newStatus;
            }
        });

        setDemandData([...demandData]);
    };

    const updateDeliveryStatus = (documentId, newStatus) => {
        demandData.forEach((row) => {
            if (row.document.documentId === documentId) {
                row.deliveryStatus = newStatus;
            }
        });

        setDemandData([...demandData]);
    };

    const resizeGrid = () => {
        if (gridRef.current != null) {
            let windowH = window.innerHeight - 200;
            gridRef.current.style.height = windowH + 'px';
        }
    };

    window.addEventListener('resize', function (event) {
        resizeGrid();
    }, true);

    useEffect(() => {
        resizeGrid();
    }, [gridRef]);

    useEffect(() => {
        getDemandListApi(true);
        getUser();
    }, []);

    const demand_col = {
        field: 'demand_id',
        headerName: 'Demand',
        width: 150,
        headerAlign: 'left',
        align: 'left',
        valueGetter: (params) => {
            return params.row.document.claimInfo?.claimNumber || "Not on File"
        },
        renderCell: (params) => {
            if (isPrecedentUser(userData)) {
                return (
                    <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                        <Link to={"/a/" + params.row.document.documentId}>{params.row.document.claimInfo?.claimNumber || params.row.document.documentId}</Link>
                        <div className='pin-label'>PIN: {params.row.documentAdminPIN}</div>
                    </div>
                )
            }
            else if (isLawFirmUser(userData)) {
                return (
                    <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                        <Link to={"/l/" + params.row.document.documentId}>{params.row.document.claimInfo.claimNumber ? params.row.document.claimInfo.claimNumber : params.row.document.documentId}</Link>
                        <div className='pin-label'>PIN: {params.row.attorneyWebPIN}</div>
                    </div>
                )
            }
        }
    };

    const matter_col = {
        field: 'matter',
        headerName: 'Matter',
        width: 160,
        headerAlign: 'left',
        valueGetter: (params) => {
            return params.row.document.sendingFirm?.firmCaseNumber || "Not on File"
        },
        renderCell: (params) => {
            if (params.row.litifyUrl) {
                return (<a target="litify" href={params.row.litifyUrl}>{params.row.document.sendingFirm?.firmCaseNumber || "Not on File"}</a>)
            }
            else {
                return params.row.document.sendingFirm?.firmCaseNumber || "Not on File"
            }
        }
    };

    const lawfirm_col = {
        field: 'lawfirm',
        headerName: 'Law firm',
        width: 150,
        headerAlign: 'left',
        align: 'left',
        valueGetter: (params) => {
            if (params.row.document.sendingFirm?.firmName) {
                return params.row.document.sendingFirm.firmName;
            }
            return "Not on File"
        }
    };

    const client_col = {
        field: 'client_name',
        headerName: 'Client name',
        width: 150,
        headerAlign: 'left',
        align: 'left',
        valueGetter: (params) => {
            if (params.row.document.claimInfo?.claimant?.firstName != null || params.row.document.claimInfo?.claimant?.lastName != null) {
                return (params.row.document.claimInfo?.claimant?.firstName ?? "") + " " + (params.row.document.claimInfo?.claimant?.lastName ?? "")
            }
            return "Not on File"
        }
    };

    const attorney_col = {
        field: 'attorney_name',
        headerName: 'Attorney',
        width: 150,
        headerAlign: 'left',
        align: 'left',
        valueGetter: (params) => {
            if (params.row.document.sendingFirm?.attorney?.firstName != null || params.row.document.sendingFirm?.attorney?.lastName != null) {
                return (params.row.document.sendingFirm?.attorney?.firstName ?? "") + " " + (params.row.document.sendingFirm?.attorney?.lastName ?? "")
            }

            return "Not on file"
        }
    };


    const case_mgr_col = {
        field: 'case_mgr',
        headerName: 'Case manager',
        width: 150,
        headerAlign: 'left',
        align: 'left',
        valueGetter: (params) => {
            if (params.row.document.sendingFirm?.primaryContact?.firstName != null || params.row.document.sendingFirm?.primaryContact?.lastName != null) {
                return (params.row.document.sendingFirm?.primaryContact?.firstName ?? "") + " " + (params.row.document.sendingFirm?.primaryContact?.lastName ?? "")
            }

            return "Not on file"
        }
    };


    const carrier_col = {
        field: 'carrier',
        headerName: 'Recipient carrier',
        width: 160,
        headerAlign: 'left',
        align: 'left',
        valueGetter: (params) => {
            return params.row.document.recipientCarrier.carrierCommonName
        },
        renderCell: (params) => {
            return (<div className='MuiDataGrid-cellContent'>{params.row.document.recipientCarrier.carrierCommonName}<br /><span className='pin-label'>{params.row.document.claimInfo?.claimCoverage || "Not on file"}</span></div>);
        }
    };

    const date_created_col = {
        field: 'date_created',
        headerName: 'Date created',
        width: 115,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            return params.row.document.createdTs * 1000;
        },
        renderCell: (params) => {
            return (<div className='MuiDataGrid-cellContent'>{convertUTCDateTimeToLocalDate(new Date(params.row.document.createdTs * 1000))}<br /><span className='pin-label'>{convertUTCDateTimeToLocalTime(new Date(params.row.document.createdTs * 1000))}</span></div>);
        }
    };

    const date_approved_col = {
        field: 'date_approved',
        headerName: 'Date approved',
        width: 115,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            const statusEvent = findStatusEventDate("DocumentApproved", params.row.documentStatusHistory);
            if (statusEvent) {
                return statusEvent.timestamp * 1000;
            }
            else
                return '0';
        },
        renderCell: (params) => {
            const statusEvent = findStatusEventDate("DocumentApproved", params.row.documentStatusHistory);
            if (statusEvent) {
                return (<div className='MuiDataGrid-cellContent'>{convertUTCDateTimeToLocalDate(new Date(statusEvent.timestamp * 1000))}<br /><span className='pin-label'>{convertUTCDateTimeToLocalTime(new Date(statusEvent.timestamp * 1000))}</span></div>);
            }
            else {
                return ("");
            }
        }
    };

    const date_submitted_col = {
        field: 'date_submitted',
        headerName: 'Date submitted',
        width: 115,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            const statusEvent = findStatusEventDate("DocumentSubmitted", params.row.documentStatusHistory);
            if (statusEvent) {
                return statusEvent.timestamp * 1000;
            }
            else {
                return 0;
            }
        },
        renderCell: (params) => {
            const statusEvent = findStatusEventDate("DocumentSubmitted", params.row.documentStatusHistory);
            if (statusEvent) {
                return (<div className='MuiDataGrid-cellContent'>{convertUTCDateTimeToLocalDate(new Date(statusEvent.timestamp * 1000))}<br /><span className='pin-label'>{convertUTCDateTimeToLocalTime(new Date(statusEvent.timestamp * 1000))}</span></div>);
            }
            else {
                return "";
            }
        }
    };

    const date_recvd_col = {
        field: 'date_received',
        headerName: 'Date received',
        width: 115,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            const statusEvent = findStatusEventDate("DocumentReceived", params.row.documentStatusHistory);
            if (statusEvent) {
                return statusEvent.timestamp * 1000;
            }
            else
                return '0';
        },
        renderCell: (params) => {
            const statusEvent = findStatusEventDate("DocumentReceived", params.row.documentStatusHistory);
            if (statusEvent) {
                return (<div className='MuiDataGrid-cellContent'>{convertUTCDateTimeToLocalDate(new Date(statusEvent.timestamp * 1000))}<br /><span className='pin-label'>{convertUTCDateTimeToLocalTime(new Date(statusEvent.timestamp * 1000))}</span></div>);
            }
            else {
                return "";
            }
        }
    };

    const document_status_col = {
        field: 'status',
        headerName: 'Document status',
        width: 200,
        valueGetter: (params) => {
            return mapStatusToLabel(params.row.documentStatus);
        },
        renderCell: (params) => (
            <div className={mapStatusToChipClass(params.row.documentStatus)}>{mapStatusToLabel(params.row.documentStatus)}</div>
        )
    };

    const delivery_status_col = {
        field: 'delivery_status',
        headerName: 'Delivery status',
        width: 200,
        valueGetter: (params) => {
            return mapDeliveryStatusToLabel(params.row.deliveryStatus);
        },
        renderCell: (params) => (
            <div className={mapDeliveryStatusToChipClass(params.row.deliveryStatus)}>{mapDeliveryStatusToLabel(params.row.deliveryStatus)}</div>
        )
    };

    const action_col = {
        field: 'action',
        headerName: '',
        width: 85,
        renderCell: (params) => {
            if (userHasPermission("DemandSend", userData) || userHasPermission("DemandArchive", userData) || userHasPermission("DemandPreview", userData)) {
                return (
                    <div>
                        <IconButton
                            id={params.row.document.documentId}
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => { handleMenuButtonClick(params.row.document.documentId, e) }}
                        >
                            <MoreHoriz color='primaryActions' />
                        </IconButton>
                        <Menu
                            id={`${params.row.document.documentId}-menu`}
                            keepMounted
                            anchorEl={anchorEls[params.row.document.documentId]}
                            open={Boolean(anchorEls[params.row.document.documentId])}
                            onClose={(e) => { handleMenuClose(params.row.document.documentId, e) }}
                        >
                            {userHasPermission("DemandMedicalExtraction", userData) && params.row.documentStatus === 'DocumentUploaded' ?
                                <div>
                                    <MenuItem onClick={(e) => { handleMedicalExtractionClick(params.row, e) }}>
                                        <ListItemIcon>
                                            <MedicalServices />
                                        </ListItemIcon>
                                        <ListItemText primary="Send for Medical Extraction" />
                                    </MenuItem>
                                </div>
                                : null}
                            {isUploadMedicalsEnabled(userData, params.row.documentStatus) ?
                                <div>
                                    <MenuItem onClick={(e) => { handleUploadMedicalsClick(params.row.document.documentId) }}>
                                        <ListItemIcon>
                                            <MedicalInformation />
                                        </ListItemIcon>
                                        <ListItemText primary="Upload Medicals" />
                                    </MenuItem>
                                    <div>
                                        <Divider />
                                    </div>
                                </div>
                                : null}
                            {userHasPermission("DemandPreview", userData) ?
                                <div>
                                    <MenuItem onClick={(e) => { handlePreviewEmail(params.row.document.documentId, e) }}>
                                        <ListItemIcon>
                                            <RemoveRedEyeOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary="Email preview to me" />
                                    </MenuItem>
                                </div>
                                : null}

                            {userHasPermission("DemandSend", userData) && params.row.documentStatus != 'DocumentArchived' ?
                                <div>
                                    <Divider />
                                    <MenuItem onClick={(e) => { handleEmailCarrier(params.row.document.documentId, params.row.deliveryEmail, params.row.document.recipientCarrier?.carrierCommonName, e) }}>
                                        <ListItemIcon>
                                            <SendOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary="Email to carrier" />
                                    </MenuItem>
                                    <MenuItem onClick={(e) => { handleFaxCarrier(params.row.document.documentId, params.row.deliveryFax, params.row.document.recipientCarrier?.carrierCommonName, e) }}>
                                        <ListItemIcon>
                                            <FaxOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary="Fax to carrier" />
                                    </MenuItem>
                                    <MenuItem onClick={(e) => { handleSendManually(params.row.document.documentId, e) }}>
                                        <ListItemIcon>
                                            <CheckBoxRounded />
                                        </ListItemIcon>
                                        <ListItemText primary="Mark as sent manually" />
                                    </MenuItem>
                                </div> : null}
                            {userHasPermission("DemandRequestApproval", userData) && params.row.documentStatus != 'DocumentArchived' ?
                                <div>
                                    <Divider />
                                    <MenuItem disabled={!isRequestApprovalEnabled(params.row.documentStatus)} onClick={(e) => { handleApproveDemandClick(params.row, params.row.deliveryAttorneyEmail, e) }}>
                                        <ListItemIcon>
                                            <ApprovalRounded />
                                        </ListItemIcon>
                                        <ListItemText primary="Email approval to firm" />
                                    </MenuItem>
                                </div> : null}
                            {userHasPermission("DemandApproval", userData) && params.row.documentStatus != 'DocumentArchived' ?
                                <div>
                                    <Divider />
                                    <MenuItem disabled={params.row.documentStatus != 'WaitingFirmApproval'} onClick={(e) => { handleFirmApproveDemand(params.row, e) }}>
                                        <ListItemIcon>
                                            <ApprovalRounded />
                                        </ListItemIcon>
                                        <ListItemText primary="Approve and send to carrier" />
                                    </MenuItem>
                                    <MenuItem disabled={params.row.documentStatus != 'WaitingFirmApproval'} onClick={(e) => { handleRejectDemand(params.row, e) }}>
                                        <ListItemIcon>
                                            <ErrorOutlineRounded />
                                        </ListItemIcon>
                                        <ListItemText primary="Request additional review" />
                                    </MenuItem>
                                </div> : null}
                            {userHasPermission("DemandArchive", userData) && params.row.documentStatus != 'DocumentArchived' ?
                                <div>
                                    <Divider />
                                    <MenuItem onClick={(e) => { handleArchiveDemand(params.row, e) }}>
                                        <ListItemIcon>
                                            <ArchiveOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary="Archive demand" />
                                    </MenuItem>
                                </div> : null}
                        </Menu>
                    </div>)
            }
            else
                return null;
        }
    };


    const columns = viewType == 'precedent' ? [demand_col, matter_col, lawfirm_col, carrier_col, client_col, case_mgr_col, attorney_col,
        , date_created_col, date_approved_col, date_submitted_col, date_recvd_col,
        , document_status_col, delivery_status_col, action_col]
        :
        [demand_col, matter_col, carrier_col, client_col, case_mgr_col, attorney_col,
            , date_created_col, date_approved_col, date_submitted_col,
            , document_status_col, action_col];

    return (
        <div className='header-offset'>
            <Card>
                <Stack direction={"row"}>
                    <Stack direction={"row"} width={"35%"}>
                        <Title>Demands</Title>
                    </Stack>
                    <Stack direction={"row"} spacing={2} width={"65%"} justifyContent={"flex-end"}>
                        {userHasPermission("DemandCreate", userData) && demandCreateCTA ? (
                            <Button variant="contained" color='secondary'
                                onClick={handleSubmitNewDemandClick}
                            >Submit new demand</Button>
                        ) : null}
                    </Stack>
                </Stack>
                <Stack direction={"row"}>
                    <ToggleButtonGroup
                        value={filterToggleValue}
                        exclusive
                        size="small"
                        onChange={handleFilterToggleChange}
                    >
                        <ToggleButton value="active">Active</ToggleButton>
                        <ToggleButton value="inactive">Inactive</ToggleButton>
                    </ToggleButtonGroup>
                </Stack>

                <DataGridPro ref={gridRef} pagination getRowId={(row) => row.document.documentId} columns={columns} rows={demandData}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'date_created', sort: 'desc' }],
                        },
                        pinnedColumns: { left: ['demand_id'] },
                        pagination: { paginationModel: { pageSize: 50 } }
                    }}
                    getRowHeight={() => 'auto'}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    disableRowSelectionOnClick={true}
                    disableColumnSelector={false}
                    loading={loading}
                    pageSizeOptions={[25, 50, 100]}

                    sx={{
                        backgroundColor: "#fff",
                        mt: 1,
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: '9pt',
                            fontWeight: '500'
                        },
                        '& .MuiDataGrid-cellContent': {
                            fontSize: '9pt'
                        }
                    }}

                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'
                    }
                >
                </DataGridPro>
            </Card>


            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

            <SendPreviewDialog user={user} documentId={carrierDemandId} handler={sendPreviewComplete} handleClose={handlePreviewDialogClose} dialogOpen={previewDialogOpen}></SendPreviewDialog>

            {currentMedicalExtractionDemand ? (
                <MedicalExtractionSendDialog user={user} documentId={carrierDemandId} carrierCommonName={currentMedicalExtractionDemand.document.recipientCarrier.carrierCommonName} claimNumber={currentMedicalExtractionDemand.document.claimInfo.claimNumber} handler={sendForMedicalExtractionComplete} handleClose={handleMedicalExtractionDialogClose} dialogOpen={medicalExtractionDialogOpen}></MedicalExtractionSendDialog>
            ) : null}

            <CarrierSendDialog user={user} documentId={carrierDemandId} carrierName={carrierName} deliveryEmail={deliveryEmail} handler={sendEmailToCarrierComplete} handleClose={handleCarrierDialogClose} dialogOpen={carrierDialogOpen}></CarrierSendDialog>

            <CarrierSendFaxDialog user={user} documentId={carrierDemandId} carrierName={carrierName} deliveryFax={deliveryFax} handler={sendFaxToCarrierComplete} handleClose={handleCarrierFaxDialogClose} dialogOpen={carrierFaxDialogOpen}></CarrierSendFaxDialog>

            <SendManuallyDialog handler={sendManually} handleClose={handleSendManuallyDialogClose} dialogOpen={manualDialogOpen}></SendManuallyDialog>

            {currentArchiveDemand ? (
                <ArchiveDialog firmCaseNumber={currentArchiveDemand.document.sendingFirm.firmCaseNumber} handler={archiveDemand} handleClose={handleArchiveDialogClose} dialogOpen={archiveDialogOpen}></ArchiveDialog>
            ) : null}

            {currentApprovalDemand ? (
                <AttorneySendDialog user={user} documentId={currentApprovalDemand.document.documentId} deliveryEmail={currentApprovalEmail} handler={sendForAttorneyApprovalComplete} handleClose={handleAttorneyDialogClose} dialogOpen={attorneyDialogOpen}></AttorneySendDialog>
            ) : null}

            <FirmRejectDialog handler={rejectDemand} handleClose={handleRejectDialogClose} dialogOpen={rejectDialogOpen}></FirmRejectDialog>

            {currentApprovalDemand ?
                <FirmApproveDialog user={user} documentId={currentApprovalDemand.document.documentId} handler={sendFirmApprovalRequestComplete} documentData={currentApprovalDemand.document} deliveryEmail={currentApprovalDemand.deliveryEmail} deliveryFax={currentApprovalDemand.deliveryFax} handleClose={handleFirmApproveDialogClose} dialogOpen={firmApproveDialogOpen}></FirmApproveDialog>
                : null}

            <VisuallyHiddenInput ref={uploadMedicalsInputRef} onChange={(e) => {handleUploadMedicalsCsv(e)}} type="file" accept=".csv" />

        </div>
    );

}