export const stateLabelValues = [
    { 'label':'Alabama', 'value': 'AL' },
    { 'label':'Alaska', 'value': 'AK'},
    { 'label':'American Samoa', 'value': 'AS'},
    { 'label':'Arizona', 'value': 'AZ'},
    { 'label':'Arkansas', 'value': 'AR'},
    { 'label':'California', 'value': 'CA'},
    { 'label':'Colorado', 'value': 'CO'},
    { 'label':'Connecticut', 'value': 'CT'},
    { 'label':'Delaware', 'value': 'DE'},
    { 'label':'District of Columbia', 'value': 'DC'},
    { 'label':'States of Micronesia', 'value': 'FM'},
    { 'label':'Florida', 'value': 'FL'},
    { 'label':'Georgia', 'value': 'GA'},
    { 'label':'Guam', 'value': 'GU'},
    { 'label':'Hawaii', 'value': 'HI'},
    { 'label':'Idaho', 'value': 'ID'},
    { 'label':'Illinois', 'value': 'IL'},
    { 'label':'Indiana', 'value': 'IN'},
    { 'label':'Iowa', 'value': 'IA'},
    { 'label':'Kansas', 'value': 'KS'},
    { 'label':'Kentucky', 'value': 'KY'},
    { 'label':'Louisiana', 'value': 'LA'},
    { 'label':'Maine', 'value': 'ME'},
    { 'label':'Marshall Islands', 'value': 'MH'},
    { 'label':'Maryland', 'value': 'MD'},
    { 'label':'Massachusetts', 'value': 'MA'},
    { 'label':'Michigan', 'value': 'MI'},
    { 'label':'Minnesota', 'value': 'MN'},
    { 'label':'Mississippi', 'value': 'MS'},
    { 'label':'Missouri', 'value': 'MO'},
    { 'label':'Montana', 'value': 'MT'},
    { 'label':'Nebraska', 'value': 'NE'},
    { 'label':'Nevada', 'value': 'NV'},
    { 'label':'New Hampshire', 'value': 'NH'},
    { 'label':'New Jersey', 'value': 'NJ'},
    { 'label':'New Mexico', 'value': 'NM'},
    { 'label':'New York', 'value': 'NY'},
    { 'label':'North Carolina', 'value': 'NC'},
    { 'label':'North Dakota', 'value': 'ND'},
    { 'label':'Northern Mariana Islands', 'value': 'MP'},
    { 'label':'Ohio', 'value': 'OH'},
    { 'label':'Oklahoma', 'value': 'OK'},
    { 'label':'Oregan', 'value': 'OR'},
    { 'label':'Palau', 'value': 'PW'},
    { 'label':'Pennsilvania', 'value': 'PA'},
    { 'label':'Puerto Rico', 'value': 'PR'},
    { 'label':'Rhode Island', 'value': 'RI'},
    { 'label':'South Carolina', 'value': 'SC'},
    { 'label':'South Dakota', 'value': 'SD'},
    { 'label':'Tennessee', 'value': 'TN'},
    { 'label':'Texas', 'value': 'TX'},
    { 'label':'Utah', 'value': 'UT'},
    { 'label':'Vermont', 'value': 'VT'},
    { 'label':'Virgin Islands', 'value': 'VI'},
    { 'label':'Virginia', 'value': 'VA'},
    { 'label':'Washington', 'value': 'WA'},
    { 'label':'West Virginia', 'value': 'WV'},
    { 'label':'Wisconsin', 'value': 'WI'},
    { 'label':'Wyoming', 'value': 'WY'}
];

export const carriersList = [  
    {
        value: 'AAA Insurance',
        label: 'AAA Insurance',
    },
    {
        value: 'Allstate',
        label: 'Allstate',
    },
    {
        value: 'American Family',
        label: 'American Family',
    },
    {
        value: 'AmTrust',
        label: 'AmTrust',
    },
    {
        value: 'Auto Club Insurance',
        label: 'Auto Club Insurance',
    },
    {
        value: 'Auto Owners Insurance',
        label: 'Auto Owners Insurance',
    },
    {
        value: 'Bristol West',
        label: 'Bristol West',
    },
    {
        value: 'Direct General',
        label: 'Direct General',
    },
    {
        value: 'Farmers Insurance',
        label: 'Farmers Insurance',
    },
    {
        value: 'GEICO',
        label: 'GEICO',
    },
    {
        value: 'Granada Insurance',
        label: 'Granada Insurance',
    },
    {
        value: 'Infinity Insurance',
        label: 'Infinity Insurance',
    },
    {
        value: 'Liberty Mutual',
        label: 'Liberty Mutual',
    },
    {
        value: 'Mercury Insurance',
        label: 'Mercury Insurance',
    },
    {
        value: 'National General',
        label: 'National General',
    },
    {
        value: 'Nationwide Insurance',
        label: 'Nationwide Insurance',
    },
    {
        value: 'Plymouth Rock',
        label: 'Plymouth Rock',
    },
    {
        value: 'Progressive Insurance',
        label: 'Progressive Insurance',
    },
    {
        value: 'Safeco Insurance',
        label: 'Safeco Insurance',
    },
    {
        value: 'Security National Insurance',
        label: 'Security National Insurance',
    },
    {
        value: 'Southern Owners Insurance',
        label: 'Southern Owners Insurance',
    },
    {
        value: 'State Farm',
        label: 'State Farm',
    },
    {
        value: 'The General',
        label: 'The General',
    },
    {
        value: 'The Hartford',
        label: 'The Hartford',
    },
    {
        value: 'First Floridian Auto and Home',
        label: 'First Floridian Auto and Home',
    },
    {
        value: 'Travelers Property Casualty Insurance Company',
        label: 'Travelers Property Casualty Insurance Company',
    },
    {
        value: 'The Standard Fire Insurance Company',
        label: 'The Standard Fire Insurance Company',
    },
    {
        value: 'USAA',
        label: 'USAA',
    }
];

export const coverageList = [  
    {
        value: 'BI',
        label: 'BI',
    },
    {
        value: 'UM',
        label: 'UM',
    },
    {
        value: 'UM/UIM',
        label: 'UM/UIM',
    }
];
