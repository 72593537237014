import { useAuthenticator } from '@aws-amplify/ui-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState, createRef } from "react";
import { DataGridPro, useGridApiContext, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { LinearProgress, Paper, Stack, IconButton, Button, Tooltip, ToggleButton, ToggleButtonGroup, Card } from '@mui/material';
import awsExports from './aws-exports';
import Title from './Title';
import { dateComparator } from './comparators';
import { convertUTCDateTimeToLocalTime, convertUTCDateTimeToLocalDate, mapDeliveryStatusToLabel, mapDeliveryStatusToChipClass, mapStatusToChipClass, mapStatusToLabel, userHasPermission, findStatusEventDate } from './common';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, OpenInNewOutlined, RateReviewOutlined, RefreshOutlined } from '@mui/icons-material';
import { getUserApi, getContactLogApi, getDocumentApi } from './api';
import { ContactLogAddDialog } from './ContactLogAddDialog';
import StatusDialog from './dialogs/StatusDialog';
import ContactLogDetailPanelContent from './ContactLogDetailPanelContent';


export function ContactLogInventoryView(props) {

    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    const rootApiUrl = awsExports.ROOT_API_URL;
    const [demandData, setDemandData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [userData, setUserData] = useState();
    const [currentDocumentId, setCurrentDocumentId] = useState();
    const [currentDocumentRow, setCurrentDocumentRow] = useState();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [filterToggleValue, setFilterToggleValue] = useState("active");
    const apiRef = useRef({});
    const navigate = useNavigate();
    const location = useLocation();
    const gridRef = createRef();

    const getDetailPanelContent = React.useCallback(
        ({ row }) => {
            return (<ContactLogDetailPanelContent row={row} showHeader={true} />);
        },
        [],
    );

    const getDemandListApi = async (active_filter) => {
        const requestBody = {
            "active_filter": active_filter
        };
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(requestBody)
        };

        setLoading(true);

        const response = await fetch(
            rootApiUrl + "/inventory", settings
        ).then((response) => response.json())
            .finally(() => {
                setLoading(false);
            });

        setDemandData(response);
    };

    const refreshDocumentRow = async (documentId) => {

        const documentData = await getDocumentApi(documentId, user).then((serverResponse) => {
            return serverResponse.json();
        });

        for (let i = 0; i < demandData.length; i++) {
            if (demandData[i].document.documentId == documentId) {
                // Update existing document data with any changes.
                demandData[i].document = documentData.document;
                demandData[i].documentStatus = documentData.metadata.documentStatus;
                demandData[i].deliveryStatus = documentData.metadata.deliveryStatus;
                demandData[i].lastContactTs = documentData.metadata.lastContactTs;
                demandData[i].documentStatusHistory = documentData.metadata.documentStatusHistory;
                apiRef.current.updateRows([demandData[i]]);
                break;
            }
        }
    };


    const getUser = async () => {
        setUserData(await getUserApi(user));
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleFilterToggleChange = (event, newFilterToggleValue) => {
        // Null check enforces that one button is active
        if (newFilterToggleValue !== null) {
            setFilterToggleValue(newFilterToggleValue);
            if (newFilterToggleValue === "active") {
                getDemandListApi(true);
            } else if (newFilterToggleValue === "inactive") {
                getDemandListApi(false);
            }
        }
    };

    const handleContactLogAddCallback = (contactLog, document, metadata) => {

        setAddDialogOpen(false);
        showStatusDialog('Add contact log', 'Your entry has been added');

        for (let i = 0; i < demandData.length; i++) {
            if (demandData[i].document.documentId == document.documentId) {
                refreshDocumentRow(document.documentId);
                break;
            }
        }
    };

    const handleAddContactLogClick = (row, event) => {
        setCurrentDocumentId(row.document.documentId);
        setCurrentDocumentRow(row);
        setAddDialogOpen(true);
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
    };

    const handleNavToDemand = (documentId) => {
        navigate('/a/' + documentId, { state: { from: location.pathname } });
    };

    window.addEventListener('resize', function (event) {
        resizeGrid();
    }, true);

    useEffect(() => {
        resizeGrid();
    }, [gridRef]);

    useEffect(() => {
        getDemandListApi(true);
        getUser();
    }, []);


    // Grid Columns
    const demand_col = {
        field: 'demand_id',
        headerName: 'Demand',
        width: 125,
        valueGetter: (params) => {
            return params.row.document.claimInfo?.claimNumber || "Not on File"
        },
        renderCell: (params) => {
            return (
                <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    <div className="href" onClick={(e) => { handleNavToDemand(params.row.document.documentId); }}>{params.row.document.claimInfo?.claimNumber || params.row.document.documentId}</div>
                </div>
            )
        }
    };

    const client_col = {
        field: 'client_name',
        headerName: 'Client name',
        width: 150,
        valueGetter: (params) => {
            if (params.row.document.claimInfo?.claimant?.firstName != null || params.row.document.claimInfo?.claimant?.lastName != null) {
                return (params.row.document.claimInfo?.claimant?.firstName ?? "") + " " + (params.row.document.claimInfo?.claimant?.lastName ?? "")
            }
            return "Not on file"
        }
    };

    const adjuster_col = {
        field: 'adjuster',
        headerName: 'Adjuster',
        width: 150,
        valueGetter: (params) => {
            if (params.row.document.recipientAdjuster?.firstName != null || params.row.document.recipientAdjuster?.lastName != null) {
                return (params.row.document.recipientAdjuster?.firstName ?? "") + " " + (params.row.document.recipientAdjuster?.lastName ?? "")
            }
            return "Not on file"
        },
        renderCell: (params) => {
            var adjuster = "Not on file";
            var adjusterPhone = "";
            if (params.row.document.recipientAdjuster?.firstName != null || params.row.document.recipientAdjuster?.lastName != null) {
                adjuster = (params.row.document.recipientAdjuster?.firstName ?? "") + " " + (params.row.document.recipientAdjuster?.lastName ?? "");
                adjusterPhone = params.row.document.recipientAdjuster?.contactInfo?.phoneNumber;
            }

            return (<div className='MuiDataGrid-cellContent'>{adjuster}<br /><span className='pin-label'>{adjusterPhone}</span></div>);
        }
    };


    const carrier_col = {
        field: 'carrier',
        headerName: 'Carrier',
        width: 200,
        headerAlign: 'left',
        align: 'left',
        valueGetter: (params) => {
            return params.row.document.recipientCarrier.carrierCommonName
        },
        renderCell: (params) => {
            return (<div className='MuiDataGrid-cellContent'>{params.row.document.recipientCarrier.carrierCommonName}<br /><span className='pin-label'>{params.row.document.claimInfo?.claimCoverage || "Not on file"}</span></div>);
        }
    };

    const date_created_col = {
        field: 'date_created',
        headerName: 'Date created',
        width: 135,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            return params.row.document.createdTs * 1000;
        },
        renderCell: (params) => {
            return (<div className='MuiDataGrid-cellContent'>{convertUTCDateTimeToLocalDate(new Date(params.row.document.createdTs * 1000))}<br /><span className='pin-label'>{convertUTCDateTimeToLocalTime(new Date(params.row.document.createdTs * 1000))}</span></div>);
        }
    };


    const last_contact_col = {
        field: 'last_contact',
        headerName: 'Last contact',
        width: 135,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            if (params.row.lastContactTs) {
                return params.row.lastContactTs;
            }
            else
                return '0';
        },
        renderCell: (params) => {
            if (params.row.lastContactTs) {
                return (<div className='MuiDataGrid-cellContent'>{convertUTCDateTimeToLocalDate(new Date(params.row.lastContactTs))}<br /><span className='pin-label'>{convertUTCDateTimeToLocalTime(new Date(params.row.lastContactTs))}</span></div>);
            }
            else {
                return ("");
            }
        }
    };

    const receipt_col = {
        field: 'receipt',
        headerName: 'Receipt acknowledged',
        width: 135,
        valueGetter: (params) => {
            const statusEvent = findStatusEventDate("DocumentReceived", params.row.documentStatusHistory);
            if (statusEvent) {
                return statusEvent.timestamp * 1000;
            }
            else
                return '0';
        },
        renderCell: (params) => {
            const statusEvent = findStatusEventDate("DocumentReceived", params.row.documentStatusHistory);
            if (statusEvent) {
                return (<div className='MuiDataGrid-cellContent'>{convertUTCDateTimeToLocalDate(new Date(statusEvent.timestamp * 1000))}<br /><span className='pin-label'>{convertUTCDateTimeToLocalTime(new Date(statusEvent.timestamp * 1000))}</span></div>);
            }
            else {
                return ("");
            }
        }
    };

    const date_submitted_col = {
        field: 'date_submitted',
        headerName: 'Date submitted',
        width: 135,
        valueGetter: (params) => {
            const statusEvent = findStatusEventDate("DocumentSubmitted", params.row.documentStatusHistory);
            if (statusEvent) {
                return statusEvent.timestamp * 1000;
            }
            else
                return '0';
        },
        renderCell: (params) => {
            const statusEvent = findStatusEventDate("DocumentSubmitted", params.row.documentStatusHistory);
            if (statusEvent) {
                return (<div className='MuiDataGrid-cellContent'>{convertUTCDateTimeToLocalDate(new Date(statusEvent.timestamp * 1000))}<br /><span className='pin-label'>{convertUTCDateTimeToLocalTime(new Date(statusEvent.timestamp * 1000))}</span></div>);
            }
            else {
                return ("");
            }
        }
    };

    const document_status_col = {
        field: 'status',
        headerName: 'Document status',
        width: 200,
        headerAlign: 'left',
        align: 'left',
        valueGetter: (params) => {
            return mapStatusToLabel(params.row.documentStatus);
        },
        renderCell: (params) => (
            <div className={mapStatusToChipClass(params.row.documentStatus)}>{mapStatusToLabel(params.row.documentStatus)}</div>
        )
    };

    const delivery_status_col = {
        field: 'delivery_status',
        headerName: 'Delivery status',
        width: 175,
        valueGetter: (params) => {
            return mapDeliveryStatusToLabel(params.row.deliveryStatus);
        },
        renderCell: (params) => (
            <div className={mapDeliveryStatusToChipClass(params.row.deliveryStatus)}>{mapDeliveryStatusToLabel(params.row.deliveryStatus)}</div>
        )
    };

    const action_col = {
        field: 'action',
        headerName: '',
        width: 40,
        align: 'center',
        renderCell: (params) => {
            if (userHasPermission("ContactLog", userData)) {
                return (
                    <div>
                        <Tooltip placement="right" title="Add to contact log" arrow>
                            <IconButton onClick={(e) => { handleAddContactLogClick(params.row, e) }}><RateReviewOutlined></RateReviewOutlined></IconButton>
                        </Tooltip>
                    </div>)
            }
            else
                return null;
        }
    };

    const resizeGrid = () => {
        if (gridRef.current != null) {
            let windowH = window.innerHeight - 200;
            gridRef.current.style.height = windowH + 'px';
        }
    };


    const columns = [demand_col, carrier_col, client_col, adjuster_col, date_created_col, last_contact_col,
        date_submitted_col, receipt_col, document_status_col, delivery_status_col, action_col];


    return (
        <div className='header-offset'>
            <Card>

                <Stack direction={"row"}>
                    <Stack direction={"row"} width={"35%"}>
                        <Title>Demands</Title>
                    </Stack>
                    <Stack direction={"row"} spacing={2} width={"65%"} justifyContent={"flex-end"}>
                        <Button variant="text" color='secondary'
                            onClick={(event) => { window.open("https://precedentnc.sharepoint.com/:w:/s/ProjectShortCircuit/Ec971Jkj4GlGpeyZl1KbUpQBKsZ1xC9ZoGfvXiLnh-zefw?e=sbxE4h") }}
                            startIcon={<OpenInNewOutlined />}>Instructions</Button>
                        <Button variant="text" color='secondary'
                            onClick={(event) => { window.open("https://precedentnc.sharepoint.com/:x:/s/ProjectShortCircuit/ETuNKexfn5dPpw7BIBQJPJ8BmNMEWQJJD-zMGr-rJbT3dA?e=EO8CcF") }}
                            startIcon={<OpenInNewOutlined />}>Carrier contact info</Button>
                    </Stack>
                </Stack>
                <Stack direction={"row"}>
                    <ToggleButtonGroup
                        value={filterToggleValue}
                        exclusive
                        size="small"
                        onChange={handleFilterToggleChange}
                    >
                        <ToggleButton value="active">Active</ToggleButton>
                        <ToggleButton value="inactive">Inactive</ToggleButton>
                    </ToggleButtonGroup>
                </Stack>

                <DataGridPro pagination ref={gridRef} getRowId={(row) => row.document.documentId} columns={columns} rows={demandData}
                    apiRef={apiRef}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'date_created', sort: 'desc' }],
                        },
                        pinnedColumns: { left: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, 'demand_id'] },
                        pagination: { paginationModel: { pageSize: 50 } }
                    }}
                    getRowHeight={() => 'auto'}
                    slots={{
                        loadingOverlay: LinearProgress,
                        detailPanelExpandIcon: KeyboardArrowDownOutlined,
                        detailPanelCollapseIcon: KeyboardArrowUpOutlined
                    }}
                    disableRowSelectionOnClick={true}
                    disableColumnSelector={true}
                    loading={loading}
                    pageSizeOptions={[25, 50, 100]}
                    getDetailPanelContent={getDetailPanelContent}
                    sx={{
                        backgroundColor: "#fff",
                        mt: 1,
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: '9pt',
                            fontWeight: '500'
                        },
                        '& .MuiDataGrid-cellContent': {
                            fontSize: '9pt',
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        }
                    }}

                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'
                    }
                >
                </DataGridPro>

                <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

                <ContactLogAddDialog callback={handleContactLogAddCallback} handleClose={handleAddDialogClose} dialogOpen={addDialogOpen} user={user} documentId={currentDocumentId} documentRow={currentDocumentRow}></ContactLogAddDialog>

            </Card>
        </div>
    );

}