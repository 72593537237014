import { getCurrentDate, formatCurrency } from "../common";

//medical treatment alerts
export const getMedicalTreatmentAlerts = (documentData, viewType) => {
    const showAlerts = ['precedent', 'lawfirm'].includes(viewType);
    if(!showAlerts) return []
    
    const { dateOfLoss } = documentData.claimInfo;
    const  medicalTreatments   = documentData.medicals.medicalTreatments
    //treatments before date of loss
    const hasTreatmentDatesBeforeDateOfLoss = medicalTreatments.filter((treatment) => new Date(treatment.treatmentDate) < new Date(dateOfLoss)).length > 0;
    //treatments after today
    const hasTreatmentDatesAfterToday = medicalTreatments.filter((treatment) => new Date(treatment.treatmentDate) > new Date(getCurrentDate())).length > 0;
    //demand damages amount not within 100 of medical treatments total
    const medicalTreatmentsTotalAmount = medicalTreatments.reduce((total, treatment) => total + Number(treatment.billedAmount), 0);
    const demandDamagesAmount  = Number(documentData.damagesSummary?.demandDamagesAmount ? documentData.damagesSummary.demandDamagesAmount : medicalTreatmentsTotalAmount)
    const amountsNotWithin100 = !(Math.abs(demandDamagesAmount - medicalTreatmentsTotalAmount) < 100)
    //conditionally include alerts
    const medicalTreatmentAlerts = [
        ... hasTreatmentDatesBeforeDateOfLoss ? [{
            alertType: "treatmentBeforeDateOfLoss",
            alertMessage: "One or more treatments have a date of service prior to the date of loss",
            alertSeverity: "warning",
        }] : [],
        ... hasTreatmentDatesAfterToday ? [{
            alertType: "treatmentAfterToday",
            alertMessage: "One or more treatments have a date of service in the future",
            alertSeverity: "warning",
        }] : [],
        ... amountsNotWithin100 ? [{
            alertType: "amountsNotWithin100",
            alertMessage: `Submitted Medical Damages of ${formatCurrency(demandDamagesAmount)} vs. Extracted Charged Amounts differ by more than $100 dollars`,
            alertSeverity: "warning",
        }] : [],
    ]

    return medicalTreatmentAlerts;
}
