import { useEffect, useRef, useState } from "react";

export default function PDFViewerComponent(props) {

  const [instance, setInstance] = useState();
  const [currentPage, setCurrentPage] = useState(props.currentPage);

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.

    let PSPDFKit, _instance;

    (async function () {
      PSPDFKit = await import("pspdfkit")

      PSPDFKit.unload(container) // Ensure that there's only one PSPDFKit instance.

      const PSPDF_LICENSE_KEY = process.env.REACT_APP_PSPDF_LICENSE_KEY;
      if (!PSPDF_LICENSE_KEY) {
        console.error("REACT_APP_PSPDF_LICENSE_KEY environment variable is not set. Please set it to the license key provided by PSPDF.")
      }


      _instance = await PSPDFKit.load({
        licenseKey: PSPDF_LICENSE_KEY,
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: props.document,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        inlineTextSelectionToolbarItems: (
          { defaultItems, hasDesktopLayout },
          selection
        ) => {
          return [];
        },
        initialViewState: new PSPDFKit.ViewState({
          zoom: PSPDFKit.ZoomMode.FIT_WIDTH
        }),

      });

      //
      // Remove unsupport toolbar items
      //
      const items = _instance.toolbarItems;
      const toolbar_to_remove = ["sidebar-document-outline", "sidebar-annotations", "sidebar-bookmarks",
        "annotate", "ink", "highlighter", "text-highlighter", "ink-eraser", "signature", "image", "stamp", "note",
        "text", "callout", "line", "link", "arrow", "rectangle", "ellipse", "polygon", "cloudy-polygon",
        "polyline", "document-editor", "document-crop", "multi-annotations-selection", "pan", "print"];
      _instance.setToolbarItems(items.filter((item) => toolbar_to_remove.indexOf(item.type) < 0));

      setInstance(_instance);

    })();

    return () => PSPDFKit && PSPDFKit.unload(container)
  }, []);

  useEffect(() => {
    if (instance) {
      const newState = instance.viewState.set("currentPageIndex", props.currentPage);
      instance.setViewState(newState);
    }
  }, [props.currentPageTrigger]);

  // This div element will render the document to the DOM.
  return <div ref={containerRef} style={{ width: "100%", height: props.height }} />
}