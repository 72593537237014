import { useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Stack, ThemeProvider, Button, Alert, Snackbar, Tooltip, Popover, Chip, Menu, Typography, Card } from '@mui/material';
import { theme } from './Theme'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { formatDateTime, mapStatusToChipClass, mapStatusToLabel, userHasPermission } from './common';
import { buildProviderSummary } from './common-medicals';
import MedicalsProviderSummary from './MedicalsProviderSummary';
import MedicalsOverview from './MedicalsOverview';
import MedicalsOverviewPrecedent from './MedicalsOverviewPrecedent';
import MedicalsTreatments from './MedicalsTreatments';
import FirmRejectDialog from './dialogs/FirmRejectDialog';
import FirmApproveDialog from './dialogs/FirmApproveDialog';
import SendPreviewDialog from './dialogs/SendPreviewDialog';
import StatusDialog from './dialogs/StatusDialog';
import { getUserApi, getDocumentApi, rejectDemandApi } from './api';
import { Transition } from './Transition'
import { Error, RemoveRedEyeRounded, SendRounded } from '@mui/icons-material';

export const MedicalsLawFirmViewer = (props) => {

    const { documentId } = useParams();
    const [documentData, setDocumentData] = useState();
    const [documentMetaData, setDocumentMetaData] = useState();
    const [documentStatus, setDocumentStatus] = useState();
    const [providerSummary, setProviderSummary] = useState([]);
    const [userData, setUserData] = useState();
    const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [infoDialogTitle, setInfoDialogTitle] = useState("");
    const [infoDialogText, setInfoDialogText] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const [popOverOpen, setPopOverOpen] = useState(false);
    const popoverAnchor = useRef(null);
    const [deliveryEmail, setDeliveryEmail] = useState();
    const [deliveryFax, setDeliveryFax] = useState();
    const [documentS3Url, setDocumentS3Url] = useState();
    const [noPermissions, setNoPermissions] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const handlePopoverOpen = (event) => {
        setPopOverOpen(true);
        console.log("Test");
    };

    const handlePopoverClose = () => {
        setPopOverOpen(false);
    };

    const handleRejectDemand = (row, event) => {
        setRejectDialogOpen(true);
    };

    const handleRejectDialogClose = () => {
        setRejectDialogOpen(false);
    };

    const handleApproveDemand = (row, event) => {
        setApproveDialogOpen(true);
    };

    const handleApproveDialogClose = () => {
        setApproveDialogOpen(false);
    };

    const handleToastClose = () => {
        setToastMessage("");
        setToastOpen(false);
    };

    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
    };

    const handlePreviewEmail = (event) => {
        setPreviewDialogOpen(true);
    };

    const handlePreviewDialogClose = () => {
        setPreviewDialogOpen(false);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const findStatusEventDate = (status, statusHistory) => {
        const matchedStatus = statusHistory.find((element) => element.documentStatus == status);
        return matchedStatus;
    };

    const findDeliveryStatusEventDate = (status, statusHistory) => {
        const matchedStatus = statusHistory.find((element) => element.deliveryStatus == status);
        return matchedStatus;
    };

    const getStatusTimestamp = (statusEventId) => {
        const statusEvent = findStatusEventDate(statusEventId, documentMetaData.documentStatusHistory);
        if (statusEvent) {
            return formatDateTime(new Date(statusEvent.timestamp * 1000));
        }

        return "";
    };

    const getDeliveryChannel = () => {
        let statusEvent = findDeliveryStatusEventDate('FaxSent', documentMetaData.deliveryStatusHistory);
        if (statusEvent) {
            return "Fax";
        }

        statusEvent = findDeliveryStatusEventDate('EmailSent', documentMetaData.deliveryStatusHistory);
        if (statusEvent) {
            return "Email";
        }

        statusEvent = findDeliveryStatusEventDate('ManuallySent', documentMetaData.deliveryStatusHistory);
        if (statusEvent) {
            return "Manually Sent";
        }
        return "";
    };

    const handleBackNavClick = () => {
        const state = location.state;
        if (state?.from) {
            navigate(state?.from);
        }
        else {
            navigate('/firm-inventory');
        }
    }


    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    const getUser = async () => {
        setUserData(await getUserApi(user));
    };

    const getDocument = async () => {

        const response = await getDocumentApi(documentId, user).then((serverResponse) => {
            if (serverResponse.status == 200) {
                return serverResponse.json();
            }
            else if (serverResponse.status == 403) {
                return false;
            }
        });

        if (response) {
            setNoPermissions(false);
            if (response.document) {
                setDocumentS3Url(response.demandS3Url);
                setDocumentData(response.document);
                setDocumentMetaData(response.metadata);
                setDocumentStatus(response.metadata.documentStatus);
                setDeliveryEmail(response.deliveryEmail)
                setDeliveryFax(response.deliveryFax)
            }
        }
        else {
            setNoPermissions(true);
        }
    };

    const rejectDemand = (reason, comment) => {
        setRejectDialogOpen(false);

        setStatusDialogTitle("Sending Notification");
        showStatusDialog("Sending notification to Precedent...");

        rejectDemandApi(documentId, { "rejectReason": reason, "comment": comment }, user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("The notification has been delivered to Precedent.");
                    getDocument();
                }
                else {
                    showStatusDialog("There was an error delivering the rejection. Please contact support.");
                }
            });
    };

    /**
     * Called when the send to carrier has completed it's workflow.
     *  
     */
    const sendFirmApprovalRequestComplete = (success) => {
        if (success) {
            getDocument();
        }
    };

    const sendPreviewComplete = (success) => {
    };

    useEffect(() => {

        // Generate the summary of provider
        if (documentData) {
            setProviderSummary(buildProviderSummary(documentData));
        }

    }, [documentData]);

    useEffect(() => {
        getUser();
        getDocument();
    }, []);

    return (
        <Container maxWidth='100' sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
            padding: '10px'
        }}>
            <ThemeProvider theme={theme}>

                {noPermissions ? (
                    <div style={{ position: 'fixed', zIndex: 10, top: 65, left: 5, right: 15, backgroundColor: "#FFFFFF" }}>You are not currently assigned to this demand</div>
                ) : null}
                {documentData && userData ? (
                    <>
                        <div style={{ position: 'fixed', zIndex: 10, top: 60, left: 5, right: 15, backgroundColor: "#FFFFFF" }}>
                            <div className='breadcrumbs-container'>
                                <Stack direction={"row"}>
                                    <Stack spacing={1} direction={"row"} width={"35%"} display="flex" alignItems="center">
                                        <span onClick={handleBackNavClick} >
                                            <Typography variant="body1" color="primary" sx={{ cursor: "pointer", textDecoration: "underline" }}>
                                                Inventory
                                            </Typography>
                                        </span>
                                        <Typography variant="body1" color="primary">
                                            /
                                        </Typography>
                                        <Typography variant="body1" color="primary">
                                            {documentData.sendingFirm.firmCaseNumber}
                                        </Typography>
                                        <Chip className={mapStatusToChipClass(documentStatus)} label={mapStatusToLabel(documentStatus)} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} id="statusChip" sx={{ ml: 2 }} ref={popoverAnchor} aria-owns={popOverOpen ? 'statuspopover' : undefined} aria-haspopup="true">

                                        </Chip>
                                    </Stack>
                                    <Stack direction={"row"} spacing={2} width={"65%"} justifyContent={"flex-end"}>
                                        <Button onClick={(e) => { handlePreviewEmail() }} variant="outlined" color="secondary" startIcon={<RemoveRedEyeRounded />}>EMAIL PREVIEW TO ME</Button>
                                        {userHasPermission("DemandApproval", userData) ? (
                                            <>
                                                <span><Button disabled={documentStatus != 'WaitingFirmApproval'} onClick={(e) => { handleRejectDemand() }} variant="outlined" color="secondary" startIcon={<Error />}>REQUEST ADDITIONAL REVIEW</Button></span>

                                                <Tooltip placement="top" disableHoverListener={!(!documentData.recipientCarrier.contactInfo.emailAddress && !documentData.recipientCarrier.contactInfo.faxNumber)} title="This action is only available when a Carrier email address or fax number is available." arrow>
                                                    <span><Button disabled={documentStatus != 'WaitingFirmApproval'} onClick={(e) => { handleApproveDemand() }} variant="contained" color="secondary" startIcon={<SendRounded />}>APPROVE AND SEND TO CARRIER</Button></span>
                                                </Tooltip>
                                            </>
                                        ) : null}
                                    </Stack>
                                </Stack>
                            </div>
                        </div>


                        <Stack direction="column" spacing={2} sx={{ marginTop: '130px' }}>
                            <Card elevation={3} >
                                <MedicalsOverview documentData={documentData} adminView={false} />

                                <div className='form-hr' />

                                <MedicalsOverviewPrecedent documentData={documentData} showCarrierPanel={false} />
                            </Card>

                            <MedicalsProviderSummary providerSummary={providerSummary} />
                        </Stack>


                        <MedicalsTreatments viewType="lawfirm" documentData={documentData} documentS3Url={documentS3Url} userData={userData} adminView={false} />

                        <Popover
                            id={"statuspopover"}
                            open={popOverOpen}
                            anchorEl={popoverAnchor.current}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            sx={{
                                pointerEvents: 'none',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Paper elevation={3} sx={{ p: 2 }}>
                                <Stack direction={"column"} spacing={3}>
                                    <Stack direction={"row"} className='adjuster-form-data'>
                                        <div className='adjuster-form-label'>Demand Status:</div>
                                        <div className='adjuster-form-data'>{mapStatusToLabel(documentStatus)}</div>
                                    </Stack>

                                    <Stack direction={"row"} className='adjuster-form-data'>
                                        <div className='adjuster-form-label'>Delivered Via:</div>
                                        <div className='adjuster-form-data'>{
                                            getDeliveryChannel('DocumentSubmitted')
                                        }</div>
                                    </Stack>

                                    <Stack direction={"row"} className='adjuster-form-data'>
                                        <div className='adjuster-form-label'>Deleivered On:</div>
                                        <div className='adjuster-form-data'>{getStatusTimestamp('DocumentSubmitted')}</div>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Popover>
                    </>
                ) : (
                    <p></p>
                )}


                <Dialog
                    open={infoDialogOpen}
                    TransitionComponent={Transition}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{infoDialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {infoDialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { handleInfoDialogClose() }}>Ok</Button>
                    </DialogActions>
                </Dialog>

                <FirmRejectDialog handler={rejectDemand} handleClose={handleRejectDialogClose} dialogOpen={rejectDialogOpen}></FirmRejectDialog>

                <SendPreviewDialog user={user} documentId={documentId} handler={sendPreviewComplete} handleClose={handlePreviewDialogClose} dialogOpen={previewDialogOpen}></SendPreviewDialog>

                <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

                {documentData ?
                    <FirmApproveDialog user={user} documentId={documentId} handler={sendFirmApprovalRequestComplete} documentData={documentData} deliveryEmail={deliveryEmail} deliveryFax={deliveryFax} handleClose={handleApproveDialogClose} dialogOpen={approveDialogOpen}></FirmApproveDialog>
                    : null}

                <Snackbar
                    open={toastOpen}
                    autoHideDuration={2000}
                    onClose={handleToastClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>{toastMessage}</Alert>
                </Snackbar>

            </ThemeProvider>
        </Container>

    );
};
