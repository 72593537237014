
import React, { } from "react";
import { formatCurrency, formatDate } from './common';
import { Card, Paper, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Title from './Title';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const MedicalsProviderSummary = (props) => {
    const [treatmentSummaryExpanded, setExpanded] = React.useState(true);

    const providerSummary = props.providerSummary;

    const handleTreatmentSummaryExpandClick = () => {
        setExpanded(!treatmentSummaryExpanded);
    };

    return (
        <>
            <Card>



                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                    <Title>Treatment provider summary</Title>
                    <ExpandMore
                        expand={treatmentSummaryExpanded}
                        onClick={handleTreatmentSummaryExpandClick}
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Stack>

                <Collapse in={treatmentSummaryExpanded} timeout="auto" unmountOnExit>
                    <TableContainer>
                        <Table className={"attachments-table"} style={{ maxWidth: '1000px' }}>
                            <TableHead>
                                <TableRow key="header">
                                    <TableCell width={"35%"} sx={{ fontWeight: '500' }}>Facility</TableCell>
                                    <TableCell align='center' sx={{ fontWeight: '500' }}>Total Charges</TableCell>
                                    <TableCell align='center' sx={{ fontWeight: '500' }}>First Treatment</TableCell>
                                    <TableCell align='center' sx={{ fontWeight: '500' }}>Last Treatment</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {providerSummary.map((provider, index) => {
                                    if (index == providerSummary.length - 1) {
                                        return (
                                            <TableRow key={provider.treatmentFacility}>
                                                <TableCell className={"secondary"} sx={{ fontWeight: '500' }} width={"25%"}>{provider.treatmentFacility}</TableCell>
                                                <TableCell className={"secondary"} align='center' sx={{ fontWeight: '500' }}>{formatCurrency(provider.billedAmount)}</TableCell>
                                                <TableCell className={"secondary"} align='center' sx={{ fontWeight: '500' }}>{formatDate(provider.startDate)}</TableCell>
                                                <TableCell className={"secondary"} align='center' sx={{ fontWeight: '500' }}>{formatDate(provider.endDate)}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                    return (

                                        <TableRow key={provider.treatmentFacility}>
                                            <TableCell>{provider.treatmentFacility}</TableCell>
                                            <TableCell align='center'>{formatCurrency(provider.billedAmount)}</TableCell>
                                            <TableCell align='center'>{formatDate(provider.startDate)}</TableCell>
                                            <TableCell align='center'>{formatDate(provider.endDate)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Collapse>
            </Card>
        </>
    )

}

export default MedicalsProviderSummary;