import awsExports from './aws-exports';

const rootApiUrl = awsExports.ROOT_API_URL;

export const getUserApi = async (user) => {
        
    if (!user)
        return null;
    
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
       rootApiUrl+"/user/"+user.username, settings
    ).then((response) => response.json())
    .finally(() => {
    });

    return response;
};

export const getDocumentApi = async (documentId, user) => {
    
    if (!user)
        return null;

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl+"/document/"+documentId, settings
    ).then((response) => response);

    return response;
};

export const sendPreviewApi = async (documentId, user) => {
        
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl+"/document/"+documentId+"/sendPreview", settings
    ).then((response) => { return response;})
    .finally(() => {
    });

    return response;
};

export const sendMedicalExtractionApi = async (documentId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl+"/document/"+documentId+"/sendMedicalExtraction", settings
    ).then((response) => { return response;})
    .finally(() => {
    });

    return response;
};


export const sendToCarrierApi = async (documentId, user) => {
        
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
       rootApiUrl+"/document/"+documentId+"/sendCarrier", settings
    ).then((response) => response)
    .finally(() => {
    });

    return response;
};

export const faxToCarrierApi = async (documentId, user) => {
        
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
       rootApiUrl+"/document/"+documentId+"/sendFax", settings
    ).then((response) => { return response;})
    .finally(() => {
    });

    return response;
};

export const sendManuallyApi = async (documentId, formData, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(formData)
    };

    const response = await fetch(
        rootApiUrl+"/document/"+documentId+"/sendManually", settings
    ).then((response) => { return response; })
    .finally(() => {
    });

    return response;
};

export const archiveApi = async (documentId, formData, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(formData)
    };

    const response = await fetch(
        rootApiUrl+"/document/"+documentId+"/archive", settings
    ).then((response) => { return response; })
    .finally(() => {
    });

    return response;
};

export const submitFirmApprovalRequestApi = async (documentId, user) => {
        
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl+"/document/"+documentId+"/submitforfirmapproval", settings
    ).then((response) => { return response;})
    .finally(() => {
    });

    return response;
};

export const rejectDemandApi = async (documentId, formData, user) => {
        
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(formData)
    };

    const response = await fetch(
        rootApiUrl+"/document/"+documentId+"/reject", settings
    ).then((response) => { return response; })
    .finally(() => {
    });

    return response;
};

export const approveFirmDemandApi = async (documentId, user) => {
        
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
       rootApiUrl+"/document/"+documentId+"/firmapproval", settings
    ).then((response) => {return response;})
    .finally(() => {
    });

    return response;
};

export const addContactLogApi = async (contactLog, user) => {
        
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken,
        },
        body: JSON.stringify(contactLog)
    };

    const response = await fetch(
       rootApiUrl+"/document/"+contactLog['documentId']+"/contactlog", settings
    ).then((response) => {return response;})
    .finally(() => {
    });

    return response;
};

export const getContactLogApi = async (documentId, user) => {
        
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
       rootApiUrl+"/document/"+documentId+"/contactlog", settings
    ).then((response) => {return response;})
    .finally(() => {
    });

    return response;
};

export const getConfigValue = async (configKey, customerId, user) => {

    const payload = {configKey: configKey};
    if (customerId) {
        payload['customerId'] = customerId;
    }

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(payload)
    };

    const response = await fetch(
        rootApiUrl + "/getConfigValue", settings
    ).then((response) => response.json())
    .finally(() => {
    });

    return response["configValue"]; 
};

export const pushMedicalsCsvToS3 = async (uploadFields, file) => {

    const headers = new Headers({});
    const formData = new FormData();
    formData.append("key", uploadFields["fields"]["key"]);
    formData.append("AWSAccessKeyId", uploadFields["fields"]["AWSAccessKeyId"]);
    formData.append("policy", uploadFields["fields"]["policy"]);
    formData.append("signature", uploadFields["fields"]["signature"]);
    formData.append("x-amz-security-token", uploadFields["fields"]["x-amz-security-token"]);
    formData.append("file", file);

    const response = await fetch(uploadFields["url"], {
        method: 'POST',
        headers: headers,
        body: formData
    });

    return response.status == 204;
};

export const getUploadMedicalsUrl = async (documentId, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ documentId: documentId })
    };

    const response = await fetch(
        rootApiUrl + "/document/generateUploadMedicalsUrl", settings
    ).then((response) => response.json())
        .finally(() => {
        });

    return response["uploadFields"];
};

export const uploadMedicalsApi = async (documentId, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ documentId: documentId })
    };

    const response = await fetch(
        rootApiUrl + "/document/uploadMedicals", settings
    ).then((response) => {return response;})
    .finally(() => {
    });

    return response;
};

