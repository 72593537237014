
import React, { useEffect,useState, createRef } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Title from './Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatCurrency,formatDate} from './common';
import {getAttachmentS3Url, downloadAttachment} from './attachments_api';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import EnclosuresTable from './EnclosuresTable';
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from './slices/accordionSlice'

const AdjusterViewerPainSuffering = (props) => {

    const primaryActionsColor = "rgba(0, 0, 0, 0.56)";
    const [detailsPaneOpen, setDetailsPaneOpen] = useState(false);
    const [currentDocumentUrl, setCurrentDocumentUrl] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [accordionStates, setAccordionStates] = useState({});

    const frameRef = createRef();
    const leftFrameRef = createRef();
    const demandDoc = props.demandDoc;

    const dispatch = useDispatch();
    const panels = useSelector(state => state.accordion.panels);

    const handleAccordionChange = (panel, newExpanded) => {
        dispatch(toggle({id:panel,expanded:newExpanded}));
    };

    const isExpanded = (panelId) => {
        const matchingPanel = panels.find(panel => panel.id === panelId)
        if (matchingPanel) {
            return matchingPanel.expanded;
        }
        else {
            return false;
        }
    } 

    const findAttachment = (attachmentId) => {
        for (let i = 0; i < demandDoc.attachments.length; i++) {
            const attachment = demandDoc.attachments[i];
            if (attachment.attachmentId == attachmentId)
                return attachment;
        }

        return null;
    };

    const resizeIframe = () => {
        if (frameRef.current != null) {
            let h = window.innerHeight-150;    
            h = 700;
            frameRef.current.style.height = h+'px';
            leftFrameRef.current.style.height = frameRef.current.style.height
        }
    };

    const hideDetailsPanel = () => {
        setDetailsPaneOpen(false);
    };

    const viewDetailsPanelCallback = async (attachmentId, title) => {
    
        // Load the documents
        const s3_url = await getAttachmentS3Url(attachmentId);
        setCurrentDocumentUrl(s3_url);

        // Open the panel
        setDetailsPaneOpen(true);
    };

    const downloadAttachmentCallback = async (attachmentId) => {
        downloadAttachment(findAttachment(attachmentId));
    };

    const getAccordionState = (id) => {
        return accordionStates[id] == undefined ? false : accordionStates[id]; 
     };


    useEffect(() => {
    
        const fmIds = []
        for (let i = 0; i < props.demandDoc.futureMedicals.length; i++) {
            fmIds.push(props.demandDoc.futureMedicals[i].futureMedicalId);
        }

        const tempAttachments = [];
        for (let i = 0; i < props.demandDoc.attachments.length; i++) {
            const attachment = props.demandDoc.attachments[i];

            if (attachment.associatedDemandEntity.demandSection == 'FutureDamages' &&
                fmIds.includes(attachment.associatedDemandEntity.associatedDemandEntityId)) {
                tempAttachments.push(attachment);
            }
        }
        setAttachments([...tempAttachments]);
    }, []);
    
    useEffect(() => {
        resizeIframe();
    }, [frameRef,leftFrameRef]);

    return (
        <Grid container sx={{mt:1}}>
            <Grid xs={detailsPaneOpen ? 6 : 12} >
                <Box ref={leftFrameRef} style={{overflowY:'auto'}}>
                    <Stack spacing={3} width={detailsPaneOpen ? "100%" : "50%"}>
                        {demandDoc.generalDamages.map((damage,index) => {
                            return (
                                <Accordion elevation={3} sx={{mb:1}} key={index} expanded={isExpanded('gd-'+index)} onChange={(e,expanded) => {handleAccordionChange('gd-'+index, expanded)}}>
                                    <AccordionSummary
                                        sx={{margin:0}}
                                        expandIcon={<ExpandMoreIcon color='secondary'/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Title>{damage.futureIndicator ? "Future Estimated": ""} Pain and Suffering: {formatCurrency(damage.estimatedAmount)}</Title>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>

                                            <Grid container xs={12} className="doc-content-section adjuster-form-data">
                                                <Grid xs={3} className="adjuster-form-label">
                                                    Start Date
                                                </Grid>
                                                <Grid xs={9}>
                                                    {formatDate(damage.startDate)}
                                                </Grid>

                                                <Grid xs={3} className="adjuster-form-label">
                                                    End Date
                                                </Grid>
                                                <Grid xs={9}>
                                                    {formatDate(damage.endDate)}
                                                </Grid>

                                                <Grid xs={3} className="adjuster-form-label">
                                                    Description
                                                </Grid>
                                                <Grid xs={9}>
                                                    {damage.description}
                                                </Grid>

                                                <Grid xs={3} className="adjuster-form-label">
                                                    Amount
                                                </Grid>
                                                <Grid xs={9}>
                                                    {formatCurrency(damage.estimatedAmount)}
                                                </Grid>
                                            </Grid>

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                            })}

                            <Accordion  elevation={3} sx={{mb:1}} expanded={isExpanded('gd-enc')} onChange={(e,expanded) => {handleAccordionChange('gd-enc', expanded)}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Title>Enclosures ({attachments.length})</Title>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <EnclosuresTable showTitle={false} demandDoc={demandDoc} attachments={attachments} viewTitle="" downloadAttachment={downloadAttachmentCallback} viewAttachment={viewDetailsPanelCallback} />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <div style={{marginBottom:'20px'}}></div>

                    </Stack>
                </Box>
            </Grid>
            <Grid xs={detailsPaneOpen ? 6 : 0} >
                {detailsPaneOpen ? (
                <div style={{ height: "100%", marginLeft:"20px" }}>
                    <Stack direction={"column"}>
                        <Stack direction={"row"}>
                            <Grid container xs={12}>
                                <Grid xs={6} >
                                    <Title>Enclosures</Title>
                                </Grid>
                                <Grid xs={6} textAlign={"right"}>
                                    <IconButton title='Close Details' style={{width:'25px'}} onClick={() => { hideDetailsPanel()}}>
                                    <Close color='secondary'/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Stack>
                    
                        <div ref={frameRef}>
                            <iframe onLoad={resizeIframe()} id="documentFrame" src={currentDocumentUrl} title="Document" width={"100%"} height={"100%"}>
                            </iframe>
                        </div>
                    </Stack>
                </div>
                ) : null }
            </Grid>
        </Grid>
    )
}

export default AdjusterViewerPainSuffering;