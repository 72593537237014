import {dateComparator,currencyComparator} from './comparators';

export const buildProviderSummary = (documentData) => {

    const summary = {};
    documentData.medicals.medicalTreatments.forEach((medical) => {
        
        // Normalize the facility name
        medical.treatmentFacility = medical.treatmentFacility.toUpperCase();

        let facility = summary[medical.treatmentFacility];
        if (!facility) {
            facility = {};
            facility.visits = 0;
            facility.visitDates = [];
            facility.treatmentFacility = medical.treatmentFacility;
            facility.startDate = medical.treatmentDate;
            facility.endDate = medical.treatmentDate;
            facility.billedAmount = 0.0;
            summary[medical.treatmentFacility] = facility;
        }

        if (dateComparator(medical.treatmentDate, facility.endDate) > 0)
            facility.endDate = medical.treatmentDate;

        if (dateComparator(medical.treatmentDate, facility.startDate) < 0)
            facility.startDate = medical.treatmentDate;

        if (!facility.visitDates.includes(medical.treatmentDate)) {
            facility.visits += 1;
            facility.visitDates.push(medical.treatmentDate);
        }
        facility.billedAmount += parseFloat(medical.billedAmount);
    });

    const overall = {}
    overall.treatmentFacility = "Overall"
    overall.visits = 0
    overall.billedAmount = 0.0
    const providerList = [];
    for (let key in summary) {
       providerList.push(summary[key]);

       let facility = summary[key]

        if (!overall.startDate) {
            overall.startDate = facility.startDate;
        }

        if (!overall.endDate) {
            overall.endDate = facility.endDate;
        }

        if (dateComparator(facility.endDate, overall.endDate) > 0)
            overall.endDate = facility.endDate;

        if (dateComparator(facility.startDate, overall.startDate) < 0)
            overall.startDate = facility.startDate;

        overall.visits += facility.visits
        overall.billedAmount += facility.billedAmount
    }
    providerList.push(overall)

    return providerList;

};