
import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, MenuItem, TextField, Stack } from '@mui/material';
import { Transition } from '../Transition'
import Title from '../Title';
import { approveFirmDemandApi } from '../api';
import StatusDialog from "./StatusDialog";


const FirmApproveDialog = (props) => {

    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");

    const documentData = props.documentData;
    const deliveryEmail = props.deliveryEmail;
    const deliveryFax = props.deliveryFax;

    const buildSendTargetText = () => {
        if (documentData.recipientCarrier.contactInfo.emailAddress && documentData.recipientCarrier.contactInfo.emailAddress.length > 0) {
            return deliveryEmail;
        }
        else if (documentData.recipientCarrier.contactInfo.faxNumber && documentData.recipientCarrier.contactInfo.faxNumber.length > 0) {
            return deliveryFax;
        }
        else {
            return documentData.recipientCarrier.carrierCommonName;
        }
    };

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const approveDemand = (userTriggered) => {

        if (userTriggered) {
            props.handleClose();
            setStatusCloseDisabled(true);
            setStatusDialogTitle("Sending Demand");
            showStatusDialog("Sending the demand to the carrier...");
        }

        approveFirmDemandApi(documentData.documentId, props.user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("The demand has been sent to the carrier.");
                    setStatusCloseDisabled(false);
                    props.handler(true);
                }
                else if (response.status == 201) {
                    setStatusDialogText("The demand is being regenerated. This should only take a few moments.");
                    setTimeout(function () { approveDemand(false) }, 3000);
                }
                else {
                    showStatusDialog("There was an error sending the email.");
                    setStatusCloseDisabled(false);
                    props.handler(false);
                }
            });
    };

    return (
        <>
            <Dialog
                open={props.dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle><Title>Approve & Send to Carrier</Title></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Stack spacing={2}>
                            <div>Approve this demand to be submitted by Precedent to {buildSendTargetText()}.<br /><br />Click 'Approve & send' to continue.</div>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => { props.handleClose() }}>Cancel</Button><Button variant="contained" color="secondary" onClick={() => { approveDemand(true) }}>Approve & send</Button>
                </DialogActions>
            </Dialog>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    )

}

export default FirmApproveDialog;