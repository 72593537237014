
import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from '../Transition'
import { Stack, TextField, MenuItem, Button } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';



const SendManuallyDialog = (props) => {

    const [dateOfSendError, setDateOfSendError] = useState(false);
    const [sendReasonError, setSendReasonError] = useState(false);
    const [sendMethodError, setSendMethodError] = useState(false);

    const [sendMethod, setSendMethod] = useState();
    const [sendDate, setSendDate] = useState();
    const [sendReason, setSendReason] = useState();

    const sendManually = () => {

        const dateError = !sendDate;
        const reasonError = !sendReason;
        const methodError = !sendMethod;

        setDateOfSendError(dateError);
        setSendMethodError(reasonError);
        setSendReasonError(methodError);

        if (dateError || reasonError || methodError) {
            return;
        }
        else {
            props.handler(sendDate, sendReason, sendMethod);
        }
    };

    return (
        <Dialog
            open={props.dialogOpen}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
        >
            <DialogTitle>Mark demand sent manually</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Stack sx={{ mt: 2 }} spacing={2}>
                        <DateTimePicker
                            label="Date of send"
                            value={sendDate}
                            onChange={(newValue) => { setSendDate(newValue) }}
                            required={true}
                        />

                        <TextField error={sendMethodError} required={true} id="send_method" label="Send method" value={sendMethod}
                            fullWidth select onChange={(e) => { setSendMethod(e.target.value) }}
                        >
                            <MenuItem key={"email"} value={"email"}>
                                Email
                            </MenuItem>
                            <MenuItem key={"fax"} value={"fax"}>
                                Fax
                            </MenuItem>
                            <MenuItem key={"usmail"} value={"usmail"}>
                                US mail
                            </MenuItem>
                            <MenuItem key={"certifiedmail"} value={"certifiedmail"}>
                                Certified mail
                            </MenuItem>
                            <MenuItem key={"carrierportal"} value={"carrierportal"}>
                                Carrier portal
                            </MenuItem>
                        </TextField>
                        <TextField error={sendReasonError} required={true} id="send_reason" label="Reason for manual send" value={sendReason}
                            fullWidth onChange={(e) => { setSendReason(e.target.value) }}
                        ></TextField>
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.handleClose() }}>Cancel</Button><Button variant="contained" color="secondary" onClick={() => { sendManually() }}>Save</Button>
            </DialogActions>
        </Dialog>
    )

}

export default SendManuallyDialog;