import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Stack, TextField, Chip, ThemeProvider, Button, MenuItem, Alert, Snackbar, InputAdornment, ListItemIcon, ListItemText, Divider, Menu, Collapse, Typography, IconButton, Card, Box } from '@mui/material';
import awsExports from './aws-exports';
import { theme } from './Theme'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { formatDecimal, formatPhoneNumber, mapStatusToChipClass, mapStatusToLabel, userHasPermission, isRequestApprovalEnabled, cleanNumber, isUploadMedicalsEnabled } from './common';
import { stateLabelValues } from './common-data';
import { buildProviderSummary } from './common-medicals';
import MedicalsProviderSummary from './MedicalsProviderSummary';
import MedicalsOverview from './MedicalsOverview';
import MedicalsOverviewPrecedent from './MedicalsOverviewPrecedent';
import MedicalsTreatments from './MedicalsTreatments';
import AttorneySendDialog from './dialogs/AttorneySendDialog'
import CarrierSendDialog from './dialogs/CarrierSendDialog'
import CarrierSendFaxDialog from './dialogs/CarrierSendFaxDialog'
import SendManuallyDialog from './dialogs/SendManuallyDialog'
import ArchiveDialog from './dialogs/ArchiveDialog'
import SendPreviewDialog from './dialogs/SendPreviewDialog'
import StatusDialog from './dialogs/StatusDialog';
import { getUserApi, getDocumentApi, archiveApi, sendManuallyApi, pushMedicalsCsvToS3, getUploadMedicalsUrl, uploadMedicalsApi } from './api';
import { Transition } from './Transition'
import { ApprovalRounded, EditOutlined, ExpandMore, KeyboardArrowDownRounded } from '@mui/icons-material';
import { RemoveRedEyeOutlined, FaxOutlined, SendOutlined, ArchiveOutlined, CheckBoxRounded, MedicalInformation } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import Title from './Title';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});



export const MedicalsAdminViewer = (props) => {

    const rootApiUrl = awsExports.ROOT_API_URL;
    const { documentId } = useParams();
    const [documentData, setDocumentData] = useState();
    const [documentStatus, setDocumentStatus] = useState();
    const [providerSummary, setProviderSummary] = useState([]);
    const [ipAddress, setIpAddress] = useState();
    const [userData, setUserData] = useState();
    const [editOverviewOpen, setEditOverviewOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState({});
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [infoDialogTitle, setInfoDialogTitle] = useState("");
    const [infoDialogText, setInfoDialogText] = useState("");
    const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [anchorEl, setAnchorEl] = useState();
    const [faxDialogOpen, setFaxDialogOpen] = useState(false);
    const [attorneyDialogOpen, setAttorneyDialogOpen] = useState(false);
    const [deliveryAttorneyEmail, setDeliveryAttorneyEmail] = useState();
    const [deliveryCarrierEmail, setDeliveryCarrierEmail] = useState();
    const [deliveryCarrierFax, setDeliveryCarrierFax] = useState();
    const [documentS3Url, setDocumentS3Url] = useState();
    const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const [manualDialogOpen, setManualDialogOpen] = useState(false);
    const [carrierDialogOpen, setCarrierDialogOpen] = useState(false);
    const [demandOverviewExpanded, setDemandOverviewExpanded] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    // API Integrations

    const getUser = async () => {
        setUserData(await getUserApi(user));
    };

    const getDocument = async () => {

        const response = await getDocumentApi(documentId, user).then((serverResponse) => {
            return serverResponse.json();
        });

        // update the state and map form elements
        if (response.document) {
            setDocumentS3Url(response.demandS3Url);
            setDocumentData(response.document);
            setDocumentStatus(response.metadata.documentStatus);
            setDeliveryAttorneyEmail(response.deliveryAttorneyEmail);
            setDeliveryCarrierEmail(response.deliveryEmail);
            setDeliveryCarrierFax(response.deliveryFax);
        }
    };

    const saveDocument = async (showSaveDialog) => {

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(documentData)
        };

        const response = await fetch(
            rootApiUrl + "/document", settings
        ).then((response) => {
            if (response.ok) {
                if (showSaveDialog) {
                    showInfoDialog('Save Successful!', 'The demand has been saved.');
                }
                else {
                    setToastMessage("Changes Saved!");
                    setToastOpen(true);
                }
                return response.json();
            }
            else {
                throw new Error('Server returned ' + response.status + ' status code. ');
            }
        }).catch((error) => {
            // Your error is here!
            console.log(error);
            alert(error);
        });
    };

    const sendManually = async (sendDate, sendReason, sendMethod) => {

        setManualDialogOpen(false);

        const manualFormData = {
            "sendDate": sendDate.format("YYYY-MM-DDTHH:mm:ssZ"),
            "sendReason": sendReason,
            "sendMethod": sendMethod
        };

        sendManuallyApi(documentId, manualFormData, user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("Success! The demand has been updated.");
                    getDocument();
                }
                else {
                    showStatusDialog("There was an error updating the demand.");
                }
            });
    };


    const archiveDemand = async (archiveReason) => {
        setArchiveDialogOpen(false);

        const manualFormData = {
            "archiveReason": archiveReason
        };

        archiveApi(documentId, manualFormData, user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("Success! The demand has been archived.");
                    getDocument();
                }
                else {
                    showStatusDialog("There was an error archiving the demand.");
                }
            });
    };

    const handlePreviewEmail = (userTriggered, event) => {
        handleMenuClose();
        setPreviewDialogOpen(true);
    };

    /**
     * Called when the send to carrier has completed it's workflow.
     *  
     */
    const sendEmailToCarrierComplete = (success) => {
        if (success) {
            getDocument();
        }
    };

    /**
     * Called when the send to carrier has completed it's workflow.
     *  
     */
    const sendFaxToCarrierComplete = (success) => {
        if (success) {
            getDocument();
        }
    };

    /**
     * Called when the send to carrier has completed it's workflow.
     *  
     */
    const sendForAttorneyApprovalComplete = (success) => {
        if (success) {
            getDocument();
        }
    };

    /**
    * Called when the preview email send.
    *  
    */
    const sendPreviewComplete = (success) => {
    };

    // Data Mappings

    const mapFormElementsToServerData = () => {

        const tempDocumentData = { ...documentData };

        tempDocumentData.sendingFirm.attorney.firstName = formData['attorney_first_name'];
        tempDocumentData.sendingFirm.attorney.lastName = formData['attorney_last_name'];
        tempDocumentData.sendingFirm.attorney.contactInfo.emailAddress = formData['attorney_email'];
        tempDocumentData.sendingFirm.attorney.contactInfo.phoneNumber = formData['attorney_phone'];
        tempDocumentData.sendingFirm.primaryContact.firstName = formData['attorney_contact_first_name'];
        tempDocumentData.sendingFirm.primaryContact.lastName = formData['attorney_contact_last_name'];
        tempDocumentData.sendingFirm.primaryContact.contactInfo.emailAddress = formData['attorney_contact_email'];
        tempDocumentData.sendingFirm.primaryContact.contactInfo.phoneNumber = formData['attorney_contact_phone'];
        tempDocumentData.sendingFirm.firmName = formData['attorney_firm_name'];
        tempDocumentData.sendingFirm.firmCaseNumber = formData['attorney_case_number'];

        // Carrier
        tempDocumentData.recipientCarrier.carrierCommonName = formData['carrier'];
        tempDocumentData.recipientCarrier.contactInfo.phoneNumber = formData['carrier_phone'];
        tempDocumentData.recipientCarrier.contactInfo.emailAddress = formData['carrier_email'];
        tempDocumentData.recipientCarrier.contactInfo.faxNumber = formData['carrier_fax'];


        tempDocumentData.recipientAdjuster.firstName = formData['adjuster_first_name'];
        tempDocumentData.recipientAdjuster.lastName = formData['adjuster_last_name'];
        tempDocumentData.recipientAdjuster.contactInfo.phoneNumber = formData['adjuster_phone'];


        // Client   
        tempDocumentData.claimInfo.claimant.firstName = formData['client_first_name'];
        tempDocumentData.claimInfo.claimant.lastName = formData['client_last_name'];

        // Claim
        tempDocumentData.claimInfo.claimNumber = formData['claim_number'];
        tempDocumentData.claimInfo.dateOfLoss = formData['claim_date_of_loss'];
        tempDocumentData.claimInfo.claimCoverage = formData['claim_coverage'];
        tempDocumentData.claimInfo.lossState = formData['claim_loss_state'];
        tempDocumentData.claimInfo.lossCounty = formData['claim_loss_county'];
        tempDocumentData.claimInfo.insuredFirstName = formData['named_insured_first_name'];
        tempDocumentData.claimInfo.insuredLastName = formData['named_insured_last_name'];
        tempDocumentData.demandDetails.demandOfferToSettleAmount = cleanNumber(formData['demand_amount']);
        tempDocumentData.demandDetails.demandWrittenDate = formData['demand_written_date'];
        tempDocumentData.demandDetails.demandResponseDueDate = formData['demand_response_due_date'];
        tempDocumentData.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm = cleanNumber(formData['policy_limit']);
        tempDocumentData.demandDetails.policyLimitDemandIndicator = formData['is_policy_limit'];

        setDocumentData(tempDocumentData);
    };


    const mapServerDataToFormElements = () => {
        if (documentData == null)
            return;

        const tempFormData = { ...formData };
        tempFormData['attorney_first_name'] = documentData.sendingFirm?.attorney?.firstName;
        tempFormData['attorney_last_name'] = documentData.sendingFirm?.attorney?.lastName;
        tempFormData['attorney_email'] = documentData.sendingFirm?.attorney?.contactInfo?.emailAddress;
        tempFormData['attorney_phone'] = documentData.sendingFirm?.attorney?.contactInfo?.phoneNumber;
        tempFormData['attorney_contact_first_name'] = documentData.sendingFirm?.primaryContact?.firstName;
        tempFormData['attorney_contact_last_name'] = documentData.sendingFirm?.primaryContact?.lastName;
        tempFormData['attorney_contact_email'] = documentData.sendingFirm?.primaryContact?.contactInfo?.emailAddress;
        tempFormData['attorney_contact_phone'] = documentData.sendingFirm?.primaryContact?.contactInfo?.phoneNumber;
        tempFormData['attorney_firm_name'] = documentData.sendingFirm?.firmName;
        tempFormData['attorney_case_number'] = documentData.sendingFirm?.firmCaseNumber;

        // Carrier
        if (documentData.recipientCarrier) {
            tempFormData['carrier'] = documentData.recipientCarrier.carrierCommonName;
            if (documentData.recipientCarrier.contactInfo) {
                tempFormData['carrier_phone'] = documentData.recipientCarrier?.contactInfo?.phoneNumber;
                tempFormData['carrier_fax'] = documentData.recipientCarrier?.contactInfo?.faxNumber;
                tempFormData['carrier_email'] = documentData.recipientCarrier?.contactInfo?.emailAddress;
            }
        }

        tempFormData['adjuster_first_name'] = documentData.recipientAdjuster?.firstName;
        tempFormData['adjuster_last_name'] = documentData.recipientAdjuster?.lastName;
        tempFormData['adjuster_phone'] = documentData.recipientAdjuster?.contactInfo?.phoneNumber;

        // Client   
        if (documentData.claimInfo?.claimant) {
            tempFormData['client_first_name'] = documentData.claimInfo?.claimant?.firstName;
            tempFormData['client_last_name'] = documentData.claimInfo?.claimant?.lastName;
        }

        // Claim
        tempFormData['claim_number'] = documentData.claimInfo?.claimNumber;
        tempFormData['claim_date_of_loss'] = cleanDate(documentData.claimInfo?.dateOfLoss);
        tempFormData['claim_coverage'] = documentData.claimInfo?.claimCoverage;
        tempFormData['claim_loss_state'] = documentData.claimInfo?.lossState;
        tempFormData['claim_loss_county'] = documentData.claimInfo?.lossCounty;
        tempFormData['named_insured_first_name'] = documentData.claimInfo?.insuredFirstName;
        tempFormData['named_insured_last_name'] = documentData.claimInfo?.insuredLastName;
        tempFormData['demand_amount'] = documentData.demandDetails?.demandOfferToSettleAmount;
        tempFormData['demand_written_date'] = cleanDate(documentData.demandDetails?.demandWrittenDate);
        tempFormData['demand_response_due_date'] = cleanDate(documentData.demandDetails?.demandResponseDueDate);
        tempFormData['policy_limit'] = documentData.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm;
        tempFormData['is_policy_limit'] = documentData.demandDetails?.policyLimitDemandIndicator || false;

        setFormData(tempFormData);
    };

    const cleanDate = (dateStr) => {
        const timeRegex = /T\d{2}:\d{2}:\d{2}/g;
        if (dateStr) {
            return dateStr.replace(timeRegex, "");
        }
        return dateStr;
    }

    // UI Handlers

    const handleDemandOverviewExpandClick = () => {
        setDemandOverviewExpanded(!demandOverviewExpanded);
    };

    const handleToastClose = () => {
        setToastMessage("");
        setToastOpen(false);
    };

    const handleMenuClose = (event) => {
        setAnchorEl(null);
    };

    const handlePreviewDialogClose = () => {
        setPreviewDialogOpen(false);
    };


    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };


    const handleEmailCarrier = (event) => {
        handleMenuClose(event);
        setCarrierDialogOpen(true);
    };

    const handleCarrierDialogClose = () => {
        setCarrierDialogOpen(false);
    };

    const handleFaxCarrier = (documentId, fax, event) => {
        handleMenuClose(documentId, event);
        setFaxDialogOpen(true);
    };

    const handleCarrierFaxDialogClose = () => {
        setFaxDialogOpen(false);
    };

    const handleArchiveDemand = (event) => {
        handleMenuClose(event);
        setArchiveDialogOpen(true);
    };

    const handleArchiveDialogClose = () => {
        setArchiveDialogOpen(false);
    };

    const handleSendManually = (event) => {
        handleMenuClose(event);
        setManualDialogOpen(true);
    };

    const handleManualDialogClose = () => {
        setManualDialogOpen(false);
    };


    const handleDocumentDataUpdated = (inputDocumentData) => {
        setDocumentData({ ...inputDocumentData });
        saveDocument(false);
    };

    const handleSaveOverviewClick = (event) => {
        //count the number of errors where the string length is > 0
        if (Object.keys(formError).filter((key) => formError[key].length > 0).length > 0) {
            showInfoDialog("Error", "Please correct the errors before saving.");
            return;
        }
        mapFormElementsToServerData();
        saveDocument(true);
        handleEditOverviewClose(true);
    };

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const handleEditOverviewClick = (event) => {
        setEditOverviewOpen(true);
    };

    const handleEditOverviewClose = (event, saveData) => {
        if (!saveData) {
            mapServerDataToFormElements();
        }

        setEditOverviewOpen(false);
    };

    const getFormData = (id) => {
        return formData[id];
    };

    const handleFormElementChange = (event, id) => {
        const elementId = id || event.target.id;
        if (event.target.type == 'checkbox') {
            formData[elementId] = event.target.checked
        }
        else {
            formData[elementId] = event.target.value;
        }

        setFormData({ ...formData });

        if (id.includes("phone") || id.includes("fax")) {
            const formattedPhoneNumber = formatPhoneNumber(event.target.value || "");
            //strip anything past the "x"
            const withoutExtension = formattedPhoneNumber.split("x")[0];
            //there must be at least 10 digits in a valid phone number
            const digitsOnly = withoutExtension.replace(/[^0-9]/g, '');
            if (formattedPhoneNumber.length != 0 && digitsOnly.length < 10) {
                setFormError({ ...formError, [elementId]: "Invalid phone number" });
            }
            //otherwise, clear the error
            else {
                setFormError({ ...formError, [elementId]: "" });
            }
        }

        if (id.includes("email")) {
            //validate that the email is in the correct format
            const email = event.target.value;
            const emailRegex = /\S+@\S+\.\S+/;
            //if it is empty, or it is not in the correct format, show an error
            if (email.length != 0 && !emailRegex.test(email)) {
                setFormError({ ...formError, [elementId]: "Invalid email address" });
            }
            //otherwise, clear the error
            else {
                setFormError({ ...formError, [elementId]: "" });
            }
        }
    };

    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
    };

    const showInfoDialog = (title, text) => {
        setInfoDialogText(text);
        setInfoDialogTitle(title);
        setInfoDialogOpen(true);
    };

    const handleApproveDemandClick = () => {
        setAttorneyDialogOpen(true);
    };

    const handleAttorneyDialogClose = () => {
        setAttorneyDialogOpen(false);
    };

    const handleBackNavClick = () => {
        const state = location.state;
        if (state?.from) {
            navigate(state?.from);
        }
        else {
            navigate('/inventory');
        }
    }

    const handleCsvUpload = async (e) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        
        const uploadFields = await getUploadMedicalsUrl(documentId, user);

        // Upload the csv to s3
        const success = await pushMedicalsCsvToS3(uploadFields, file);

        if (success) {
            const uploadMedicalsResponse = await uploadMedicalsApi(documentId, user);
            const responseJson = await uploadMedicalsResponse.json();
            if (uploadMedicalsResponse.status == 200) {
                showStatusDialog("Success uploading medicals!");
                if (responseJson.document) {
                    setDocumentData(responseJson.document);
                    setDocumentStatus(responseJson.metadata.documentStatus);
                }
            } else if (uploadMedicalsResponse.status == 400) {
                showStatusDialog(responseJson["error"]);
            } else {
                showStatusDialog("Unexpected error uploading medicals, check logs");
            }
        }
        else {
            showStatusDialog("There was an error submitting the csv");
        }
    };

    const menuopen = Boolean(anchorEl);
    const adminView = userHasPermission("DemandEdit", userData);

    useEffect(() => {

        // Generate the summary of provider
        if (documentData?.medicals?.medicalTreatments) {
            setProviderSummary(buildProviderSummary(documentData));
        }

        mapServerDataToFormElements();

    }, [documentData]);

    useEffect(() => {

        //
        // Get the IP Address of the client
        //
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIpAddress(data.ip));

        getUser();
        getDocument();
    }, []);

    return (
        <Container maxWidth='100' sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
        }}>
            <ThemeProvider theme={theme}>
                {documentData && userData ? (
                    <>
                        <div style={{ position: 'fixed', zIndex: 10, top: 60, left: 5, right: 15, backgroundColor: "#FFFFFF" }}>
                            <div className='breadcrumbs-container'>
                                <Stack direction={"row"}>
                                    <Stack spacing={1} direction={"row"} width={"35%"} display="flex" alignItems="center">
                                        <span onClick={handleBackNavClick} >
                                            <Typography variant="body1" color="primary" sx={{ cursor: "pointer", textDecoration: "underline" }}>
                                                Inventory
                                            </Typography>
                                        </span>
                                        <Typography variant="body1" color="primary">
                                            /
                                        </Typography>
                                        <Typography variant="body1" color="primary">
                                            {documentData.sendingFirm.firmCaseNumber}
                                        </Typography>
                                        <Chip className={mapStatusToChipClass(documentStatus)} label={mapStatusToLabel(documentStatus)}></Chip>
                                    </Stack>
                                    <Stack direction={"row"} spacing={2} width={"65%"} justifyContent={"flex-end"}>
                                        <Button variant="outlined" color="secondary"
                                            onClick={(event) => { setAnchorEl(event.currentTarget); }}
                                            aria-haspopup="listbox"
                                            aria-controls="lock-menu"
                                            endIcon={<KeyboardArrowDownRounded />}>More options</Button>
                                        <Menu
                                            id="menu-options"
                                            keepMounted
                                            anchorEl={anchorEl}
                                            open={menuopen}
                                            onClose={(e) => { handleMenuClose(e) }}
                                        >
                                            {userHasPermission("DemandPreview", userData) ?
                                                <MenuItem onClick={(e) => { handlePreviewEmail(true, e) }}>
                                                    <ListItemIcon>
                                                        <RemoveRedEyeOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Email preview to me" />
                                                </MenuItem>
                                                : null}

                                            {userHasPermission("DemandSend", userData) && documentStatus != 'DocumentArchived' ?
                                                <div>
                                                    <Divider />
                                                    <MenuItem onClick={(e) => { handleEmailCarrier(e) }}>
                                                        <ListItemIcon>
                                                            <SendOutlined />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Email to carrier" />
                                                    </MenuItem>
                                                    <MenuItem onClick={(e) => { handleFaxCarrier(e) }}>
                                                        <ListItemIcon>
                                                            <FaxOutlined />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Fax to carrier" />
                                                    </MenuItem>
                                                    <MenuItem onClick={(e) => { handleSendManually(e) }}>
                                                        <ListItemIcon>
                                                            <CheckBoxRounded />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Mark as sent manually" />
                                                    </MenuItem>
                                                </div> : null}
                                            {userHasPermission("DemandArchive", userData) && documentStatus != 'DocumentArchived' ?
                                                <div>
                                                    <Divider />
                                                    <MenuItem onClick={(e) => { handleArchiveDemand(e) }}>
                                                        <ListItemIcon>
                                                            <ArchiveOutlined />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Archive demand" />
                                                    </MenuItem>
                                                </div> : null}
                                        </Menu>
                                        {isUploadMedicalsEnabled(userData, documentStatus) ? (
                                        <Button sx={{ width: 200 }} component="label" variant="outlined" color="secondary" startIcon={<MedicalInformation />}>
                                                    Upload Medicals
                                                    <VisuallyHiddenInput onChange={(e) => {handleCsvUpload(e)}} type="file" accept=".csv" />
                                                </Button>) : null}

                                        {userHasPermission("DemandRequestApproval", userData) && documentStatus != 'DocumentArchived' ? (
                                            <Button disabled={!isRequestApprovalEnabled(documentStatus)} onClick={(e) => { handleApproveDemandClick() }} variant="contained" color="secondary" startIcon={<ApprovalRounded />}>Email approval to firm</Button>
                                        ) : null}
                                    </Stack>
                                </Stack>
                            </div>
                        </div>
                        <Box sx={{ marginTop: '135px' }}></Box>
                        <Card >
                            <Stack direction="row" display="flex" alignItems="center" justifyContent={"space-between"}>
                                <Title>Demand overview</Title>

                                <Stack direction="row" spacing={1}>
                                    {adminView ? (
                                        <IconButton onClick={(e) => { handleEditOverviewClick(e) }}><EditOutlined size="xs" /></IconButton>
                                    ) : null}

                                    <ExpandMore
                                        expand={demandOverviewExpanded}
                                        onClick={handleDemandOverviewExpandClick}
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </Stack>

                            </Stack>
                            <Collapse in={demandOverviewExpanded} timeout="auto" unmountOnExit>
                                <MedicalsOverview documentData={documentData} adminView={userHasPermission("DemandEdit", userData)} />

                                <Divider sx={{ my: 1 }} />

                                <MedicalsOverviewPrecedent documentData={documentData} showCarrierPanel={true} />
                            </Collapse>
                        </Card>

                        {documentData.medicals?.medicalTreatments ? (
                            <div style={{ marginTop: '15px' }}>
                                <MedicalsProviderSummary providerSummary={providerSummary} />

                                <MedicalsTreatments viewType="precedent" documentData={documentData} documentS3Url={documentS3Url} userData={userData} adminView={userHasPermission("DemandEdit", userData)} dataUpdatedCallback={handleDocumentDataUpdated} />
                            </div>
                        ) : (<></>)}

                        <Dialog
                            open={editOverviewOpen}
                            TransitionComponent={Transition}
                            aria-describedby="alert-dialog-slide-description"
                            fullWidth={true}
                        >
                            <DialogTitle>Edit demand overview</DialogTitle>

                            <DialogContent>
                                <Stack spacing={0} sx={{ mt: 0, mb: 1 }}>
                                    <Typography variant="h6" color="secondary">
                                        Displayed data
                                    </Typography>
                                    <Typography variant="subtitle1" color="secondary">
                                        Demand details
                                    </Typography>
                                </Stack>
                                <Stack spacing={2}>

                                    <TextField label="Claim Number" onChange={(e) => { handleFormElementChange(e, 'claim_number') }}
                                        value={getFormData('claim_number')} />
                                    <TextField label="Claim Coverage" onChange={(e) => { handleFormElementChange(e, 'claim_coverage') }}
                                        value={getFormData('claim_coverage')} />
                                    <TextField
                                        id="claim_loss_state"
                                        select
                                        label="Loss state"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => { handleFormElementChange(e, 'claim_loss_state') }}
                                        value={getFormData('claim_loss_state')}
                                    >
                                        {stateLabelValues.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="demand_amount" label="Demand amount" fullWidth value={formatDecimal(getFormData('demand_amount'))}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            onChange={(e) => { handleFormElementChange(e, 'demand_amount') }}></TextField>
                                        <TextField id="policy_limit" label="Policy limit" fullWidth value={formatDecimal(getFormData('policy_limit'))}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            onChange={(e) => { handleFormElementChange(e, 'policy_limit') }}></TextField>
                                        <TextField id="policy_limit_demand" label="Policy limit demand" value={getFormData('is_policy_limit')}
                                            fullWidth select onChange={(e) => { handleFormElementChange(e, 'is_policy_limit') }}
                                        >
                                            <MenuItem key={true} value={true}>
                                                Yes
                                            </MenuItem>
                                            <MenuItem key={false} value={false}>
                                                No
                                            </MenuItem>
                                        </TextField>
                                    </Stack>

                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="date_of_loss" type='date' label="Date of loss" InputLabelProps={{ shrink: true }} fullWidth value={getFormData('claim_date_of_loss')}
                                            onChange={(e) => { handleFormElementChange(e, 'claim_date_of_loss') }}></TextField>

                                        <TextField id="date_of_demand" InputLabelProps={{ shrink: true }} type='date' label="Date of demand" fullWidth value={getFormData('demand_written_date')}
                                            onChange={(e) => { handleFormElementChange(e, 'demand_written_date') }}></TextField>

                                        <TextField id="response_due_date" type='date' label="Response due date" InputLabelProps={{ shrink: true }} fullWidth value={getFormData('demand_response_due_date')}
                                            onChange={(e) => { handleFormElementChange(e, 'demand_response_due_date') }}></TextField>
                                    </Stack>

                                    <Typography variant='subtitle1' color={"secondary"}>Sending firm contact</Typography>

                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="firm_contact_fn" label="Firm contact first name" fullWidth onChange={(e) => { handleFormElementChange(e, 'attorney_contact_first_name') }}
                                            value={getFormData('attorney_contact_first_name')} />
                                        <TextField id="firm_contact_ln" label="Firm contact last name" fullWidth onChange={(e) => { handleFormElementChange(e, 'attorney_contact_last_name') }}
                                            value={getFormData('attorney_contact_last_name')} />
                                    </Stack>

                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="attorney_contact_phone" label="Firm contact phone"
                                            fullWidth value={formatPhoneNumber(getFormData('attorney_contact_phone'))}
                                            onChange={(e) => handleFormElementChange(e, 'attorney_contact_phone')}
                                            error={formError.attorney_contact_phone ? true : false}
                                            helperText={formError.attorney_contact_phone ? 'Invalid phone number. It must have at least 10 digits.' : ''}
                                        />
                                        <TextField id="attorney_contact_email" label="Firm contact email"
                                            fullWidth onChange={(e) => { handleFormElementChange(e, 'attorney_contact_email') }}
                                            value={getFormData('attorney_contact_email')}
                                            error={formError.attorney_contact_email ? true : false}
                                            helperText={formError.attorney_contact_email ? 'Invalid email address.' : ''}
                                        />
                                    </Stack>


                                </Stack>

                                <Divider sx={{ my: 2 }} />

                                <Stack spacing={0} mb={2}>
                                    <Typography variant="h6" color="secondary">
                                        Hidden Data
                                    </Typography>
                                    <Typography variant="subtitle1" color="secondary">
                                        Sending Firm
                                    </Typography>
                                </Stack>
                                <Stack spacing={2}>

                                    <TextField id="attorney_firm_name" label="Firm name" onChange={(e) => { handleFormElementChange(e, 'attorney_firm_name') }}
                                        value={getFormData('attorney_firm_name')} />

                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="attorney_phone" label="Attorney phone" fullWidth value={formatPhoneNumber(getFormData('attorney_phone'))}
                                            onChange={(e) => handleFormElementChange(e, 'attorney_phone')}
                                            error={formError.attorney_phone ? true : false}
                                            helperText={formError.attorney_phone ? 'Invalid phone number. It must have at least 10 digits.' : ''}
                                        />
                                        <TextField id="firm_email" label="Attorney email"
                                            fullWidth onChange={(e) => { handleFormElementChange(e, 'attorney_email') }}
                                            value={getFormData('attorney_email')}
                                            error={formError.attorney_email ? true : false}
                                            helperText={formError.attorney_email ? 'Invalid email address.' : ''}
                                        />
                                    </Stack>


                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="firm_atty_fn" label="Attorney first name" fullWidth onChange={(e) => { handleFormElementChange(e, 'attorney_first_name') }}
                                            value={getFormData('attorney_first_name')} />
                                        <TextField id="firm_atty_ln" label="Attorney last name" fullWidth onChange={(e) => { handleFormElementChange(e, 'attorney_last_name') }}
                                            value={getFormData('attorney_last_name')} />
                                    </Stack>


                                    <Typography variant="subtitle1" color="secondary">
                                        Recipient carrier
                                    </Typography>

                                    <TextField id="carrier_name" fullWidth label="Carrier name" onChange={(e) => { handleFormElementChange(e, 'carrier') }}
                                        value={getFormData('carrier')} />

                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="adjuster_fn" label="Adjuster first name" fullWidth onChange={(e) => { handleFormElementChange(e, 'adjuster_first_name') }}
                                            value={getFormData('adjuster_first_name')} />

                                        <TextField id="adjuster_ln" label="Adjuster last name" fullWidth onChange={(e) => { handleFormElementChange(e, 'adjuster_last_name') }}
                                            value={getFormData('adjuster_last_name')} />
                                    </Stack>

                                    <TextField id="adjuster_phone" label="Adjuster phone number" fullWidth value={formatPhoneNumber(getFormData('adjuster_phone'))}
                                        onChange={(e) => handleFormElementChange(e, 'adjuster_phone')}
                                        error={formError.adjuster_phone ? true : false}
                                        helperText={formError.adjuster_phone ? 'Invalid phone number. It must have at least 10 digits.' : ''}
                                    />

                                    <TextField id="carrier_email" label="Carrier email"
                                        fullWidth onChange={(e) => { handleFormElementChange(e, 'carrier_email') }}
                                        value={getFormData('carrier_email')}
                                        error={formError.carrier_email ? true : false}
                                        helperText={formError.carrier_email ? 'Invalid email address.' : ''}
                                    />

                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="carrier_phone" label="Carrier phone" fullWidth value={formatPhoneNumber(getFormData('carrier_phone'))}
                                            onChange={(e) => handleFormElementChange(e, 'carrier_phone')}
                                            error={formError.carrier_phone ? true : false}
                                            helperText={formError.carrier_phone ? 'Invalid phone number. It must have at least 10 digits.' : ''}
                                        />
                                        <TextField id="carrier_fax" label="Carrier fax" fullWidth value={formatPhoneNumber(getFormData('carrier_fax'))}
                                            onChange={(e) => handleFormElementChange(e, 'carrier_fax')}
                                            error={formError.carrier_fax ? true : false}
                                            helperText={formError.carrier_fax ? 'Invalid fax number. It must have at least 10 digits.' : ''}
                                        />
                                    </Stack>

                                    <Typography variant="subtitle1" color="secondary">
                                        Loss details
                                    </Typography>

                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="claimant_fn" label="Client first name" fullWidth onChange={(e) => { handleFormElementChange(e, 'client_first_name') }}
                                            value={getFormData('client_first_name')} />
                                        <TextField id="claimant_ln" label="Client last name" fullWidth onChange={(e) => { handleFormElementChange(e, 'client_last_name') }}
                                            value={getFormData('client_last_name')} />
                                    </Stack>

                                    <Stack direction={"row"} spacing={2}>
                                        <TextField id="insured_fn" label="Insured first name" fullWidth onChange={(e) => { handleFormElementChange(e, 'named_insured_first_name') }}
                                            value={getFormData('named_insured_first_name')} />
                                        <TextField id="insured_ln" label="Insured last name" fullWidth onChange={(e) => { handleFormElementChange(e, 'named_insured_last_name') }}
                                            value={getFormData('named_insured_last_name')} />
                                    </Stack>

                                    <TextField id="loss_county" fullWidth label="Loss County" onChange={(e) => { handleFormElementChange(e, 'claim_loss_county') }}
                                        value={getFormData('claim_loss_county')} />

                                </Stack>


                            </DialogContent>

                            <DialogActions>
                                <Button color='error' onClick={() => { handleEditOverviewClose(false) }}>Discard changes</Button><Button variant="contained" color="secondary" onClick={(e) => { handleSaveOverviewClick(); }}>Save & exit</Button>
                            </DialogActions>
                        </Dialog>

                        <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

                        <SendPreviewDialog user={user} documentId={documentId} handler={sendPreviewComplete} handleClose={handlePreviewDialogClose} dialogOpen={previewDialogOpen}></SendPreviewDialog>

                        <ArchiveDialog firmCaseNumber={documentData.sendingFirm.firmCaseNumber} handler={archiveDemand} handleClose={handleArchiveDialogClose} dialogOpen={archiveDialogOpen}></ArchiveDialog>

                        <SendManuallyDialog handler={sendManually} handleClose={handleManualDialogClose} dialogOpen={manualDialogOpen}></SendManuallyDialog>

                        <CarrierSendFaxDialog user={user} documentId={documentId} carrierName={documentData.recipientCarrier.carrierCommonName} deliveryFax={deliveryCarrierFax} handler={sendFaxToCarrierComplete} handleClose={handleCarrierFaxDialogClose} dialogOpen={faxDialogOpen}></CarrierSendFaxDialog>

                        <CarrierSendDialog user={user} documentId={documentId} carrierName={documentData.recipientCarrier.carrierCommonName} deliveryEmail={deliveryCarrierEmail} handler={sendEmailToCarrierComplete} handleClose={handleCarrierDialogClose} dialogOpen={carrierDialogOpen}></CarrierSendDialog>

                        <AttorneySendDialog user={user} documentId={documentId} deliveryEmail={deliveryAttorneyEmail} handler={sendForAttorneyApprovalComplete} handleClose={handleAttorneyDialogClose} dialogOpen={attorneyDialogOpen}></AttorneySendDialog>
                    </>
                ) : (
                    <p></p>
                )}


                <Dialog
                    open={infoDialogOpen}
                    TransitionComponent={Transition}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{infoDialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {infoDialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { handleInfoDialogClose() }}>Ok</Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={toastOpen}
                    autoHideDuration={2000}
                    onClose={handleToastClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>{toastMessage}</Alert>
                </Snackbar>

            </ThemeProvider>
        </Container>

    );
};
