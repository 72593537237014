
import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { Transition } from '../Transition'
import { submitFirmApprovalRequestApi } from '../api';
import StatusDialog from "./StatusDialog";


const AttorneySendDialog = (props) => {
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const attorneyApprove = async (userTriggered) => {

        if (userTriggered) {
            props.handleClose();
            setStatusCloseDisabled(true);
            setStatusDialogTitle("Sending Approval");
            showStatusDialog("Sending approval message to attorney...");
        }

        submitFirmApprovalRequestApi(props.documentId, props.user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("Success! The demand has been sent for approval.");
                    setStatusCloseDisabled(false);
                    props.handler(true);
                }
                else if (response.status == 201) {
                    setStatusDialogText("The demand is being regenerated. This should only take a few moments.");
                    setTimeout(function () { attorneyApprove(false) }, 3000);
                }
                else {
                    showStatusDialog("There was an error sending the email :-(");
                    setStatusCloseDisabled(false);
                    props.handler(false);
                }
            });
    };

    return (
        <>
            <Dialog
                open={props.dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Send demand to attorney</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        You are about to deliver this demand to <b>{props.deliveryEmail}</b>. Click the 'Send' button to continue.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.handleClose() }}>Cancel</Button><Button variant="contained" color="secondary" onClick={() => { attorneyApprove(true) }}>Send</Button>
                </DialogActions>
            </Dialog>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

        </>
    )

}

export default AttorneySendDialog;