
import React, { useState  } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, MenuItem, TextField, Stack } from '@mui/material';
import {Transition} from '../Transition'
import Title from '../Title';


const FirmRejectDialog = (props) => {


    const [rejectReason, setRejectReason] = useState();
    const [comment, setComment] = useState();

    const rejectDemand = () =>  {
        props.handler(rejectReason, comment);
    };

    return (
        <Dialog
                open={props.dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle>
                    <Title>Request Additional Review</Title>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Stack spacing={2} sx={{mt:2}} >
                        <TextField id="reject_reason" required={true} label="Reason for additional review" value={rejectReason}
                            fullWidth  select onChange={(e)=>{setRejectReason(e.target.value)}}>
                            <MenuItem key={"archive"} value={"Archive, not ready for demand"}>Archive, not ready for demand</MenuItem>
                            <MenuItem key={"edits"} value={"Requesting edits"}>Requesting edits</MenuItem>
                            <MenuItem key={"other"} value={"Other"}>Other</MenuItem>
                        </TextField>

                        <TextField id="comment" required={true} label="Comment" value={comment}
                            fullWidth  onChange={(e)=>{setComment(e.target.value)}}></TextField>
                    
                    </Stack>
    
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => {props.handleClose()}}>Cancel</Button><Button disabled={!comment || comment == "" || !rejectReason || rejectReason == ""} variant="contained" color="secondary" onClick={() => {rejectDemand()}}>Send For Review</Button>
                </DialogActions>
            </Dialog>
    )

}

export default FirmRejectDialog;