
import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { Transition } from '../Transition'
import { faxToCarrierApi } from '../api';
import StatusDialog from "./StatusDialog";


const CarrierSendFaxDialog = (props) => {

    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const faxToCarrier = async (userTriggered) => {
        if (userTriggered) {
            props.handleClose();
            setStatusCloseDisabled(true);
            setStatusDialogTitle("Fax demand");
            showStatusDialog("Sending fax...");
        }

        faxToCarrierApi(props.documentId, props.user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("Success! The fax has been queued up to send.");
                    setStatusCloseDisabled(false);
                    props.handler(true);
                }
                else if (response.status == 201) {
                    setStatusDialogText("The demand is being regenerated. This should only take a few moments.");
                    setTimeout(function () { faxToCarrier(false) }, 3000);
                }
                else {
                    setStatusCloseDisabled(false);
                    showStatusDialog("There was an error sending the fax :-(");
                    props.handler(false);
                }
            });
    };

    return (
        <>
            <Dialog
                open={props.dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Fax demand to carrier</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        You are about to approve this demand to be submitted to <b>{props.carrierName} ({props.deliveryFax})</b> via Precedent Exchange.<br /><br />Click 'Approve & send' to continue.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.handleClose() }}>Cancel</Button><Button variant="contained" color="secondary" onClick={() => { faxToCarrier(true) }}>Approve & send</Button>
                </DialogActions>
            </Dialog>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    )

}

export default CarrierSendFaxDialog;