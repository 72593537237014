

export const PRECEDENT_ADMIN_ROLE = "PrecedentAdmin";
export const PRECEDENT_EDITOR_ROLE = "PrecedentEditor";
export const PRECEDENT_USER_ROLE = "PrecedentUser";
export const PRECEDENT_CALLER_ROLE = "PrecedentCaller";

export const PRECEDENT_ROLES = [PRECEDENT_ADMIN_ROLE, PRECEDENT_EDITOR_ROLE, PRECEDENT_USER_ROLE, PRECEDENT_CALLER_ROLE];
export const LAW_FIRM_ROLES = ["LawFirmUser", "LawFirmEditor", "LawFirmApprover", "LawFirmAdmin"];

export const isLawFirmUser = (user) => {
    return user && (user["roles"].some(r=> LAW_FIRM_ROLES.indexOf(r) >= 0));
};

export const isPrecedentUser = (user) => {
    return user && (user["roles"].some(r=> PRECEDENT_ROLES.indexOf(r) >= 0));
};