
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import { CircularProgress, Grid, Stack, TextField, ThemeProvider } from '@mui/material';
import { Link } from "react-router-dom";
import { theme } from './Theme'
import Title from './Title';


export const Error404Page = (props) => {

    return (
        <Container maxWidth='100' sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
            padding: '10px'
        }}>
            <ThemeProvider theme={theme}>
                <>
                    <Title>Error 404 : Page Not Found</Title>
                    <div style={{ position: 'fixed', zIndex: 1, top: 63, width: '97%' }}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ minHeight: "100vh" }}
                        >
                            <Grid item>
                                <h2>Error 404 : Page Not Found</h2>
                            </Grid>
                            <Grid item>
                                <Link to="/">Go Home</Link>
                            </Grid>
                        </Grid>
                    </div>
                </>
            </ThemeProvider>
        </Container>

    );
};
