import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { Snackbar, Alert, AlertTitle, Paper, Stack, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Button, Container, TextField, Divider, MenuItem, InputAdornment, Card } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import awsExports from './aws-exports';
import { formatDecimal, getCurrentDate, isValidEmail, cleanNumber } from './common';
import { isLawFirmUser, isPrecedentUser } from './common-roles';
import { carriersList, coverageList } from './common-data';
import { CheckCircleRounded, CloudUploadRounded, ContentCopyRounded } from '@mui/icons-material';
import { getUserApi } from './api';
import StatusDialog from './dialogs/StatusDialog';
import { styled } from '@mui/material/styles';
import { stateLabelValues } from './common-data';

const customerservice_email = "customerservice@precedent.com";
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const filter = createFilterOptions();

export function SubmitNewDemand(props) {

    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    const rootApiUrl = awsExports.ROOT_API_URL;
    const [primaryContacts, setPrimaryContacts] = useState([]);
    const [approvers, setApprovers] = useState([]);
    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState({});
    const [showUserHelpMessage, setShowUserHelpMessage] = useState(false);
    const [disableAttorneyEmail, setDisableAttorneyEmail] = useState(true);
    const [disableContactEmail, setDisableContactEmail] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [userData, setUserData] = useState();
    const [demandDoc, setDemandDoc] = useState("");
    const [demandDocLoaded, setDemandDocLoaded] = useState("");
    const [rawFile, setRawFile] = useState();
    const [demandFileName, setDemandFileName] = useState("");
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();

    const navigate = useNavigate();

    // API calls

    const submitDemand = async (e) => {

        setSubmitting(true);
        showStatusDialog("Submitting Demand", "The demand is being submitted to Precedent, please wait...");


        // Create the demand
        const demand = buildDocumentObject();
        const documentResponse = await createDocumentApi(demand);
        if (documentResponse.status != 200) {
            setStatusDialogText("There was an error submitting the demand.");
            setSubmitting(false);
            return;
        }

        const documentId = (await documentResponse.json())["documentId"]

        console.log("New document id " + documentId);

        // Get the upload url for the demand pdf
        const uploadFields = await getUploadUrl('originalDemand.pdf', documentId);

        // Upload the demand to s3
        const success = await pushDemandDocumentToS3(uploadFields);

        if (success) {
            setStatusDialogText("Generating the demand PDF document.");
            regenerateDocumentApi(documentId);
            setTimeout(() => {
                checkStatus(documentId);
            }, 3000);
        }
        else {
            setStatusDialogText("There was an error submitting the demand.");
            setSubmitting(false);
        }
    };

    const checkStatus = async (documentId) => {

        const response = await regenerateDocumentStatusApi(documentId);
        const status = response["status"];
        console.log("Regeneration status is: " + status + " for document " + documentId);

        if (status === "Complete") {
            setStatusDialogOpen(false);
            if (isLawFirmUser(userData))
                navigate('/l/' + documentId);
            else if (isPrecedentUser(userData))
                navigate('/a/' + documentId);
            return;
        }
        else if (status === "Failed") {
            setSubmitting(false);
            setStatusDialogText("The document PDF generation failed however the demand has been created.");
            return;
        }

        setTimeout(() => {
            checkStatus(documentId);
        }, 3000);
    }



    const getUser = async () => {
        setUserData(await getUserApi(user));
    };

    const pushDemandDocumentToS3 = async (uploadFields) => {

        const headers = new Headers({});
        const formData = new FormData();
        formData.append("key", uploadFields["fields"]["key"]);
        formData.append("AWSAccessKeyId", uploadFields["fields"]["AWSAccessKeyId"]);
        formData.append("policy", uploadFields["fields"]["policy"]);
        formData.append("signature", uploadFields["fields"]["signature"]);
        formData.append("x-amz-security-token", uploadFields["fields"]["x-amz-security-token"]);
        formData.append("file", rawFile);

        const response = await fetch(uploadFields["url"], {
            method: 'POST',
            headers: headers,
            body: formData
        });

        return response.status === 204;
    };

    const createDocumentApi = async (document) => {

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(document)
        };

        const response = await fetch(
            rootApiUrl + "/document", settings
        ).then((response) => { return response; })
            .finally(() => {
            });

        return response;
    };

    const regenerateDocumentStatusApi = async (documentId) => {

        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };

        const response = await fetch(
            rootApiUrl + "/document/" + documentId + "/documentRegenerate", settings
        ).then((response) => response.json())
            .finally(() => {
            });

        return response;
    };

    const regenerateDocumentApi = async (documentId) => {

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };

        const response = await fetch(
            rootApiUrl + "/document/" + documentId + "/documentRegenerate", settings
        ).then((response) => response.json())
            .finally(() => {
            });

        return response;
    };

    const getUploadUrl = async (fileName, documentId) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify({ fileName: fileName, documentId: documentId })
        };

        const response = await fetch(
            rootApiUrl + "/document/generateUploadUrl", settings
        ).then((response) => response.json())
            .finally(() => {
            });

        return response["uploadFields"];
    };

    const getAuthorFormData = async () => {
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };

        const response = await fetch(
            rootApiUrl + "/authorFormData", settings
        ).then((response) => response.json())
            .finally(() => {
            });


        const primaryContactList = []
        response.users.forEach(element => {
            const name = buildFullName(element);
            primaryContactList.push({ value: name, label: name, emailAddress: element.emailAddress });
        });
        setPrimaryContacts([...primaryContactList]);

        const aproversList = []
        response.approvers.forEach(element => {
            const name = buildFullName(element);
            aproversList.push({ value: name, label: name, emailAddress: element.emailAddress });
        });
        setApprovers([...aproversList]);

        // Default some field
        formData['is_policy_limit'] = "true";
        setFormData({ ...formData });

        // Load users now that we have some data.
        getUser();
    };

    const buildDocumentObject = () => {

        const document = {};

        document["recipientCarrier"] = {};
        document["recipientCarrier"]["carrierCommonName"] = formData["carrier"];
        document["recipientCarrier"]["contactInfo"] = {};
        document["recipientCarrier"]["contactInfo"]["phoneNumber"] = formData["carrier_phone"];
        document["recipientCarrier"]["contactInfo"]["faxNumber"] = formData["carrier_fax"];
        document["recipientCarrier"]["contactInfo"]["emailAddress"] = formData["carrier_email"];

        document["recipientAdjuster"] = {};
        document["recipientAdjuster"]["firstName"] = formData["adj_fn"];
        document["recipientAdjuster"]["lastName"] = formData["adj_ln"];
        document["recipientAdjuster"]["contactInfo"] = {};

        document["sendingFirm"] = {};
        document["sendingFirm"]["firmCaseNumber"] = formData["matter_num"];
        document["sendingFirm"]["attorney"] = {};
        document["sendingFirm"]["attorney"]["contactInfo"] = {}
        if (formData["attorney"]) {
            const { firstName, lastName } = extractName(formData["attorney"]);
            document["sendingFirm"]["attorney"]["firstName"] = firstName
            document["sendingFirm"]["attorney"]["lastName"] = lastName;
        }
        document["sendingFirm"]["attorney"]["contactInfo"]["emailAddress"] = formData["attorney_email"];

        document["sendingFirm"]["primaryContact"] = {};
        document["sendingFirm"]["primaryContact"]["contactInfo"] = {}
        if (formData["primary_contact"]) {
            const { firstName, lastName } = extractName(formData["primary_contact"]);
            document["sendingFirm"]["primaryContact"]["firstName"] = firstName
            document["sendingFirm"]["primaryContact"]["lastName"] = lastName;
        }
        document["sendingFirm"]["primaryContact"]["contactInfo"]["emailAddress"] = formData["primary_contact_email"];

        document["claimInfo"] = {};
        document["claimInfo"]["claimNumber"] = formData["claim_number"];
        document["claimInfo"]["dateOfLoss"] = formData["date_of_loss"];
        document["claimInfo"]["claimCoverage"] = formData["coverage"];
        document["claimInfo"]["insuredFirstName"] = formData["insured_fn"];
        document["claimInfo"]["insuredLastName"] = formData["insured_ln"];
        document["claimInfo"]["insuredPolicyLimitsAsUnderstoodByFirm"] = cleanNumber(formData["insured_policy_limit_amt"]);
        document["claimInfo"]["claimant"] = {};
        document["claimInfo"]["claimant"]["firstName"] = formData["client_fn"];
        document["claimInfo"]["claimant"]["lastName"] = formData["client_ln"];
        document["claimInfo"]["lossState"] = formData["loss_state"];
        document["claimInfo"]["lossCounty"] = formData["loss_county"];


        document["demandDetails"] = {};
        document["demandDetails"]["policyLimitDemandIndicator"] = formData["is_policy_limit"] === "true";
        document["demandDetails"]["demandWrittenDate"] = formData["demand_create_dt"];
        document["demandDetails"]["demandResponseDueDate"] = formData["demand_due_dt"];
        document["demandDetails"]["demandOfferToSettleAmount"] = cleanNumber(formData["demand_amt"]);


        return document;

    };

    const extractName = (fullName) => {
        const namesArray = fullName.split(' ');
        const firstName = namesArray.slice(0)[0];
        const lastName = namesArray.slice(-1 * (namesArray.length - 1)).join('');
        return { firstName, lastName };
    };

    // UI handlers 
    const validateFormData = () => {
        var formValid = isElementValueSet("carrier") && isElementValueSet("matter_num") && isElementValueSet("demandFile");
        setSubmitButtonDisabled(!formValid);
        return formValid
    }

    const isElementValueSet = (elementId) => {
        var isValid = true;
        if (elementId === "demandFile") {
            if (!haveValidFile()) {
                isValid = false;
            }
        }
        else {
            if (!formData[elementId]) {
                isValid = false;
            }
        }
        return isValid;
    };

    const validateFormElement = (elementId) => {
        var isValid = true;
        if (elementId === "demandFile") {
            if (!haveValidFile()) {
                formError["demandFile"] = "Please upload a demand file";
                isValid = false;
            }
            else {
                formError["demandFile"] = "";
            }
        }
        else {
            if (!formData[elementId]) {
                formError[elementId] = "This field is required";
                isValid = false;
            }
            else {
                formError[elementId] = "";
            }
        }

        setFormError({ ...formError });

        return isValid;
    };

    const handleFileUpload = (e) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        setRawFile(file);
        const { name, size } = file;

        if (size > 26214400) { // 25MB
            showStatusDialog("Error", "Files cannot be larger than 25MB");
            return;
        }

        setDemandFileName(name);

        const reader = new FileReader();
        reader.onload = (evt) => {
            if (!evt?.target?.result) {
                return;
            }
            var { result } = evt.target;
            result = result.split("base64,")[1];
            setDemandDoc(result);
            setDemandDocLoaded(true);
        };
        reader.readAsDataURL(file);
    };

    const getFormData = (id) => {
        if (id in formData) {
            return formData[id];
        }
        return "";
    };

    const handleFormElementChange = (event, id) => {
        const elementId = id || event.target.id;
        formData[elementId] = event.target.value;


        if (id.includes("email")) {
            //validate that the email is in the correct format
            if (formData[elementId] !== "" && isValidEmail(formData[elementId]) === false) {
                setFormError({ ...formError, [elementId]: "Invalid email address" });
            }
            //otherwise, clear the error
            else {
                setFormError({ ...formError, [elementId]: "" });
            }
        }
        else if (id === 'is_policy_limit') {
            if (formData['is_policy_limit'] === "true") {
                formData['demand_amt'] = formData['insured_policy_limit_amt'];
            }
            else {
                formData['demand_amt'] = "";
            }
        }
        else if (id === 'insured_policy_limit_amt') {
            if (formData['is_policy_limit'] === "true") {
                formData['demand_amt'] = formData['insured_policy_limit_amt'];
            }
        }


        setFormData({ ...formData });
    };

    const buildFullName = (user) => {
        return user.firstName + " " + user.lastName
    };

    const findUser = (fullName, users) => {
        const user = users.find((element) => { return element.label === fullName });
        return user;
    };

    const handlAutocompleteElementChange = (elementId, newValue) => {
        if (typeof newValue === 'string') {
            formData[elementId] = newValue;
        } else if (newValue && newValue.inputValue) {
            formData[elementId] = newValue.inputValue;
        } else if (newValue && newValue.value) {
            formData[elementId] = newValue.value;
        } else {
            formData[elementId] = "";
        }

        if (elementId === 'attorney') {
            if (!formData[elementId] || formData[elementId] === "") {
                setDisableAttorneyEmail(false)
            }
            else {
                setShowUserHelpMessage(false);
                const user = findUser(formData[elementId], approvers);
                if (user) {
                    setDisableAttorneyEmail(true);
                    formData['attorney_email'] = user.emailAddress;
                }
                else {
                    setDisableAttorneyEmail(false);
                    setShowUserHelpMessage(true);
                }

            }
        }

        if (elementId === 'primary_contact') {
            if (!formData[elementId] || formData[elementId] === "") {
                setDisableContactEmail(false)
            }
            else {
                setShowUserHelpMessage(false);

                const user = findUser(formData[elementId], primaryContacts);
                if (user) {
                    setDisableContactEmail(true)
                    formData['primary_contact_email'] = user.emailAddress;
                }
                else {
                    setDisableContactEmail(false);
                    setShowUserHelpMessage(true);
                }

            }
        }

        setFormData({ ...formData });
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleToastClose = () => {
        setToastMessage("");
        setToastOpen(false);
    };

    const haveValidFile = () => {
        return demandFileName && demandDoc.length > 0;
    };

    useEffect(() => {
        if (demandDocLoaded)
            validateFormElement("demandFile");
        validateFormData();
    }, [demandDocLoaded]);

    useEffect(() => {
        validateFormData();
    }, [formData]);


    useEffect(() => {

        if (userData) {
            const fullName = buildFullName(userData);
            const existingUser = findUser(fullName, approvers);

            // Add to approver list if not already in there.
            if (!existingUser) {
                approvers.push({ label: fullName, value: fullName, emailAddress: userData.emailAddress });
                setApprovers([...approvers]);
            }

            formData['attorney'] = fullName;
            formData['attorney_email'] = userData.emailAddress;

            setFormData({ ...formData });
        }
    }, [userData]);

    useEffect(() => {
        getAuthorFormData();
    }, []);

    return (
        <div style={{ paddingTop: '80px' }}>
            <div style={{ position: "fixed", left: 0, right: 0, top: 60, zIndex: 100, paddingLeft: '40px', paddingTop: '10px', paddingRight: '40px', backgroundColor: "#F3FAFF" }}>
                <Stack direction={"row"}>
                    <Stack direction={"row"} width={"35%"}>
                        <div className='breadcrumbs-page-title-container' onClick={(e) => { navigate('/inventory') }}><span className='breadcrumbs-page-link'>Inventory</span> <span className='breadcrumbs-page-active'>/ Submit new demand</span></div>
                    </Stack>
                    <Stack direction={"row"} spacing={2} width={"65%"} justifyContent={"flex-end"}>
                        <Button variant="contained" color='secondary'
                            disabled={submitButtonDisabled}
                            onClick={(event) => { submitDemand(event) }}
                        >Submit</Button>
                    </Stack>
                </Stack>
            </div>

            <Stack spacing={2} direction="row" sx={{ mt: 2, mb: 4 }} justifyContent={"center"}>
                <Container>
                    <Stack spacing={2} width={"100%"}>
                        <div className='author-title'>Submit a demand in 3 steps</div>

                        <div className='author-step-title'>Step 1</div>

                        <Card>
                            <Stack spacing={2} width={"100%"}>
                                <div className='author-section-title'>Case information</div>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <TextField disabled={true} required value={demandFileName} helperText={formError["demandFile"]} error={formError["demandFile"] ? true : false} fullWidth={true} label='Demand document' InputProps={{
                                            endAdornment:
                                                <Button sx={{ width: 200 }} component="label" variant="outlined" color="secondary" startIcon={<CloudUploadRounded />}>
                                                    {haveValidFile() ? "Replace" : "Upload"}
                                                    <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept=".pdf" />
                                                </Button>,
                                            startAdornment:
                                                <div style={{ width: 50, paddingTop: '5px', visibility: haveValidFile() ? 'visible' : 'hidden' }}>
                                                    <CheckCircleRounded color="success"></CheckCircleRounded>
                                                </div>
                                        }}
                                        />
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} required label="Matter number or Case ID" onChange={(e) => { handleFormElementChange(e, 'matter_num') }}
                                            value={getFormData('matter_num')} onBlur={(e) => { validateFormElement("matter_num") }} helperText={formError["matter_num"]} error={formError["matter_num"] ? true : false}></TextField>
                                    </Stack>
                                </Stack>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Client first name" onChange={(e) => { handleFormElementChange(e, 'client_fn') }}
                                            value={getFormData('client_fn')} ></TextField>
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Client last name" onChange={(e) => { handleFormElementChange(e, 'client_ln') }}
                                            value={getFormData('client_ln')} ></TextField>
                                    </Stack>
                                </Stack>


                                <Divider style={{ marginTop: '30px' }} />

                                <div className='author-subsection-title'>Carrier details</div>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <Autocomplete
                                            value={{ value: getFormData('carrier'), label: getFormData('carrier') }}
                                            onChange={(event, newValue) => {
                                                handlAutocompleteElementChange('carrier', newValue);
                                            }}
                                            onBlur={(event) => {
                                                validateFormElement("carrier");
                                            }}
                                            selectOnFocus
                                            forcePopupIcon
                                            autoSelect
                                            handleHomeEndKeys
                                            id="carrier"
                                            options={carriersList}
                                            fullWidth={true}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.value;
                                                }
                                                // Regular option
                                                return option.label;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                            freeSolo={true}
                                            renderInput={(params) => (
                                                <TextField  {...params} required inputProps={{
                                                    ...params.inputProps,
                                                    required: getFormData('carrier').length === 0
                                                }} helperText={formError["carrier"]} error={formError["carrier"] ? true : false} fullWidth label="Carrier" />
                                            )}
                                        />
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Claim Number" onChange={(e) => { handleFormElementChange(e, 'claim_number') }}
                                            value={getFormData('claim_number')}></TextField>
                                    </Stack>
                                </Stack>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} inputProps={{ max: getCurrentDate() }} label="Date of loss" type="date" InputLabelProps={{ shrink: true }} onChange={(e) => { handleFormElementChange(e, 'date_of_loss') }}
                                            value={getFormData('date_of_loss')}  ></TextField>
                                    </Stack>
                                    <Stack flex={.5}>
                                        <Autocomplete
                                            value={{ value: getFormData('coverage'), label: getFormData('coverage') }}
                                            onChange={(event, newValue) => {
                                                handlAutocompleteElementChange('coverage', newValue);
                                            }}
                                            selectOnFocus
                                            forcePopupIcon
                                            autoSelect
                                            handleHomeEndKeys
                                            id="coverage"
                                            options={coverageList}
                                            fullWidth={true}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.value;
                                                }
                                                // Regular option
                                                return option.label;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField  {...params} fullWidth label="Coverage" />
                                            )}
                                        />
                                    </Stack>
                                </Stack>


                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Insured first name" onChange={(e) => { handleFormElementChange(e, 'insured_fn') }}
                                            value={getFormData('insured_fn')}  ></TextField>
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Insured last name" onChange={(e) => { handleFormElementChange(e, 'insured_ln') }}
                                            value={getFormData('insured_ln')}></TextField>
                                    </Stack>
                                </Stack>

                                <TextField fullWidth={true} label="Insured policy limit" onChange={(e) => { handleFormElementChange(e, 'insured_policy_limit_amt') }}
                                    value={formatDecimal(getFormData('insured_policy_limit_amt'))} InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}></TextField>

                                <Divider style={{ marginTop: '30px' }} />

                                <div className='author-subsection-title'>Additional carrier details</div>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Adjuster first name" onChange={(e) => { handleFormElementChange(e, 'adj_fn') }}
                                            value={getFormData('adj_fn')}></TextField>
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Adjuster last name" onChange={(e) => { handleFormElementChange(e, 'adj_ln') }}
                                            value={getFormData('adj_ln')}></TextField>
                                    </Stack>
                                </Stack>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Carrier phone number" onChange={(e) => { handleFormElementChange(e, 'carrier_phone') }}
                                            value={getFormData('carrier_phone')}></TextField>
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Carrier fax number" onChange={(e) => { handleFormElementChange(e, 'carrier_fax') }}
                                            value={getFormData('carrier_fax')}></TextField>
                                    </Stack>
                                </Stack>

                                <TextField fullWidth={true} label="Carrier email" onChange={(e) => { handleFormElementChange(e, 'carrier_email') }}
                                    value={getFormData('carrier_email')} error={formError.carrier_email ? true : false}
                                    helperText={formError.carrier_email}></TextField>
                            </Stack>

                        </Card>

                        <Divider style={{ marginTop: '30px' }} />

                        <div className='author-step-title'>Step 2</div>

                        <Card >
                            <Stack spacing={2} width={"100%"}>
                                <div className='author-section-title'>Demand details</div>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} inputProps={{ max: getCurrentDate() }} label="Demand creation date" type="date" InputLabelProps={{ shrink: true }} onChange={(e) => { handleFormElementChange(e, 'demand_create_dt') }}
                                            value={getFormData('demand_create_dt')}></TextField>
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Demand due date" type="date" InputLabelProps={{ shrink: true }} onChange={(e) => { handleFormElementChange(e, 'demand_due_dt') }}
                                            value={getFormData('demand_due_dt')}></TextField>
                                    </Stack>
                                </Stack>


                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <FormControl>
                                            <FormLabel id="policy-radio-buttons-group-label">Is policy limit demand?</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-radio-group-label"
                                                defaultValue="true"
                                                name="radio-buttons-group"
                                                onChange={(e) => { handleFormElementChange(e, 'is_policy_limit') }}
                                                value={getFormData('is_policy_limit')}
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="false" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} disabled={formData['is_policy_limit'] === "true"} label="Demand Amount" onChange={(e) => { handleFormElementChange(e, 'demand_amt') }}
                                            value={formatDecimal(getFormData('demand_amt'))} InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}></TextField>
                                    </Stack>
                                </Stack>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} select label="Loss state" type="select" onChange={(e) => { handleFormElementChange(e, 'loss_state') }}
                                            value={getFormData('loss_state')}>
                                            {stateLabelValues.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Loss county" onChange={(e) => { handleFormElementChange(e, 'loss_county') }}
                                            value={getFormData('loss_county')}></TextField>
                                    </Stack>
                                </Stack>
                            </Stack>

                        </Card>

                        <Divider style={{ marginTop: '30px' }} />

                        <div className='author-step-title'>Step 3</div>

                        <Card>
                            <Stack spacing={2} width={"100%"}>
                                <div className='author-section-title'>Contact information</div>

                                <div className='author-info-box' style={{ display: showUserHelpMessage ? 'block' : 'none' }}>
                                    <Stack direction={"row"}>
                                        <Stack direction={"row"} width={"75%"}>
                                            <Alert severity="info">
                                                <AlertTitle>Having troubling finding an attorney or primary contact?</AlertTitle>
                                                Contact customerservice@precedent.com to add the user to the system.
                                            </Alert>
                                        </Stack>
                                        <Stack direction={"row"} spacing={2} width={"25%"} justifyContent={"flex-end"}>
                                            <Button color='primary'
                                                variant='text'
                                                size='small'
                                                startIcon={<ContentCopyRounded />}
                                                onClick={(event) => { navigator.clipboard.writeText(customerservice_email); setToastMessage("Copied to clipboard"); setToastOpen(true); }}
                                            >{customerservice_email}</Button>
                                        </Stack>
                                    </Stack>
                                </div>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <Autocomplete
                                            value={{ value: getFormData('attorney'), label: getFormData('attorney') }}
                                            onChange={(event, newValue) => {
                                                handlAutocompleteElementChange('attorney', newValue);
                                            }}
                                            selectOnFocus
                                            forcePopupIcon
                                            autoSelect
                                            handleHomeEndKeys
                                            id="attorney"
                                            options={approvers}
                                            fullWidth={true}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.value;
                                                }
                                                // Regular option
                                                return option.label;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField  {...params} fullWidth label="Attorney" />
                                            )}
                                        />
                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Attorney email" disabled={disableAttorneyEmail} onChange={(e) => { handleFormElementChange(e, 'attorney_email') }}
                                            value={getFormData('attorney_email')} error={formError.attorney_email ? true : false}
                                            helperText={formError.attorney_email}></TextField>
                                    </Stack>
                                </Stack>

                                <Stack direction={"row"} spacing={2}>
                                    <Stack flex={.5}>
                                        <Autocomplete
                                            value={{ value: getFormData('primary_contact'), label: getFormData('primary_contact') }}
                                            onChange={(event, newValue) => {
                                                handlAutocompleteElementChange('primary_contact', newValue);
                                            }}
                                            selectOnFocus
                                            forcePopupIcon
                                            autoSelect
                                            handleHomeEndKeys
                                            id="primary_contact"
                                            options={primaryContacts}
                                            fullWidth={true}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.value;
                                                }
                                                // Regular option
                                                return option.label;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField  {...params} fullWidth label="Primary contact" />
                                            )}
                                        />


                                    </Stack>
                                    <Stack flex={.5}>
                                        <TextField fullWidth={true} label="Primary contact email" disabled={disableContactEmail} onChange={(e) => { handleFormElementChange(e, 'primary_contact_email') }}
                                            value={getFormData('primary_contact_email')} error={formError.primary_contact_email ? true : false}
                                            helperText={formError.primary_contact_email}></TextField>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Card>
                    </Stack>
                </Container>
            </Stack>

            <Snackbar
                open={toastOpen}
                autoHideDuration={2000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>{toastMessage}</Alert>
            </Snackbar>
            <StatusDialog closeDisabled={submitting} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

        </div>
    );

}