
import React, { createRef, useState, useEffect } from "react";
import { getCurrentDate, formatDecimal, formatCurrency, formatDate, userHasPermission, convertUTCDateToLocalDate, cleanNumber } from './common';
import { currencyComparator } from './comparators';
import { getMedicalTreatmentAlerts } from './helpers/medicalsTreatments';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import { TextField, Stack, Button, Paper, Box, InputAdornment, ListItemIcon, Menu, MenuItem, ListItemText, Tooltip, Card } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Divider from '@mui/material/Divider';
import Title from './Title';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Add, KeyboardArrowDownRounded, AddCard, Close, DeleteRounded, MenuOpenRounded, MoreVert, FullscreenRounded, Edit, Delete, ManageSearchRounded } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';
import { Transition } from './Transition'
import { Resizable } from 're-resizable';
import PDFViewerComponent from './components/PDFViewerComponent'

const MedicalsTreatments = ({documentData, documentS3Url, dataUpdatedCallback, userData, viewType }) => {

    const [anchorEl, setAnchorEl] = useState();
    const [deleteMedicalOpen, setDeleteMedicalOpen] = useState(false);
    const [addMedicalOpen, setAddMedicalOpen] = useState(false);
    const [bulkEditOpen, setbulkEditOpen] = useState(false);
    const [bulkDeleteOpen, setbulkDeleteOpen] = useState(false);
    const [confirmationWindowOpen, setconfirmationWindowOpen] = useState(false);
    const [currentDeleteMedical, setCurrentDeleteMedical] = useState();
    const [addMedicalData, setAddMedicalData] = useState({});
    const [bulkEditData, setBulkEditData] = useState({});
    const [bulkEditOldData, setBulkEditOldData] = useState({});
    const [documentPanelOpen, setDocumentPanelOpen] = useState(true);
    const [treatmentsMaxWidth, setTreatmentsMaxWidth] = useState(documentS3Url ? '85%' : '100%');
    const [resizable, setResizable] = useState();
    const [addMedicalMenuView, setAddMedicalMenuView] = useState(false);
    const [bulkEditMenuView, setbulkEditMenuView] = useState(false);
    const [bulkDeleteMenuView, setbulkDeleteMenuView] = useState(false);
    const [confirmationWindowMenuView, setConfirmationWindowMenuView] = useState(false);
    const [addMenuOpen, setAddMenuOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageTrigger, setCurrentPageTrigger] = useState(0);
    const [gridHeight, setGridHeight] = useState(500);
    const [pdfHeight, setPdfHeight] = useState(500);
    const frameRef = createRef();
    const collapsedFrameRef = createRef();
    const MULTIPLE_VALUES = '[Multiple values]';

    const [selectionModel, setSelectionModel] = React.useState([]);
    const scrollFrameRef = createRef();

    const medicalTreatmentAlerts = getMedicalTreatmentAlerts(documentData, viewType);
    const hasMedicalTreatmentAlerts = medicalTreatmentAlerts.length > 0;

    const processRowUpdate = (newRow, originalRow) => {
        if (newRow.billedAmount) {
            newRow.billedAmount = cleanNumber(newRow.billedAmount);
        }
        const updatedRow = { ...newRow };
        if (JSON.stringify(newRow) !== JSON.stringify(originalRow)) {
            documentData.medicals.medicalTreatments = documentData.medicals.medicalTreatments.map((row) => (row.medicalTreatmentId === newRow.medicalTreatmentId ? updatedRow : row));
            dataUpdatedCallback(documentData);
        }
        return updatedRow;
    };

    const processMultipleRowUpdate = (newRows) => {
        newRows.forEach((newRow) => {
            if (newRow.billedAmount) {
                newRow.billedAmount = cleanNumber(newRow.billedAmount);
            }
            const updatedRow = { ...newRow };
            documentData.medicals.medicalTreatments = documentData.medicals.medicalTreatments.map((row) => (row.medicalTreatmentId === newRow.medicalTreatmentId ? updatedRow : row));
        });
        dataUpdatedCallback(documentData);
    };

    const handleAddMedicalsClick = (event) => {
        setAddMedicalData({});
        setAddMedicalOpen(true);
        if (addMedicalMenuView) {
            handleMenuClose();
        }
    };

    const handleAddMedicalsClose = (event) => {
        setAddMedicalOpen(false);
    };

    const handleBulkEditClick = (event) => {
        handleMenuClose();
        setBulkEditData({});
        setBulkEditOldData({});
        setbulkEditOpen(true);
        if (bulkEditMenuView) {
            handleMenuClose();
        }
    }

    const handleBulkDeleteClick = (event) => {
        handleMenuClose();
        setbulkDeleteOpen(true);
        if (bulkDeleteMenuView) {
            handleMenuClose();
        }
    }

    const handleConfirmationWindowClick = (event) => {
        setconfirmationWindowOpen(true);
        if (confirmationWindowMenuView) {
            handleMenuClose();
        }
    }

    const handleBulkEditClose = (event) => {
        setbulkEditOpen(false);
    }

    const handleBulkDeleteClose = (event) => {
        setbulkDeleteOpen(false);
    }

    const handleConfirmationWindowClose = (event) => {
        setconfirmationWindowOpen(false);
    }

    const getMedicalFormData = (id) => {
        return addMedicalData[id];
    };

    const getBulkEditFormData = (id) => {
        return bulkEditData[id];
    };

    const handleDeleteMedicalClick = (event, row) => {
        setCurrentDeleteMedical(row);
        setDeleteMedicalOpen(true);
    };

    const handleDeleteMedicalClose = () => {
        setDeleteMedicalOpen(false);
    };

    const handleMedicalFormElementChange = (event, id) => {
        const elementId = id || event.target.id;
        addMedicalData[elementId] = event.target.value;
        setAddMedicalData({ ...addMedicalData });
    };

    const handleBulkEditFormElementChange = (event, id, newValue = null) => {
        const elementId = id || event.target.id;
        newValue = newValue || event.target.value;
        if (bulkEditOldData[elementId] !== newValue) {
            bulkEditData[elementId] = newValue;
            setBulkEditData({ ...bulkEditData });
        }
    };

    const handleBulkEditFormElementReset = (event, id) => {
        const elementId = id || event.target.id;
        delete bulkEditData[elementId];
        setBulkEditData({ ...bulkEditData });
    };

    const handleMenuButtonClick = (event) => {
        setAnchorEl(event.target);
        setAddMenuOpen(true);
    };

    const handleMenuClose = (event) => {
        setAddMenuOpen(false);
        setAnchorEl(null);
    };

    const handleSaveConfirmationWindowClick = (event) => {
        let newRows = [];
        selectionModel.forEach((rowId) => {
            let newRow = apiRef.current.getRow(rowId);
            Object.keys(bulkEditData).forEach((key) => {
                if (bulkEditData[key] !== MULTIPLE_VALUES) {
                    newRow[key] = bulkEditData[key];
                }
            });
            newRows.push(newRow);
        });
        processMultipleRowUpdate(newRows);
        setconfirmationWindowOpen(false);
        setbulkEditOpen(false);
    };

    const handleSaveBulkDeleteClick = (event) => {
        documentData.medicals.medicalTreatments = documentData.medicals.medicalTreatments.filter(item => !selectionModel.includes(item.medicalTreatmentId));
        dataUpdatedCallback(documentData);
        setbulkDeleteOpen(false);
    };

    const handleSaveMedicalClick = (event) => {
        let newMedical = {};
        newMedical["medicalTreatmentId"] = uuidv4();
        newMedical["treatmentFacility"] = addMedicalData["facility"];
        newMedical["treatmentType"] = addMedicalData["type"];
        newMedical["treatmentDate"] = addMedicalData["dateOfService"];
        newMedical["treatments"] = addMedicalData["treatments"];
        newMedical["diagnoses"] = addMedicalData["diagnoses"];
        newMedical["generatedPageNumber"] = addMedicalData["pageNumber"];
        newMedical["billedAmount"] = addMedicalData["chargedAmount"];

        // Issue: When manually adding a medical treatment, date of service (if left alone) saves as “invalid date”
        // Desired Behavior: When manually adding a medical treatment, default date of service should save as today’s date. 
        if (newMedical["treatmentDate"] == null || newMedical["treatmentDate"] == '') {
            newMedical["treatmentDate"] = getCurrentDate();
        }

        if (newMedical["billedAmount"] != null && newMedical["billedAmount"] != '') {
            newMedical["billedAmount"] = cleanNumber(newMedical["billedAmount"]);
        }

        documentData.medicals.medicalTreatments = [...documentData.medicals.medicalTreatments, newMedical];
        dataUpdatedCallback(documentData);
        setAddMedicalOpen(false);
    };

    const handleDocumentPanelClose = (event) => {
        setDocumentPanelOpen(false);
        setTreatmentsMaxWidth('97%');
        resizable.updateSize({ width: '97%', height: '100%' });
    };

    const handleDocumentPanelOpen = (event) => {
        setDocumentPanelOpen(true);
        setTreatmentsMaxWidth('85%');
        resizable.updateSize({ width: '66%', height: '100%' });
        resizeIframe();
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
    };

    const deleteMedical = () => {
        documentData.medicals.medicalTreatments = documentData.medicals.medicalTreatments.filter(item => item.medicalTreatmentId !== currentDeleteMedical['medicalTreatmentId']);
        dataUpdatedCallback(documentData);
        setDeleteMedicalOpen(false);
    };


    const handleTreatmentsPageShow = (row) => {
        setCurrentPage(parseInt(row.generatedPageNumber) - 1);
        setCurrentPageTrigger(currentPageTrigger + 1);
    }

    const resizeIframe = () => {
        // Need to size the treatments and iframe differently due to different layouts
        let docH = window.innerHeight - 215;
        let gridH = window.innerHeight - 230;
        setGridHeight(Math.max(gridH, 500));
        setPdfHeight(Math.max(docH, 590))
    };

    const onPanelResizeStop = (event, direction, refToElement, delta) => {
        if (documentPanelOpen && (frameRef.current && frameRef.current.offsetWidth / window.innerWidth) < .15) {
            handleDocumentPanelClose();
        }
        else if (!documentPanelOpen && (collapsedFrameRef && collapsedFrameRef.current.offsetWidth / window.innerWidth) > .15) {
            handleDocumentPanelOpen(event);
        }
    };

    const onPanelResize = (event, direction, refToElement, delta) => {

        if (refToElement.offsetWidth < 1000) {
            setAddMedicalMenuView(true);
            setbulkEditMenuView(true);
            setbulkDeleteMenuView(true);
            setConfirmationWindowMenuView(true);
        }
        else {
            setAddMedicalMenuView(false);
            setbulkEditMenuView(false);
            setbulkDeleteMenuView(false);
            setConfirmationWindowMenuView(false);
        }
    };

    const scrollToMedicals = (event) => {
        if (documentS3Url) {
            window.scrollTo({
                top: scrollFrameRef.current.offsetTop - 120,
                behavior: "smooth"
            });
        }
        else {
            window.scrollTo({
                top: frameRef.current.offsetTop - 110,
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        resizeIframe();
    }, [frameRef]);

    window.addEventListener('resize', function (event) {
        resizeIframe();
    }, true);

    const apiRef = useGridApiRef();

    const columns = [
        {
            field: 'treatmentFacility',
            headerName: 'Facility',
            width: 125,
            headerAlign: 'left',
            valueGetter: (params) => {
                return params.row.treatmentFacility;
            },
            editable: userHasPermission("MedicalsEdit", userData)
        },
        {
            field: 'gotopage',
            headerName: '',
            width: 60,
            resizable: false,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (<Tooltip arrow title="View in documnet" placement="right"><IconButton onClick={(e) => { handleTreatmentsPageShow(params.row) }} ><ManageSearchRounded color="primaryActions"></ManageSearchRounded> </IconButton></Tooltip>);
            }
        },
        {
            field: 'treatmentType',
            headerName: 'Type',
            width: 250,
            headerAlign: 'left',
            valueGetter: (params) => {
                return params.row.treatmentType
            },
            editable: userHasPermission("MedicalsEdit", userData)
        },
        {
            field: 'treatmentDate',
            headerName: 'Date of service',
            type: 'date',
            minWidth: 160,
            headerAlign: 'right',
            align: 'right',
            valueGetter: (params) => {
                return params.row.treatmentDate ? new Date(params.row.treatmentDate) : 0;
            },
            renderCell: (params) => {
                return (<div className='MuiDataGrid-cellContent'>{formatDate(new Date(params.row.treatmentDate))}</div>);
            },
            renderEditCell: (params) => (
                <TextField
                    id="date_of_service"
                    inputProps={{ max: getCurrentDate() }}
                    type='date'
                    label="Date of service"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={params.value ? convertUTCDateToLocalDate(params.value) : ''}
                    onKeyDown={(e) => { e.preventDefault(); }}
                    onChange={(e) => {
                        const updatedValue = new Date(e.target.value);

                        //this updates the backend
                        const newRow = { ...params.row, [params.field]: updatedValue };
                        processRowUpdate(newRow, params.row);

                        //however, we need to update the cell value to display the new value
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: updatedValue,
                        });
                    }}
                ></TextField>
            ),
            onEditCellChange: (params, event) => {
                event.defaultMuiPrevented = true;
            },
            editable: userHasPermission("MedicalsEdit", userData),
        },
        {
            field: 'billedAmount',
            headerName: 'Charged amount',
            width: 170,
            type: 'number',
            headerAlign: 'right',
            align: 'right',
            sortComparator: currencyComparator,
            valueGetter: (params) => {
                return params.row.billedAmount;

            },
            renderCell: (params) => {
                if (params.row.billedAmount != '')
                    return formatCurrency(params.row.billedAmount + '');
                else
                    return '';
            },
            editable: userHasPermission("MedicalsEdit", userData)

        },
        {
            field: 'treatments',
            headerName: 'Treatments',
            width: 150,
            headerAlign: 'left',
            align: 'left',
            valueGetter: (params) => {
                return params.row.treatments;
            },
            editable: userHasPermission("MedicalsEdit", userData)
        },
        {
            field: 'generatedPageNumber',
            headerName: 'Page #',
            width: 110,
            type: 'number',
            headerAlign: 'right',
            align: 'right',
            valueGetter: (params) => {
                return params.row.generatedPageNumber;
            },
            editable: userHasPermission("MedicalsEdit", userData)
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 50,
            type: 'number',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (userHasPermission("MedicalsEdit", userData) && viewType === "precedent") {
                    return (<IconButton onClick={(e) => { handleDeleteMedicalClick(e, params.row) }}><DeleteRounded /></IconButton>);
                }
                else {
                    return (<></>)
                }
            },
            editable: false,
            sortable: false
        }
    ];


    return (
        <>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                }}
            >
                <Resizable onResizeStop={onPanelResizeStop} onResize={onPanelResize} ref={c => { setResizable(c); }} defaultSize={{ width: documentS3Url ? "66%" : "100%", height: "100%" }} minWidth={'25%'} maxWidth={treatmentsMaxWidth}>
                    <div style={{ borderRight: documentS3Url ? '1px solid rgba(0,0,0,0.12)' : '' }}>
                        <Box sx={{ mt: 2, mr: documentS3Url ? 1 : 0 }}>
                            <Grid container >
                                <Grid xs={6} >
                                    <Stack direction={"row"} alignItems="center" spacing={2}>
                                        <Title>Medical treatments</Title>
                                        <Tooltip placement="top" title="Click to scroll the medicals full screen" arrow>
                                            <IconButton onClick={scrollToMedicals}><FullscreenRounded /></IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                                <Grid xs={6} sx={{ textAlign: 'right' }}>
                                    {userHasPermission("DemandEdit", userData) && !bulkEditMenuView ? (
                                        selectionModel.length === 0 ? <Tooltip title="Select medical treatments to enable bulk options." arrow placement="top">
                                            <span>
                                                <Button color="secondary" sx={{ mr: 2 }} endIcon={<KeyboardArrowDownRounded />} disabled>Bulk options</Button>
                                            </span>
                                        </Tooltip> : <Button onClick={handleMenuButtonClick} color="secondary" sx={{ mr: 2 }} endIcon={<KeyboardArrowDownRounded />}>Bulk options</Button>
                                    ) : null}
                                    <Menu
                                        id={`${documentData.documentId}-menu`}
                                        keepMounted
                                        anchorEl={anchorEl}
                                        open={addMenuOpen}
                                        onClose={(e) => { handleMenuClose(e) }}
                                    >
                                        <MenuItem onClick={handleBulkEditClick} disabled={selectionModel.length === 0}>
                                            <ListItemIcon>
                                                <Edit fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>Edit selected treatments</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={handleBulkDeleteClick} disabled={selectionModel.length === 0}>
                                            <ListItemIcon>
                                                <Delete fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>Delete selected treatments</ListItemText>
                                        </MenuItem>
                                    </Menu>


                                    {userHasPermission("DemandEdit", userData) && !addMedicalMenuView ? (
                                        <Button onClick={handleAddMedicalsClick} color="secondary" variant="outlined" startIcon={<Add />}>Add medical treatment</Button>
                                    ) : null}

                                    {userHasPermission("DemandEdit", userData) && addMedicalMenuView ? (
                                        <div>
                                            <Button
                                                id={documentData.documentId}
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={(e) => { handleMenuButtonClick(e) }}
                                            >
                                                <MoreVert color='primaryActions' />
                                            </Button>
                                            <Menu
                                                id={`${documentData.documentId}-menu`}
                                                keepMounted
                                                anchorEl={anchorEl}
                                                open={addMenuOpen}
                                                onClose={(e) => { handleMenuClose(e) }}
                                            >
                                                <MenuItem onClick={handleAddMedicalsClick}>
                                                    <ListItemIcon>
                                                        <AddCard />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Add New Medical" />
                                                </MenuItem>
                                                <MenuItem onClick={handleBulkEditClick} disabled={selectionModel.length === 0}>
                                                    <ListItemIcon>
                                                        <Edit fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>Edit Selected Treatments</ListItemText>
                                                </MenuItem>
                                                <MenuItem onClick={handleBulkDeleteClick} disabled={selectionModel.length === 0}>
                                                    <ListItemIcon>
                                                        <Delete fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>Delete Selected Treatments</ListItemText>
                                                </MenuItem>
                                            </Menu>
                                        </div>

                                    ) : null}
                                </Grid>
                            </Grid>
                            <div style={{ height: gridHeight + 'px' }}>

                                {hasMedicalTreatmentAlerts && (
                                    medicalTreatmentAlerts.map((alert, index) => (
                                        <Alert key={index} severity={alert.alertSeverity} sx={{ m: 1 }}>
                                            <AlertTitle>{alert.alertMessage}</AlertTitle>
                                        </Alert>
                                    ))
                                )}

                                <DataGridPro autoHeight={false} getRowId={(row) => row.medicalTreatmentId} columns={columns} rows={documentData.medicals.medicalTreatments}
                                    columnVisibilityModel={{
                                        actions: viewType === "carrier" ? false : true,
                                    }}
                                    density="compact"
                                    onColumnWidthChange={() => {
                                        apiRef.current.resetRowHeights();
                                    }}
                                    unstable_cellSelectionModel={{ 1: { gotopage: false } }}
                                    selectionModel={selectionModel}
                                    onRowSelectionModelChange={handleSelectionModelChange}
                                    checkboxSelection={userHasPermission("MedicalsEdit", userData)}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: 'treatmentDate', sort: 'asc' }],
                                        },
                                        pagination: {
                                            paginationModel: { pageSize: 100, page: 0 },
                                        },
                                        pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'treatmentFacility', 'gotopage'] }
                                    }}
                                    editMode={'cell'}
                                    apiRef={apiRef}
                                    processRowUpdate={(updatedRow, originalRow) => {
                                        return processRowUpdate(updatedRow, originalRow);
                                    }}
                                    disableRowSelectionOnClick={true}
                                    onProcessRowUpdateError={(error) => { alert(error); }}
                                    onCellEditStop={(params, event) => {
                                        event.defaultMuiPrevented = false;
                                    }}

                                    disableColumnSelector={true}
                                    pageSizeOptions={[25, 50, 100]}
                                    getRowHeight={() => 'auto'}

                                    sx={{
                                        mt: 1,
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            fontSize: '0.85714em',
                                            fontWeight: '500'
                                        },

                                        // we need to drop the sticky attribute on MuiDataGrid-pinnedColumns
                                        // because it causes the column (minus the header) to be sticky and overflow the container
                                        '& .MuiDataGrid-pinnedColumns': {
                                            //position: 'inherit'
                                        },

                                        // same issue for pinnedColumnHeaders (just the header row), but here with need to drop the z-index
                                        '& .MuiDataGrid-pinnedColumnHeaders': {
                                            //zIndex: 0
                                        },
                                        '& .MuiDataGrid-cell': {
                                            fontSize: '.92857em',
                                            paddingRight: "0px",
                                            width: "100%"
                                        },

                                    }}

                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'
                                    }
                                >
                                </DataGridPro>
                            </div>
                        </Box>
                    </div>
                </Resizable>

                {documentS3Url ? (
                    <div ref={scrollFrameRef} style={{ width: "100%", paddingLeft: '10px' }}>
                        <Box sx={{ mt: 2, display: documentPanelOpen ? 'display' : 'none' }} width={"100%"}>
                            <Stack direction={"row"} >
                                <Stack direction={"row"} width={"80%"}>
                                    <Title>Demand</Title>
                                </Stack>
                                <Stack direction={"row"} width={"20%"} justifyContent={'flex-end'}>
                                    <IconButton onClick={handleDocumentPanelClose}><Close /></IconButton>
                                </Stack>
                            </Stack>
                            <div ref={frameRef} >
                                <PDFViewerComponent currentPage={currentPage} currentPageTrigger={currentPageTrigger} height={pdfHeight} document={documentS3Url}></PDFViewerComponent>
                            </div>
                        </Box>

                        <Box ref={collapsedFrameRef} sx={{ pl: 1, mt: 2, display: documentPanelOpen ? 'none' : 'display' }} width={"100%"} justifyContent={'flex-end'}>
                            <IconButton onClick={handleDocumentPanelOpen}><MenuOpenRounded /></IconButton>
                        </Box>
                    </div>
                ) : <div ref={frameRef}></div>}

            </div>


            {deleteMedicalOpen ? (
                <Dialog
                    open={deleteMedicalOpen}
                    TransitionComponent={Transition}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>Delete medical treatment?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Do you want to delete this medical: {currentDeleteMedical["treatmentFacility"]} - {currentDeleteMedical["treatmentType"]}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { handleDeleteMedicalClose() }}>Cancel</Button><Button variant="contained" color="secondary" onClick={() => { deleteMedical() }}>Delete</Button>
                    </DialogActions>
                </Dialog>) : null}

            <Dialog
                open={bulkEditOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle>Bulk edit medical treatments</DialogTitle>

                <DialogContent>
                    <Alert severity="info" spacing={2} sx={{ mt: 3, mb: 3 }}>
                        <AlertTitle>
                            {selectionModel.length} medical treatment{selectionModel.length > 1 ? 's' : ''} selected
                        </AlertTitle>
                        {selectionModel.length > 1 ? (
                            "Edit a field to apply the value to all selected medical treatments. Leave a field unchanged to maintain its value(s) as is."
                        ) : (
                            "Edit the fields below to apply the changes to the selected medical treatment."
                        )}
                    </Alert>

                    {selectionModel.length > 0 ? (
                        <Stack spacing={2} sx={{ mt: 1 }}>
                            {columns.map((column) => {
                                if (column.field !== 'actions' && bulkEditOpen) {
                                    let multipleValues = false;
                                    bulkEditOldData[column.field] = undefined;
                                    selectionModel.forEach((rowId) => {
                                        //this container is used for the confirmation window to display before and after
                                        if (bulkEditOldData[column.field] !== undefined && bulkEditOldData[column.field] !== apiRef.current.getRow(rowId)[column.field]) {
                                            bulkEditOldData[column.field] = MULTIPLE_VALUES;
                                            multipleValues = true;
                                        }
                                        else {
                                            bulkEditOldData[column.field] = apiRef.current.getRow(rowId)[column.field];
                                            if (column.type === 'date') {
                                                bulkEditOldData[column.field] = convertUTCDateToLocalDate(bulkEditOldData[column.field]);
                                            }
                                            else if (column.field === 'billedAmount') {
                                                bulkEditOldData[column.field] = formatDecimal(bulkEditOldData[column.field]);
                                            }
                                        }
                                    });
                                    let value = bulkEditOldData[column.field];
                                    if (value == null) {
                                        value = '';
                                    }

                                    const haveValue = getBulkEditFormData(column.field) !== undefined;
                                    let displayValue = haveValue ? getBulkEditFormData(column.field) : (multipleValues ? "" : value);

                                    if (column.type === 'date') {
                                        if (displayValue !== '') {
                                            displayValue = convertUTCDateToLocalDate(displayValue)
                                        }
                                        return (
                                            <div style={{ display: 'flex', alignItems: 'center' }} key={column.field}>
                                                <TextField id={column.field} label={column.headerName} inputProps={{ max: getCurrentDate() }} type='date'
                                                    InputLabelProps={{ shrink: true }} fullWidth value={displayValue}
                                                    onChange={(e) => { handleBulkEditFormElementChange(e, column.field) }}
                                                    onKeyDown={(e) => { e.preventDefault(); }}
                                                    InputProps={{
                                                        endAdornment: getBulkEditFormData(column.field) !== undefined ? (
                                                            <InputAdornment position="end">
                                                                <Button color="error" onClick={(e) => { handleBulkEditFormElementReset(e, column.field, value) }} sx={{ mr: 3 }}>Reset</Button>
                                                            </InputAdornment>
                                                        ) : null
                                                    }}
                                                    helperText={multipleValues ? "Multiple values" : `Current value: ${formatDate(new Date(value))}`}></TextField>
                                            </div>
                                        )
                                    }
                                    else if (column.field === 'billedAmount') {
                                        return (
                                            <div style={{ display: 'flex', alignItems: 'center' }} key={column.field}>
                                                <TextField id={column.field} label={column.headerName} fullWidth value={formatDecimal(displayValue)}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        endAdornment: getBulkEditFormData(column.field) !== undefined ? (
                                                            <InputAdornment position="end">
                                                                <Button color="error" onClick={(e) => { handleBulkEditFormElementReset(e, column.field, value) }} sx={{ mr: 3 }}>Reset</Button>
                                                            </InputAdornment>
                                                        ) : null
                                                    }}
                                                    onChange={(e) => {
                                                        let newValue = formatDecimal(e.target.value);
                                                        handleBulkEditFormElementChange(e, column.field, newValue);
                                                    }}
                                                    helperText={multipleValues ? "Multiple values" : `Current value: ${formatCurrency(value)}`}></TextField>
                                            </div>
                                        )
                                    }
                                    else if (column.field === 'generatedPageNumber') {
                                        return (
                                            <div style={{ display: 'flex', alignItems: 'center' }} key={column.field}>
                                                <TextField id={column.field} label={column.headerName} fullWidth value={displayValue}
                                                    inputProps={{ type: 'number' }}
                                                    onChange={(e) => { handleBulkEditFormElementChange(e, column.field) }}
                                                    InputProps={{
                                                        endAdornment: getBulkEditFormData(column.field) !== undefined ? (
                                                            <InputAdornment position="end">
                                                                <Button color="error" onClick={(e) => { handleBulkEditFormElementReset(e, column.field, value) }} sx={{ mr: 3 }}>Reset</Button>
                                                            </InputAdornment>
                                                        ) : null
                                                    }}
                                                    helperText={multipleValues ? "Multiple values" : `Current value: ${value}`}></TextField>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div style={{ display: 'flex', alignItems: 'center' }} key={column.field}>
                                                {/* the autocomplete should be populated with values from all existing rows without duplicates and without empty or undefined values */}
                                                <Autocomplete options={
                                                    Array.from(new Set(documentData.medicals.medicalTreatments.map((row) => row[column.field]).filter((value) => value != null && value !== '')))
                                                } freeSolo value={displayValue} fullWidth multiline
                                                    getOptionLabel={(option) => typeof option === 'string'
                                                        || option instanceof String ? option : ""}
                                                    onChange={(e, newValue) => handleBulkEditFormElementChange(e, column.field, newValue)}
                                                    onInputChange={(e, newValue) => handleBulkEditFormElementChange(e, column.field, newValue)}
                                                    renderInput={(params) => (
                                                        <div ref={params.InputProps.ref}>
                                                            <TextField {...params} label={column.headerName} fullWidth
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: getBulkEditFormData(column.field) !== undefined ? (
                                                                        <InputAdornment position="end">
                                                                            <Button color="error" onClick={(e) => { handleBulkEditFormElementReset(e, column.field, value) }} sx={{}}>Reset</Button>
                                                                        </InputAdornment>
                                                                    ) : null
                                                                }}
                                                                helperText={multipleValues ? "Multiple values" : `Current value: ${value}`} />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        )
                                    }
                                }
                            })}
                        </Stack>
                    ) : null}
                </DialogContent>
                <Divider sx={{ my: 1 }} />

                <DialogActions>
                    <Button color='error' onClick={() => { handleBulkEditClose() }}>Discard changes</Button>
                    <Button variant="contained" color="secondary" disabled={selectionModel.length === 0 || Object.keys(bulkEditData).length === 0} onClick={(e) => { handleConfirmationWindowClick(); }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={bulkDeleteOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >

                <DialogTitle>Confirm Bulk Delete</DialogTitle>

                <DialogContent>
                    <DialogContentText mt={2}>
                        {`You are about to delete ${selectionModel.length} medical treatment${selectionModel.length > 1 ? 's' : ''}. This action cannot be undone.`}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button color='secondary' onClick={() => { handleBulkDeleteClose() }}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={(e) => { handleSaveBulkDeleteClick(); }} disabled={selectionModel.length === 0}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={confirmationWindowOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle>Confirm Bulk Edit</DialogTitle>

                <DialogContent>
                    <Alert severity={"info"} spacing={2} sx={{ my: 3 }}>
                        <AlertTitle>
                            You are about to edit the following fields across {selectionModel.length} medical treatment{selectionModel.length > 1 ? 's' : ''}:
                        </AlertTitle>
                    </Alert>
                    <TableContainer component={Paper} sx={{ mt: 1 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Field</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Old Value</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>New Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(bulkEditData).map((key) => (
                                    <TableRow key={key}>
                                        <TableCell>{columns.find((column) => column.field === key).headerName}</TableCell>
                                        <TableCell>{bulkEditOldData[key]}</TableCell>
                                        <TableCell>{bulkEditData[key]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <Divider sx={{ my: 1 }} />

                <DialogActions>
                    <Button color='error' onClick={() => { handleConfirmationWindowClose() }}>Cancel</Button>
                    <Button variant="contained" color="secondary" onClick={(e) => { handleSaveConfirmationWindowClick(); }}>
                        Apply changes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={addMedicalOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle>Add Medical Treatment</DialogTitle>

                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 1 }}>
                        <TextField id="facillity" label="Facility" fullWidth value={getMedicalFormData('facility')}
                            required
                            error={getMedicalFormData('facility') === ''}
                            onChange={(e) => { handleMedicalFormElementChange(e, 'facility') }}></TextField>

                        <TextField id="type" label="Type" fullWidth value={getMedicalFormData('type')}
                            onChange={(e) => { handleMedicalFormElementChange(e, 'type') }}></TextField>

                        <TextField id="date_of_service" inputProps={{ max: getCurrentDate() }} type='date' label="Date of service" InputLabelProps={{ shrink: true }} fullWidth value={getMedicalFormData('dateOfService')}
                            required
                            error={getMedicalFormData('dateOfService') === ''}
                            onChange={(e) => { handleMedicalFormElementChange(e, 'dateOfService') }}></TextField>

                        <TextField id="charged_amount" label="Charged amount" fullWidth value={formatDecimal(getMedicalFormData('chargedAmount'))}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            required
                            error={getMedicalFormData('chargedAmount') === ''}
                            onChange={(e) => { handleMedicalFormElementChange(e, 'chargedAmount') }}></TextField>

                        <TextField id="diagnoses" label="Diagnoses" fullWidth value={getMedicalFormData('diagnoses')}
                            onChange={(e) => { handleMedicalFormElementChange(e, 'diagnoses') }}></TextField>

                        <TextField id="treatments" label="Treatments" fullWidth value={getMedicalFormData('treatments')}
                            onChange={(e) => { handleMedicalFormElementChange(e, 'treatments') }}></TextField>

                        <TextField id="page_number" label="Page number" fullWidth value={getMedicalFormData('pageNumber')}
                            inputProps={{ type: 'number' }}
                            onChange={(e) => { handleMedicalFormElementChange(e, 'pageNumber') }}></TextField>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button color='error' onClick={() => { handleAddMedicalsClose() }}>Discard changes</Button>
                    {/* Facility, Service date, and charged amount must be filled in to consider this a valid input */}
                    <Button variant="contained" color="secondary"
                        disabled={getMedicalFormData('facility') === '' || getMedicalFormData('chargedAmount') === '' || getMedicalFormData('dateOfService') === null || getMedicalFormData('dateOfService') === undefined || getMedicalFormData('dateOfService') === ''}
                        onClick={(e) => { handleSaveMedicalClick(); }}>Save & exit</Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default MedicalsTreatments;