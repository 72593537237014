import dayjs from 'dayjs';
import { format } from 'date-fns';

export const DATE_FORMAT = Intl.DateTimeFormat('en-US',{  month:"2-digit", day:"2-digit", year:"numeric", timeZone: 'UTC' });
export const DATE_FORMAT_EST = Intl.DateTimeFormat('en-US',{  month:"2-digit", day:"2-digit", year:"numeric", timeZone: 'America/New_York' });
export const DATE_TIME_FORMAT = Intl.DateTimeFormat('en-US',{  month:"2-digit", day:"2-digit", year:"numeric", hour: "2-digit", minute:"2-digit", hourCycle: 'h23', timeZone: 'America/New_York' });
export const USD_FORMAT = Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'});
export const DECIMAL_FORMAT = Intl.NumberFormat('en-US');
export const TIME_FORMAT = Intl.DateTimeFormat('en-US',{ hour: "2-digit", minute:"2-digit", hour12: false, timeZone: 'America/New_York' });
export const DATE_TIME_FORMAT_LOCAL_TZ = Intl.DateTimeFormat('en-US',{  month:"2-digit", day:"2-digit", year:"numeric", hour: "2-digit", minute:"2-digit", hourCycle: 'h23', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });

//
// Format a date string that does not have a time component associated with it
// Example: 2023-11-01
//
export const formatDate = (dateStr) => {
    try {
        if (dateStr && dateStr !== "") {
            return DATE_FORMAT.format(Date.parse(dateStr));
        }
    }
    catch {
    }
    
    return "";
};

export const formatDateEst = (dateStr) => {
    try {
        if (dateStr && dateStr !== "") {
            return DATE_FORMAT_EST.format(Date.parse(dateStr));
        }
    }
    catch {
    }
    return "";
};

// This function is needed to fix the off-by-1 error when putting in a date, since it is converted to UTC and goes back a day
export const convertUTCDateToLocalDate = (date) => {
    try {
        var newDate = formatDate(date);
        return format(new Date(newDate), 'MM/dd/yyyy')
    }
    catch {
        console.log("Could not convert date: " + date);
    }

    return "";
}

export const convertUTCDateTimeToLocalDate = (date) => {
    try {
        var newDate = formatDateTimeLocal(date);
        return format(new Date(newDate), 'MM/dd/yyyy')
    }
    catch {
        console.log("Could not convert date: " + date);
    }

    return "";
}

export const convertUTCDateTimeToLocalTime = (date) => {
    var newDate = null;
    try{
        newDate = formatDateTimeLocal(date);
        return format(new Date(newDate), 'HH:mm')
    }
    catch {
        console.log("Could not convert date: " + date);
    }

    return "";
}

export const getCurrentDate = () => {
    return dayjs().format("YYYY-MM-DD");
};

export const cleanNumber = (num) => {
    // If the value is a number then convert to string
    if (num !== undefined && typeof num === 'number') {
        num = num + "";
    }

    if (num !== undefined && num !== null)
        return num.replace(/[$,]/g, '');
    else
        return '';
};

//
// Format a date object that does have a time component associated with it. This will
// format the date with the user's local timezone
//
export const formatDateTimeLocal = (dateObj) => {
    try {
        if (dateObj) {
            return DATE_TIME_FORMAT_LOCAL_TZ.format(dateObj);
        }
    }
    catch {
    }
    return "";
};

//
// Format a date object that does have a time component associated with it. This will
// format the date in American/New_York timezone
//
export const formatDateTime = (dateObj) => {
    try {
        if (dateObj) {
            return DATE_TIME_FORMAT.format(dateObj);
        }
    }
    catch {
    }
    return "";
};

export const formatTime = (dateObj) => {
    try {
        if (dateObj) {
            return TIME_FORMAT.format(dateObj);
        }
    }
    catch {
    }
    return "";
};

export const formatCurrency = (numStr) => {
    if (numStr !== undefined) {
        numStr = numStr + "";
        numStr = numStr.replace("$", "").replaceAll(",", "");
        if (!isNaN(numStr))
            return USD_FORMAT.format(numStr);
    }

    return "";
};

export const formatDecimal = (numStr) => {

    // If the value is a number then convert to string
    if (numStr !== undefined && typeof numStr === 'number') {
        numStr = numStr + "";
    }

    if (numStr !== undefined && typeof numStr === 'string') {
        // Remove non-numeric characters, except for the first decimal point
        let cleanedNumStr = numStr.replace(/[^\d.]/g, '');

        //if there is more than one decimal point, remove all but the first one
        const decimalIndex = cleanedNumStr.indexOf('.');
        if (decimalIndex !== -1) {
            cleanedNumStr = cleanedNumStr.substring(0, decimalIndex + 1) + cleanedNumStr.substring(decimalIndex + 1).replace('.', '');
        }

        // Split the string at the decimal point
        const parts = cleanedNumStr.split('.');

        if (parts.length > 0) {
            // Format the integer part with commas
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Ensure there's at most one decimal point and at most two digits past the decimal
            if (parts.length > 1) {
                parts[1] = parts[1].substring(0, 2); // Keep at most 2 decimal places
            }
        }

        // Rejoin the integer and fractional parts with a single decimal point
        cleanedNumStr = parts.join('.');

        return cleanedNumStr;
    }

    return "";
};


//this function is used to format phone number. It should allow only numbers, and and x to indicate extension
//It should correctly place parenthesis and dashes for the phone number
//we are ONLY concerned with US numbers here. The extension is optional, but it must all be numbers besides the singular x
//valid example: (407) 867-4877 x5202
export const formatPhoneNumber = (numStr) => {
    if (numStr !== undefined) {
        // Remove all non-numeric characters except 'x' for the extension.
        const cleanedStr = numStr.replace(/[^0-9x]/g, '');

        // Check if 'x' for extension exists in the string.
        const extensionIndex = cleanedStr.indexOf('x');

        // Split the cleaned string into the number and extension parts.
        let numberPart, extensionPart;
        if (extensionIndex !== -1) {
            numberPart = cleanedStr.substring(0, extensionIndex);
            extensionPart = cleanedStr.substring(extensionIndex + 1);
        } else {
            numberPart = cleanedStr;
        }

        // Format the number part as (XXX) XXX-XXXX.
        let formattedNumber = '';
        if (numberPart.length >= 10) {
            formattedNumber = `(${numberPart.substring(0, 3)}) ${numberPart.substring(3, 6)}-${numberPart.substring(6, 10)}`;
        } else if (numberPart.length >= 7) {
            formattedNumber = `(${numberPart.substring(0, 3)}) ${numberPart.substring(3, 6)}-${numberPart.substring(6)}`;
        } else {
            formattedNumber = numberPart;
        }

        // Combine the formatted number and extension (if it exists).
        if (extensionPart !== undefined) {
            return `${formattedNumber} x${extensionPart}`;
        } else {
            return formattedNumber;
        }
    }
    return "";
};


export const formatAddress = (address) => {
    var addressStr = "";

    if (address) {
        addressStr += address.street1;
        if (address.street2 !== "") {
            addressStr += ", " + address.street2;
        }

        addressStr += ", " + address.city;
        addressStr += ", " + address.state;
        addressStr += " " + address.zipCode
    }

    return addressStr;
};

export const mapStatusToLabel = (status) => {

    switch (status) {
        case "DocumentUploaded":
            return "New submission";
        case "DocumentSubmitted":
            return "Delivered to carrier";
        case "DocumentApproved":
            return "Approved by law firm";
        case "DocumentReceived":
            return "Receipt acknowledged";
        case "DocumentArchived":
            return "Archived";
        case "WaitingFirmApproval":
            return "Awaiting firm approval";
        case "RejectedByLawFirm":
            return "Awaiting Precedent review";
        case "AwaitingPrecedentSignoff":
            return "Awaiting Precedent signoff";
        case "AwaitingMedicalExtraction":
            return "Awaiting medical extraction";
    }

    return status;
};

export const mapStatusToChipClass = (status) => {
    switch (status) {
        case "DocumentUploaded":
            return "status-chip pending";
        case "DocumentSubmitted":
            return "status-chip submitted";
        case "DocumentApproved":
            return "status-chip approved";
        case "DocumentReceived":
            return "status-chip received";
        case "DocumentArchived":
            return "status-chip archived";
        case "WaitingFirmApproval":
            return "status-chip waitingapproval";
        case "RejectedByLawFirm":
            return "status-chip rejected";
        case "AwaitingPrecedentSignoff":
            return "status-chip awaitingprecedentsignoff";
        case "AwaitingMedicalExtraction":
            return "status-chip awaitingmedicalextraction";
    }

    return "status-chip";
};


export const mapDeliveryStatusToLabel = (status) => {

    switch (status) {
        case "AwaitingSubmission":
            return "Awaiting submission";
        case "EmailSubmitted":
            return "Email submission in progress";
        case "EmailSent":
            return "Sent via email";
        case "EmailSendFailure":
            return "Email send failed";
        case "FaxSubmitted":
            return "Fax submission in progress";
        case "FaxSubmissionFailed":
            return "Fax submission failed";
        case "FaxSent":
            return "Sent via fax";
        case "FaxSendFailure":
            return "Fax send failed";
        case "ManuallySent":
            return "Sent manually";

    }

    return status;
};


export const mapDeliveryStatusToChipClass = (status) => {
    return "status-chip " + status.toLowerCase();
};



export const userHasPermission = (permissionId, user) => {
    return user && user["permissions"].includes(permissionId);
};

export const userHasRole = (roleId, user) => {
    return user && user["roles"] && user["roles"].includes(roleId);
};

export const isRequestApprovalEnabled = (documentStatus) => {
    return documentStatus == 'DocumentUploaded' ||
        documentStatus == 'RejectedByLawFirm' ||
        documentStatus == 'AwaitingMedicalExtraction' ||
        documentStatus == 'AwaitingPrecedentSignoff';
};

export const isUploadMedicalsEnabled = (userData, documentStatus) => {
    return (userHasPermission("UploadMedicals", userData) && 
        (documentStatus === 'DocumentUploaded' || documentStatus === 'AwaitingMedicalExtraction' || documentStatus === 'AwaitingPrecedentSignoff'));
}

export const findStatusEventDate = (status, statusHistory) => {
    const matchedStatus = statusHistory.toReversed().find((element) => element.documentStatus == status);
    return matchedStatus;
};

export const isValidEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    //if it is empty, or it is not in the correct format, return false
    if (!email || email.length == 0) {
        return false
    }
    else if (!emailRegex.test(email)) {
        return false;
    }
    else {
        return true;
    }
};